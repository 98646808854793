.container___1sRtm {
    display: grid;
    grid: 1fr / repeat(12, 1fr);
    grid-gap: 0 8px;
    max-width: 1280px;
    flex: 1;
    margin: auto;
}

@media (min-width: 1060px) and (max-width: 1080px) {
    .container___1sRtm {
        max-width: 1060px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container___1sRtm {
        grid: 1fr / repeat(6, 1fr);
        grid-gap: 0 13px;
        max-width: 1024px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .container___1sRtm {
        grid: 1fr / repeat(2, 1fr);
        grid-gap: 0 10px;
        max-width: 480px;
    }
}
