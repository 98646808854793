.container___1eJjq {
  margin: 24px 69px;
  grid-column: 8 span;
  grid-column-start: 3;
  grid-column-end: 11;
  padding-top: 24px;
}

.title___3uik2 {
  position: relative;
  padding: 8px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  display: inline-block;
  font-size: 34px;
}

.title___3uik2::after{
  content: " ";
  width: 100px;
  height: 4px;
  background-color: #009DC5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.rrss___28sfK{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 2vw 0;
}

.facebook___3J9gy {
  width: 200px;
  height: 44px;
  background-color: #3b5998;
  border-radius: 22px;
  border: 1px solid #3b5998;
  color: white;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  padding-left: 24px;
}

.facebook___3J9gy::before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  color: white;
  position: absolute;
  top: 10px;
  left: 8px;
  -webkit-mask-image: url(/static/images/facebook_line.svg);
  mask-image: url(/static/images/facebook_line.svg);
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  background-color: white;
}

.contentAppleLogin___3l0Ko {
  width: 100%;
  height: 44px;
  background-color: #000;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
}

.contentAppleLogin___3l0Ko div{
  width: 100%;
  text-align: center;
}

.rrss___28sfK .facebook___3J9gy,
.rrss___28sfK .contentAppleLogin___3l0Ko{
  max-width: 200px;
}

.input___3zyvQ {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px;
}

.input___3zyvQ:focus {
  border-color: #009DC5
}

.input_error___3aqHg {
  border-color: red;
}

.button___7NSZw {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.separator___1Ls5y {
  height: 1px;
  width: 100%;
  background-color: #c0c4d2;
  margin: 16px 0;
}

.register__types___2SS5C {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.register__type___136x3 {
  cursor: pointer;
}

.register__type___136x3.selected___2ZOVl {
  color: #009DC5;;
}

.register__type___136x3:hover {
  color: #009DC5;;
}

.register__sign___LW7e7 {
  margin-top: 32px;
}

.register__signButton___2G4P9 {
  padding: 16px 48px;
  border-radius: 4px;
  border: none;
  background-color: #009DC5;;
  color: #f7f7f7;
  letter-spacing: 1.08px;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 16px 24px rgba(205, 34, 166, 0.4);
  transform: translateY(-4px);
}

.register__signButton___2G4P9:hover {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .2);
  color: #fff;
  transform: translateY(0px);
}

.register__signButton___2G4P9:focus {
  outline: none;
}

.login__recovery___b1dTK {
  cursor: pointer;
  opacity: .5;
  padding: 8px;
  content: "";
  font-size: 14px;
}

.register__radioButtons___8PR-q,
.register__checkNewsletter___2KhZD {
  margin-top: 8px;
  display: flex;
  flex-flow: row nowrap;
}

.register__radioButtons___8PR-q {
  justify-content: space-between;
}
.register__checkNewsletter___2KhZD {
  justify-content: center;
}

.register__radioButtons___8PR-q, .register__checkNewsletter___2KhZD label {
  color: rgba(0, 0, 0, .6);
  font-size: 14px;
}

.contentMessage___30_Ub{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 2vw 0;
  font-size: 14px;
}

.link___1raSB{
  background-color: transparent;
  color: #009DC5;
  font-family: Montserrat;
  font-size: 16px;
  border: none;
  text-decoration: none;
}

.contentType___10zx_ {
  display: flex;
  margin-bottom: 16px;
}

.contentType___10zx_ button {
  flex: 50%;
  text-align: center;
  height: 44px;
  border: 0;
  color: #000;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.contentType___10zx_ button.activeType___3US6H {
  color: #FFFFFF;
  background-color: #009DC5;;
}

@media only screen and (max-width: 1024px) {
  .container___1eJjq{
    margin: 8px 69px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .container___1eJjq{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 767px) {
  .container___1eJjq{
    margin: 8px 8px;
  }

  .register__radioButtons___8PR-q,
  .register__checkNewsletter___2KhZD {
    flex-flow: column nowrap;
    text-align: left;
  }

  .register__radioButtons___8PR-q {
    justify-content: space-between;
  }
  .register__checkNewsletter___2KhZD {
    justify-content: center;
  }

}
