.seemap___3SPh5 {
  grid-column: span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.seemap___3SPh5 .button___wygje {
  padding: 16px 32px;
}

.button___wygje {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #FFF;
  border-radius: 32px;
  cursor: pointer;
}
