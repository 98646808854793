.navigation___b5MxO {
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  position: absolute;
  position: sticky;
  position: -webkit-sticky;
  top: 81px;
  z-index: 999;
}

.main___2Rshy {
  height: 100px;
  display: flex;
  justify-content: center;
}

.main___2Rshy.searchMain___3l05J, .main___2Rshy.mobileCommands___2Ip-C {
  height: auto!important;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.main___2Rshy.mobileCommands___2Ip-C{
  display: none;
}

.main___2Rshy.mobileCommands___2Ip-C .container___1xuZb>.button___37Pnt {
  margin: 15px 10px;
}

.main___2Rshy.mobileCommands___2Ip-C .container___1xuZb>.button___37Pnt:first-child {
  margin-right: 0;
}

.main___2Rshy.mobileCommands___2Ip-C .container___1xuZb>.button___37Pnt:last-child {
  margin-left: 0;
}

.container___1xuZb {
  display: grid;
  grid: 1fr / repeat(12, 1fr);
  grid-gap: 0 8px;
  max-width: 1280px;
  flex: 1;
}

.categories___BHlrK {
  grid-column: span 11;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  white-space: nowrap;
}

.categories___BHlrK>div {
  margin: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.categories___BHlrK .menuItemWrapper___FDycf:focus {
  outline: 0!important;
}

.innerWrapper___1kLFM>.menuItemWrapper___FDycf:last-child>div {
  margin-right: 8px;
}

.categories___BHlrK::-webkit-scrollbar {
  height: 0 !important
}

.categories___BHlrK.item___1x4cI:first-child {
  margin-left: 8px;
}

.item___1x4cI {
  cursor: pointer;
  text-decoration: none;
  color: #333642;
  margin-left: 8px;
  padding: 8px 16px;
  flex: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.item___1x4cI.selected___3xLuO {
  background-color: #009DC5;
  color: #FFF;
}

.filtersCommands___2t9r3 {
  display: flex;
  align-items: center;
  grid-column: span 1;
}

.filtersCommands___2t9r3 .action___Sr-eS {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
}

.filtersCommands___2t9r3 .search___2LbzG {
  margin-right: 14px;
}

.search___2LbzG.selected___3xLuO button{
  background: #009DC5;
}

.filtersCommands___2t9r3 .action___Sr-eS .icon___3jmz2 {
  margin-right: 0;
}

.icon___3jmz2 {
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 8px;
  background-color: #333642;
  mask-position: center;
  -webkit-mask-position: center;
}

.selected___3xLuO .icon___3jmz2 {
  background-color: #FFF;
}

.icon___3jmz2.selected___3xLuO {
  background-color: #009DC5;
}

.magnify___2q1QA {
  -webkit-mask-image: url('/static/icons/search.svg');
  mask-image: url('/static/icons/search.svg');
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #009DC5;
}

.searchInput___2shXA {
  border-bottom: solid 1px #979797;
  grid-column: span 12;
  margin: 30px 0;
  display: flex;
  position: relative;
}

.searchInput___2shXA form {
  width: calc(100% - 50px);
}

.searchInput___2shXA input {
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  border: 0;
  outline: 0;
  border-radius: 0;
  padding-left: 24px;
  background-color: transparent;
  background-image: url('/static/icons/search.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  font-family: 'Montserrat', sans-serif;
}

.searchInput___2shXA input::placeholder {
  text-transform: uppercase;
}

.searchInput___2shXA button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  text-indent: -6666px;
  background-image: url('/static/icons/close.svg');
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container___1xuZb {
    grid: 1fr / repeat(6, 1fr);
    grid-gap: 0 13px;
    max-width: 768px;
  }
  .categories___BHlrK {
      grid-column: span 5;
      overflow-x: scroll;
  }
  .filtersCommands___2t9r3 {
      grid-column: span 1;
      justify-content: space-between;
  }
  .searchInput___2shXA {
    margin: 30px 16px;
  }
}

@media (min-width: 1060px) and (max-width: 1080px) {
  .container___1xuZb {
      max-width: 1060px;
  }
}
