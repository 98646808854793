.contentForm___SL6gK {
    display: flex;
    flex-direction: column;
    background-color: #ECEEF2;
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
}

.contentForm___SL6gK.header___2Yk6w{
    margin-bottom: 15px;
}

.whatsapp___18kcm,
.callphone___veREF {
    padding-left: 35px;
    position: relative;
}

.whatsapp___18kcm a,
.callphone___veREF a{
    text-decoration: none;
}

.whatsapp___18kcm::before,
.callphone___veREF::before {
    content: '';
    height: 30px;
    width: 30px;
    display: inline-block;
    position: absolute;
    left: 0;
}

.whatsapp___18kcm::before{
    -webkit-mask-image: url(/static/icons/whatsapp.svg);
    mask-image: url(/static/icons/whatsapp.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;;
}

.callphone___veREF::before{
    -webkit-mask-image: url(/static/icons/callphone.svg);
    mask-image: url(/static/icons/callphone.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;;
}

.row___sxV51 {
    width: 100%;
    margin: 12px 0;
    border-radius: 5px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.contentForm___SL6gK.header___2Yk6w .row___sxV51{
    background-color: #ECEEF2 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.row___sxV51 .num___2OxTl,
.row___sxV51 .phone___3amF8{
    width: 50%;
    display: inline-flex;
}

.select___2LK5U{
    width: 100%;
    height: 44px;
    border: 0;
    margin: 10px;
    padding: 0px 15px;
}

.input___2EcpR {
    border: 0;
    height: 44px;
    margin: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid white;
}

.input___2EcpR {
  width: 100%;
}

.input_error___2ixZL {
  border-color: red;
}

.contentForm___SL6gK.fotter___3qdrD{
    margin-top: 15px;
    background-color: transparent !important;
}

.contentForm___SL6gK.fotter___3qdrD .row___sxV51{
    background-color: transparent !important;
}

.button___3y8bL {
    background-color: #009DC5;
    text-decoration: none;
    text-transform: uppercase;
    padding: 16px 8px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 32px;
    cursor: pointer;
    width: 100%;
    border: none;
}

.contentTerms___3US-g span,
.contentTerms___3US-g span a{
    font-size: 12px;
}
