.jobs___3Gpxr {

}

.container___1TLwT {
    display: flex;
    max-width: 1280px;
    flex: 1;
    flex-direction: column;
    gap: 16px
}

.title___Ubs7F {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 3;
    margin-bottom: 0px;
    display: block;
    letter-spacing: -0.1px;
    color: #6b6f82;
}

.job___1Afvz {
    display: flex;
    flex-direction: column;
}

.files___7-YoD {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 8px;
}

@media only screen and (min-width: 768px) {
    .files___7-YoD {
        grid-template-columns: repeat(2,1fr);
    }
}

@media only screen and (min-width: 1024px) {
    .files___7-YoD {
        grid-template-columns: repeat(4,1fr);
    }
}