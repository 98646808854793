#homeBar___2LPAE {
  height: auto;
  background-color: #009DC5;
  position: relative;
}
#homeBar___2LPAE * {
  font-family: "Poppins", sans-serif;
}
.image___1Hu4h {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  opacity: 0.53;
  z-index: 100;
}
.navbarContainer___2T2ie {
  left: 0px !important;
  width: 100% !important;
  z-index: 100 !important;
  position: fixed !important;
  top: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  transition: 0.2s all;
  height: 80px;
}
.isScrolling___15fPH.navbarContainer___2T2ie {
  background-color: #fff;
  height: 80px;
}
.isExpanded___3geFL.navbarContainer___2T2ie {
}
.navbar___1206T {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  z-index: 120;
  height: 80px;
  min-height: 80px;
  color: #fff;
}
.isScrolling___15fPH .navbar___1206T {
  color: #000;
}
.navbar___1206T * {
  font-family: "Poppins", sans-serif;
}
.commands___IFSJ7,
.brandContainer___xCyaZ,
.menubar___vaets {
  flex: 1;
  display: flex;
  align-items: center;
}
.brand___3FJIG {
  display: flex;
  width: 196px;
  height: 35px;
  text-indent: -6000px;
  text-decoration: none;
  margin-left: 10px;
  color: #fff;
}
.isScrolling___15fPH .brand___3FJIG.discoolver___Lkz-f {
  background-image: url("/static/images/discoolver/discoover-brand.svg");
}
.brand___3FJIG.discoolver___Lkz-f {
  background-image: url("/static/images/discoolver/discoolver-brand-white.svg");
}
.brand___3FJIG.ronda___2GokA {
  background-image: url("/static/images/ronda/discoover-brand.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.image___1Hu4h::before {
  content: " ";
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    #009DC5 100%
  );
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 250px;
  z-index: 110;
}
.image___1Hu4h.small___21QbI::before {
  height: 80px;
}
.gridContainer___2OGyK {
  position: relative;
  z-index: 150;
}
.infosContainer___2-6X3,
.gridContainer___2OGyK {
  max-width: 1280px;
  flex: 1;
  margin: auto;
}
.infosContainer___2-6X3 {
  position: relative;
  z-index: 100;
  padding: 96px 0 216px 0;
  color: #fff;
}
.infosContainer___2-6X3.small___21QbI {
  padding: 80px 0 0 0;
}
.infos___3x3wz {
  max-width: 1076px;
  margin: auto;
}
.infos___3x3wz h2 {
  color: white;
  margin: 0;
  padding: 0;
  font-size: 64px;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 67px !important;
}
.infos___3x3wz.experienceDetail___3o_QK{
  max-width: 100%;
}
.commands___IFSJ7 {
  padding: 0 24px !important;
  flex: 0 1 auto !important;
}
.commandsContainer___1iyNZ {
  align-items: center !important;
  background-color: #ffffff !important;
  border: 0 solid #dddddd !important;
  border-radius: 24px !important;
  color: #222222 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  transform-origin: 0% 0% !important;
  height: 48px;
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms,
    pointer-events 0ms ease 150ms !important;
}
.desktopInput___1b4HJ {
  width: 1074px;
  height: 66px;
  border-radius: 33px !important;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15), 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
  display: inline-flex !important;
  vertical-align: middle !important;
  text-align: left !important;
  transform-origin: 50% 0% !important;
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms,
    pointer-events 0ms ease 150ms !important;
  -webkit-transform: scale(1) translate(0) !important;
  -ms-transform: scale(1) translate(0) !important;
  transform: scale(1) translate(0) !important;
  visibility: visible !important;
  opacity: 1 !important;
  cursor: pointer;
  pointer-events: all !important;
  overflow: hidden;
}
.isScrolling___15fPH .desktopInput___1b4HJ {
  -webkit-transform-origin: 50% 0% !important;
  -ms-transform-origin: 50% 0% !important;
  transform-origin: 50% 0% !important;
  -webkit-transform: scale(0.34, 1) translate(-20px, -122px) !important;
  -ms-transform: scale(0.34, 1) translate(-20px, -122px) !important;
  transform: scale(0.34, 1) translate(-20px, -122px) !important;
  opacity: 0 !important;
  -webkit-transition: -webkit-transform 150ms ease, transform 150ms ease,
    opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  -moz-transition: transform 150ms ease, opacity 50ms ease 20ms,
    visibility 0ms 150ms !important;
  transition: -ms-transform 150ms ease, -webkit-transform 150ms ease,
    transform 150ms ease, opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  pointer-events: none !important;
  visibility: hidden !important;
  will-change: transform, opacity !important;
}
.commandsContainer___1iyNZ button {
  border: 0;
  height: 100%;
  padding: 0 16px;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.commandsContainer___1iyNZ span {
  display: flex;
  flex: 1;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.64);
}
.searchIcon___1xIZX {
  background-color: #009DC5;
  background-image: url("/static/icons/search-white-2.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 64%;
  height: 32px;
  width: 32px;
  margin-left: 16px;
  border-radius: 16px;
}
.contentSearchIcon___uXITl{
  background-color: #009DC5;
  margin-left: 8px;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.searchIconText___3JYpf {
  padding: 4px 8px 4px 4px;
  color: white;
  text-decoration: none;
  font-size: 13px;
}
.searchIconText___3JYpf:last-child {
  border-bottom: 1px solid white;
}
.smallFakeInput____pdXW {
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  -webkit-transform-origin: 0% 0% !important;
  -ms-transform-origin: 0% 0% !important;
  transform-origin: 0% 0% !important;
  -webkit-transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  -ms-transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  opacity: 0 !important;
  -webkit-transition: -webkit-transform 150ms ease, transform 150ms ease,
    opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  -moz-transition: transform 150ms ease, opacity 50ms ease 20ms,
    visibility 0ms 150ms !important;
  transition: -ms-transform 150ms ease, -webkit-transform 150ms ease,
    transform 150ms ease, opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  pointer-events: none !important;
  visibility: hidden !important;
  will-change: transform, opacity !important;
  border-width: 1px !important;
}
.isScrolling___15fPH .smallFakeInput____pdXW {
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  transform-origin: 0% 0% !important;
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms,
    pointer-events 0ms ease 150ms !important;
  -webkit-transform: scale(1) translate(0) !important;
  -ms-transform: scale(1) translate(0) !important;
  transform: scale(1) translate(0) !important;
  visibility: visible !important;
  opacity: 1 !important;
  cursor: pointer;
  pointer-events: all !important;
}
.menubar___vaets {
  justify-content: flex-end;
  margin-right: 16px;
}
.menubar___vaets a {
  text-decoration: none;
  color: #fff;
  margin: 0 16px;
  display: inline-flex;
}
.isScrolling___15fPH .menubar___vaets a {
  color: #000;
}

.categories___BEpL3,
.searchbar___3Exdo,
.filterbar___3l_YX {
  background: white;
  z-index: 100 !important;
  height: 64px;
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  position: sticky;
  top: 80px;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
}

.filterbar___3l_YX {
  height: auto;
}

.searchbar___3Exdo,
.filterbar___3l_YX{
  top: 144px;
  overflow: hidden;
}
.showKeyboard___gONdg{
  height: 336px;
}
.navButtons___MJ4AY{
  display: grid;
  grid: 1fr / repeat(3, 1fr);
  grid-gap: 0 8px;
  width: 215px;
}

.navButtons___MJ4AY .results___1AiSQ {
  align-self: center;
}

.iconButton___3rsOG {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton___3rsOG .icon___3NCap {
  margin-right: 0;
}


.iconButton___3rsOG .action___IOJ6V {
  font-size: 16px;
  border: 0;
  outline: 0;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: transparent;
}

.iconButton___3rsOG.selected___1oZgH .action___IOJ6V{
  color: #009DC5;
}

.iconButton___3rsOG .action___IOJ6V .icon___3NCap {
  width: 24px;
  height: 24px;
  display: inline-flex;
  background-color: #009DC5;
  mask-position: center;
  -webkit-mask-position: center;
}

.iconButton___3rsOG.selected___1oZgH .action___IOJ6V .icon___3NCap {
  background-color: white;
}

.iconsearch___1Da_Y {
  -webkit-mask-image: url("/static/icons/search.svg");
  mask-image: url("/static/icons/search.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #009DC5;
}

.iconmap___3iG4N {
  -webkit-mask-image: url("/static/icons/map.svg");
  mask-image: url("/static/icons/map.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #009DC5;
}

.iconfilter___3p9FL {
  -webkit-mask-image: url("/static/icons/adjustments.svg");
  mask-image: url("/static/icons/adjustments.svg");
  background-color: #009DC5;
}

.noScrolling___3BeMT{
  overflow-x: unset;
}
.categories___BEpL3 > div:first-child{
  width: calc(100% - 230px);
}

.categories___BEpL3 .navButtons___MJ4AY{
  width: 225px;
  padding-right: 8px;
  padding-left: 8px;
  border-left: 1px dotted;
}

.searchbar___3Exdo > div {
  width: 100%;
}

.filterbar___3l_YX > div {
  width: 100%;
  /*overflow: unset;
  height: 36px;*/
}

.categories___BEpL3 .menuItemWrapper___2OUhx:focus {
  outline: 0 !important;
}

.innerWrapper___KuEPv > .menuItemWrapper___2OUhx:last-child > div {
  margin-right: 8px;
}

.categories___BEpL3::-webkit-scrollbar,
.searchbar___3Exdo::-webkit-scrollbar {
  height: 0 !important;
}

.categories___BEpL3.item___1cBQO:first-child,
.searchbar___3Exdo .searchMain___39iPm {
  margin-left: 8px;
}

.smallButton___3mzG4 {
}
.desktopBar___27S0s {
  margin-top: 196px;
}
.isScrolling___15fPH {
}
.isExpanded___3geFL {
}
.isScrolling___15fPH .desktopBar___27S0s{
  display: none;
}
.desktopBar___27S0s .searchIcon___1xIZX {
  position: absolute;
  right: 16px;
}
.mobileBar___1kZ7F {
  display: none;
}
.option___3EJfB {
  display: flex;
  flex: 1;
  align-items: flex-start !important;
  flex-direction: column !important;
  flex: 1 0 0% !important;
  min-width: 0px !important;
  padding: 14px 12px !important;
  height: auto !important;
}
.option___3EJfB:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.optionLabel___ruvRO {
  color: black;
  font-weight: 600;
}
.optionValue___1iSEK {
  color: rgba(0, 0, 0, 0.64);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}

.location___2KhqM {
  flex: 1;
}
.dates___15Iro {
  flex: 2;
  display: flex;
  flex-direction: row !important;
}
.start___19miX {
}
.end___1VjVI {
}
.city___3aD_v {
  flex: 1;
}
.company___nkXFG {
  flex: 1;
}
.mobileOptions___3Tgbu {
  display: none;
}
.filtersContainer___35vX8 {
  background-color: white;
  padding: 16px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15), 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
}
.filtersOptions___2NiWh {
}
.titleBar___274ou,
.filterButtons___35d4r {
  display: flex;
  flex-direction: row;
}
.titleBar___274ou {
  margin-bottom: 16px;
}
.title___2SIXy {
  flex: 1;
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.filterIcon___3tHF- {
  text-indent: -6000px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  background-color: transparent;
}
.filterIcon___3tHF-.close___15Vub {
  background-image: url("/static/icons/x-close.svg");
}
.filterIcon___3tHF-.filters___bW0jV {
  background-image: url("/static/icons/filters.svg");
}
.filterButtons___35d4r {
  margin-top: 16px;
}
.filterButtons___35d4r button {
  flex: 1;
}
.filterButtons___35d4r button:first-child {
  margin-right: 8px;
}
.filterButtons___35d4r button:last-child {
  margin-left: 8px;
}
.filtersOptions___2NiWh {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.commandOptionContainer___2Jzvl {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.commandOptionContainer___2Jzvl > .commandOption___2z5Zj:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.filtersOptions___2NiWh > .commandOption___2z5Zj:last-child {
  border-bottom: 0;
}
.commandOption___2z5Zj {
  border: 0;
  background-color: transparent;
  flex: 1 auto;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.commandOption___2z5Zj span {
  font-size: 14px;
  display: flex;
  flex: 1;
  color: rgba(0, 0, 0, 0.64);
}
.groupItem___2uCn_ {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.destinationTitle___3_0ds {
  flex: 1;
  color: rgba(0, 0, 0, 0.54);
  margin: 8px 16px;
}
.modalItem___2tEfM {
  background: transparent;
  border: 0;
  text-align: left;
  margin: 0;
  padding: 8px 16px;
  width: 100%;
}
.selected___1oZgH {
  background: #009DC5;
  color: white;
}
.languageSelector___3cyCO,
.citySelector___3nwU7{
  padding: 0 15px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  min-width: 35px;
}

.languageSelector___3cyCO::after,
.citySelector___3nwU7::after {
  content: '';
  width: 24px;
  height: 100%;
  display: block;
  -webkit-mask-image: url(/static/icons/chevron-bottom.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: right center;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.citySelector___3nwU7{
  min-width: 120px;
}

.brandContainer___xCyaZ .citySelector___3nwU7{
  color: white;
}

.isScrolling___15fPH .brandContainer___xCyaZ .citySelector___3nwU7{
  color: #000;
}

.isScrolling___15fPH .brandContainer___xCyaZ .citySelector___3nwU7::after{
  background-color: #000;
}

.isScrolling___15fPH .languageSelector___3cyCO::after{
  background-color: #000;
}

.modalProfile___3NHPZ div{
  min-width: 200px;
}

.contentSelector___pe-NM{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.mobileselector___PeA-F::after {
  background-color: #009DC5;
}

.buttonBackTotem___3tQX-{
  background: white;
  padding: 0 16px;
  border-radius: 8px;
}

.buttonBackTotem___3tQX- span{
  color: #009DC5;
  font-size: 20px;
}

.isScrolling___15fPH .buttonBackTotem___3tQX-{
  background: #009DC5;
}

.isScrolling___15fPH .buttonBackTotem___3tQX- span{
  color: white;
}

.modalDatePicker___1jStE {
  min-height: 400px !important;
}

.modalDatePickerContainer___cxK0G {
  max-height: none !important;
  overflow: hidden !important;
}

.buybutton___3spwm {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 4px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  cursor: pointer;
  max-width: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .menubar___vaets a {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1076px) {
  .desktopInput___1b4HJ {
    width: 1024px;
  }
  .infos___3x3wz {
    max-width: 1024px;
    margin: auto;
  }
  .commandsContainer___1iyNZ span,
  .searchIconText___3JYpf{
    font-size: 12px;
  }
}


@media only screen and (max-width: 991px) {
  .navbar___1206T,
  .desktopBar___27S0s {
    display: none;
  }
  .navbarContainer___2T2ie {
    height: 80px !important;
    display: flex !important;
    justify-content: space-between !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mobileBar___1kZ7F {
    display: flex;
    flex: inherit;
    margin: auto 8px;
    align-items: center;
    justify-content: space-between;
  }
  .mobileBar___1kZ7F .commandsContainer___1iyNZ{
    width: calc(100% - 40px);
  }
  .mobileBar___1kZ7F .contentButton___DW_Zt {
    width: 80px;
    display: flex;
    flex: inherit;
    margin: auto 8px;
    align-items: center;
    justify-content: space-between;
  }
  .mobileBar___1kZ7F .contentButton___DW_Zt .buttonBack___2ztmQ {
    color: white;
  }
  .mobileBar___1kZ7F .smallButton___3mzG4 {
    width: 100%;
  }
  .mobileBar___1kZ7F .mobileLanguage___cLvYM{
    color: white;
    text-align: center;
    height: 27px;
  }
  .mobileBar___1kZ7F .mobileLanguage___cLvYM::after{
    background-color: white;
  }
  .isScrolling___15fPH .mobileBar___1kZ7F .mobileLanguage___cLvYM{
    color: #000;
  }
  .isScrolling___15fPH .mobileBar___1kZ7F .mobileLanguage___cLvYM::after{
    background-color: #000;
  }
  .isScrolling___15fPH .mobileBar___1kZ7F .contentButton___DW_Zt .buttonBack___2ztmQ {
    color: #009DC5;
  }
  .infosContainer___2-6X3 {
    padding: 96px 16px 16px 16px;
  }
  .infosContainer___2-6X3 h2 {
    font-size: 32px;
    line-height: 33px !important;
  }
  .mobileOptions___3Tgbu {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.42);
    z-index: 2000;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .searchbar___3Exdo,
  .navButtons___MJ4AY {
    display: none;
  }

  .categories___BEpL3 > div:first-child{
    width: 100%;
  }
}

.avatar___30n3I{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 0 0 16px;
  background-color: rgba(0, 0, 0, .06);
  align-self: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .avatar___30n3I{
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1060px) and (max-width: 1080px) {
  .infosContainer___2-6X3,
  .gridContainer___2OGyK {
    max-width: 1060px;
  }
  .desktopInput___1b4HJ {
    width: 1020px;
  }
  .infos___3x3wz {
    max-width: 1000px;
    margin-left: 30px;
  }
}
