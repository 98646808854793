.contentProfileMenu___3I7W6{
  position: relative;
  margin: 0 16px;
}

.avatar___3Pgrh{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 0 0 16px;
  background-color: rgba(0, 0, 0, .06);
  align-self: center;
}

.buttonTotem___2ESGP a{
  background: #009DC5 !important;
  color: white  !important;
  border-radius: 8px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .avatar___3Pgrh{
    width: 40px;
    height: 40px;
  }
}
