.navigation___3om1U {
    padding: 16px 0;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    background-color: #ffffff;
    display: flex;
    position: relative;
    z-index: 90;
    flex-direction: column;
}

.filtersOptions___z3wRN {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.categoriesContainer___2_qg4 {
    width: 100%;
}

.action_button___ebupl {
    background: transparent;
    border: 0;
}

.navigation___3om1U nav {
    flex: 1;
}

.item___3iFi3 {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    line-height: normal!important;
    color: #333642;
    margin-right: 16px;
    padding: 8px 16px;
}

.accent___3Up8G {
    background-color: #009DC5;
    color: #FFF;
    border-radius: 6px;
}

.item___3iFi3 span {
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: normal!important;
}

.filters_container___2diXt {
    flex: 1;
}

.item___3iFi3 span:last-child {
    font-size: 24px;
}

.categories___VN0GF {
    flex: 1;
    display: flex;
    grid-column: 9 span;
    width: 100%;
    overflow-x: scroll !important;
}

.filters___2Awoy {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, .06);
    margin-top: 16px;
    padding-top: 16px;
}

.divider___3FlsK {
    background-color: #333642;
    width: 1px;
    height: 16px;
    margin: 0 16px;
}

.adjustments___1FgOr {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('/static/images/adjustments.svg');
}

.search____Dx-k {
    width: 40px;
    height: 40px;
    margin-left: 8px;
}

.search____Dx-k.selected___3VGtE button{
    background: #009DC5;
}


.filters_content___FBKLh {
    flex: 1;
}

.filters_container___2diXt {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr)
}

.toggleContainer___1k4LF {
    flex: 1;
    position: relative;
    display: flex;
}

.toggleButton___3TPn1 {
    flex: 1;
    background-color: #FFF;
    border: 0;
    text-align: left;
    margin-right: 10px;
    position: relative;
}

.toggleButton___3TPn1:focus{
    border: 1px solid #009DC5;
}

.DateInputFocused___3fvH2{
    border-color: #009DC5 !important;
}

.toggleButton___3TPn1::after{
    content: '';
    -webkit-mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
    mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
    -webkit-mask-size: 11px 11px;
    mask-size: cover;
    display: inline-flex;
    background-color: #009DC5;
    width: 11px;
    height: 11px;
    position: absolute;
    right: 4px;
    top: 17px;
}

.toggleButton___3TPn1 span {
    color: rgba(42, 43, 51, 0.801);
    font-size: 14px;
    margin-left: 10px;
}

.options___rqpJg {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background-color: #FFF;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(0, 0, 0, .2);
    border-radius: 4px;
    z-index: 2000;
}

.scroll___1L0Sj {
    overflow-y: scroll;
    max-height: 300px;
}

.toggleItem___1WlEd {
    background: #FFF;
    border: 0;
    text-align: left;
    padding: 8px 16px;
    display: block;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
}

.select___2MpAY {
    display: block;
    margin: 8px 16px;
}

.toggleItem___1WlEd:hover {
    background: #eaeaea;
}

.toggleItem___1WlEd>span {
    display: block;
}

.toggleContainer___1k4LF>.options___rqpJg.places___3qovk>div:first-child {
    margin-top: 16px;
}

.toggleContainer___1k4LF {
    flex: 1;
    position: relative;
    display: flex;
}

.toggleTitle___1jR6_ {
    margin: 0 8px;
    display: flex;
}

.separator___2ABpC {
    border-top: 1px solid rgba(0, 0, 0, .2);
}

.toggle___2NH9K {
    display: flex;
}

.bubble___PybmN {
    height: 44px;
    border-radius: 4px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(0, 0, 0, .2);
    background-color: #ffffff;
}

.goto___1Cggu {
    grid-column: span 1;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 2.29;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    background-color: #009DC5;
    text-decoration: none;
    text-align: center;
}

.goto___1Cggu span {
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    width: 100%;
}

/**  **/

.destinations___FE3_G {
    grid-column: 2 span;
}

.typeOfEvent___1TuF- {
    grid-column: 3 span;
}

.dates___3yD08 {
    grid-column: 3 span;
}

.type___30UG7 {
    grid-column: 3 span;
}

.filtersCommands___PIzWV {
    display: flex;
    align-items: center;
    grid-column: span 3;
}

.map___2D03L,
.filtersAction___3pcq9{
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.map___2D03L.selected___3VGtE button{
    background: #009DC5;
}

.filtersCommands___PIzWV .action___uifCs {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #009DC5;
    background-color: #ffffff;
}

.filtersCommands___PIzWV .search____Dx-k {
    margin-right: 14px;
}

.search____Dx-k.selected___3VGtE button{
    background: #009DC5;
  }

.filtersCommands___PIzWV .action___uifCs .icon___27XAQ {
    margin-right: 0;
}

.icon___27XAQ {
    width: 24px;
    height: 24px;
    display: inline-flex;
    margin-right: 8px;
    background-color: #333642;
    mask-position: center;
    -webkit-mask-position: center;
}

.selected___3VGtE .icon___27XAQ {
    background-color: #FFF;
}

.icon___27XAQ.selected___3VGtE {
    background-color: white;
}

.magnify___3V_6b {
    -webkit-mask-image: url('/static/icons/search.svg');
    mask-image: url('/static/icons/search.svg');
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;
}

.iconmap___2MTUo {
    -webkit-mask-image: url('/static/icons/map.svg');
    mask-image: url('/static/icons/map.svg');
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;
}

.iconmap___2MTUo.active___2nv7O{
    background-color: white;
}

.filtersIcon___3_0-p {
    -webkit-mask-image: url('/static/icons/adjustments.svg');
    mask-image: url('/static/icons/adjustments.svg')
}

.button___qlJU3 {
    height: 32px;
    border-radius: 8px;
    border: solid 1px #009DC5;
    padding: 0 16px;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    color: #009DC5;
    text-decoration: none;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
}

.button___qlJU3.active___2nv7O {
    background: #009DC5;
    color: #FFF;
}

.button___qlJU3.action_visibility___3nGgV {
    background-color: white!important;
    font-size: 14px;
}

.results___2AiBG {
    position: relative;
    margin-left: 10px
}

.results___2AiBG::before{
    content: ' ';
    position: absolute;
    width: 1px;
    height: 40px;
    background: rgba(0, 0, 0, 0.16);
    left: -10px;
    top: -10px;
}

@media (max-width: 1024px) {
    .toggleButton___3TPn1 {
        margin-right: 0;
    }
    .categories___VN0GF {
        overflow-x: scroll!important;
        grid-column: 4 span;
    }
    .filtersCommands___PIzWV{
        grid-column: 2 span;
    }
    .categories___VN0GF::-webkit-scrollbar {
        width: 0 !important;
        height: 0!important;
    }
    .categories___VN0GF nav {
        width: 100%;
    }
    .dates___3yD08, .type___30UG7, .destinations___FE3_G, .typeOfEvent___1TuF- {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .dates___3yD08 {
        grid-column: span 4;
        grid-row: 2;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .type___30UG7 {
        grid-column: span 4;
        grid-row: 5;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .destinations___FE3_G {
        grid-column: span 2;
        grid-row: 1;
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .typeOfEvent___1TuF- {
        grid-column: span 4;
        grid-row: 1;
        grid-column-start: 4;
        grid-column-end: 6;
    }
    .goto___1Cggu {
        grid-column: span 4;
        grid-row: 6;
        grid-column-start: 2;
        grid-column-end: 6;
    }
}

@media (max-width: 768px) {
    .categories___VN0GF {
        grid-column: 4 span;
    }
    .filtersCommands___PIzWV{
        grid-column: 2 span;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .navigation___3om1U{
        background-color: #ffffffe4;
    }
    .toggleButton___3TPn1, .goto___1Cggu {
        margin: 0 10px;
    }
    .dates___3yD08 {
        grid-column: span 2;
        grid-row: 2;
    }
    .type___30UG7 {
        grid-column: span 2;
        grid-row: 4;
    }
    .destinations___FE3_G {
        grid-column: span 2;
        grid-row: 1;
    }
    .goto___1Cggu {
        grid-column: span 2;
        grid-row: 5;
    }
    .typeOfEvent___1TuF- {
        grid-column: span 2;
        grid-row: 3;
    }
    .filtersCommands___PIzWV {
        display: none;
        grid-column: span 1;
    }
    .results___2AiBG {
        display: none;
    }
}
