.person___3Du6q{
    width: 100%;
    margin-bottom: 32px;
    text-align: center;
}
.person___3Du6q h3{
    font-size: 24px;
}
.container___2moix{
    margin: 0 16px;
}
.image___23jvC{
    background-position: center;
    background-size: cover;
    width: 140px;
    height: 140px;
    margin: auto;
    border-radius: 70px;
    margin-bottom: 24px;
}