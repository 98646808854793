.popover___hYjpG {
  position: absolute;
  top: 45px;
  right: 0;
  min-width: 262px;
  box-sizing: border-box;
  border: 1px solid #009DC5;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: white;
}

.popover___hYjpG::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #009DC5 transparent;
  position: absolute;
  top: -10px;
  right: 50px;
}

.popover___hYjpG::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  position: absolute;
  top: -9px;
  right: 50px;
}

.popover___hYjpG ul li a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.popover___hYjpG ul li a::before{
  content: ' ';
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #009DC5;
  background-image: unset;
  mask-position: center;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.config___FFde_::before{
  content: '';
  -webkit-mask-image: url('/static/icons/config.svg');
  mask-image: url('/static/icons/config.svg');
}

.interest___3caLZ::before{
  content: '';
  -webkit-mask-image: url('/static/icons/interest.svg');
  mask-image: url('/static/icons/interest.svg');
}

.heart___1Tixo::before{
  content: '';
  -webkit-mask-image: url('/static/icons/love.svg');
  mask-image: url('/static/icons/love.svg');
}

.logout___3X6jS::before{
  content: '';
  -webkit-mask-image: url('/static/icons/logout.svg');
  mask-image: url('/static/icons/logout.svg');
}
