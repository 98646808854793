.title___3XJ1i{
  grid-column: span 12;
  padding: 0 16px;
}

.title___3XJ1i .h1___2GTh2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  display: inline;
  position: relative;
  margin-bottom: 6px;
}
/*
.title .h1::after{
  content: ' ';
  position: absolute;
  height: 6px;
  width: 50%;
  background: #333642;
  bottom: -6px;
  right: 0;
}
*/

@media (max-width: 768px){
  .title___3XJ1i .h1___2GTh2 {
    font-size: 36px;
  }
}

@media (max-width: 468px){
  .title___3XJ1i .h1___2GTh2 {
    font-size: 24px;
  }
}
