.contentPlan___3QKtK{

}

.contentDays___2RFb3 {
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(51, 54, 66, 0.61);
  background-color: #ffffff;
  max-width: 100%;
  padding: 12px 95px;
  box-sizing: border-box;
}

.days___28JA0 {
  flex: 1;
  display: flex;
  grid-column: 12 span;
  width: 100%;
  overflow-x: auto !important;
}

.day___mR6o1 {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: normal!important;
  color: #333642;
  margin-right: 16px;
  padding: 8px 16px;
  cursor: pointer;
}

.accent___Df7V3 {
  background-color: #009DC5;
  color: #FFF;
  border-radius: 6px;
}

.day___mR6o1 span {
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: normal!important;
}

.day___mR6o1 span:last-child {
  font-size: 24px;
}

.contentGenerate___kWEwN{
  flex: 1;
  display: flex;
  grid-column: 3 span;
  width: 100%;
  justify-content: flex-end;
}

.button___2nCBG {
  text-decoration: none;
  text-transform: uppercase;
  padding: 11px 28px;
  display: block;
  text-align: center;
  color: #FFF;
  border-radius: 40px;
  cursor: pointer;
  border: 0;
  align-self: center;
}

.buttonGenerate___3Rh4h{
  background-color: #009DC5;
}

.buttonWhite___2jt54{
  background-color: white;
  border: 1px solid #009DC5;
  color: #009DC5;
}

.contentDetail___rml3Y{
  margin: 12px auto 50px;
  grid-column: span 12;
}

.crumbRoad___25KLi{
  grid-column: span 6;
  padding: 0 20px;
}

.contentRecommendedInfo___1Zwu4{
  display: flex;
  flex-direction: row;
  grid-column: 12 span;
  padding-bottom: 20px;
}

.contentShowMap___jNHfx{
  grid-column: span 6;
  text-align: right;
  padding: 0 20px;
}

.contentRecommended___15ZWB{
  grid-column: span 12;
  overflow-y: auto;
  flex: 1 1 auto;
  padding: 0 20px;
}

.showMap___domMl{
  grid-column: span 7;
}

.contentMap___IndhQ{
  position: relative;
  grid-column: 5 span;
  flex: 2 1 auto;
}

.recommended___2lFlB{
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.recommended___2lFlB .image___3YTKz{
  min-height: 245px;
  height: auto;
  width: 50%;
  max-width: 305px;
  background-size: cover;
  background-position: center;
}

.data___2ZQyG{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 18px;
  justify-content: space-around;
}

.data___2ZQyG .name___1sQMr{
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.18px;
  color: #2f2f2f;
  position: relative;
  padding-right: 40px;
}

.data___2ZQyG .name___1sQMr button{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  background-color: white;
  border: 1px solid #2f2f2f;
  border-radius: 34px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.data___2ZQyG .name___1sQMr button::before{
  content: ' ';
  -webkit-mask: url(/static/icons/delete.svg) no-repeat 50% 50%;
  mask: url(/static/icons/delete.svg) no-repeat 50% 50%;
  background-color: #2f2f2f;
  width: 100%;
  height: 100%;
  display: block;
}

.data___2ZQyG div .date___QeavH,
.data___2ZQyG div .address___1vMwW{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38 !important;
  letter-spacing: -0.1px;
  text-align: justify;
  color: #2f2f2f;
  padding-left: 30px;
  position: relative;
  margin-bottom: 12px;
  display: block;
}

.data___2ZQyG div .date___QeavH::before,
.data___2ZQyG div .address___1vMwW::before{
  content: ' ';
  display: inline-block;
  height: 19px;
  width: 19px;
  margin-right: 4px;
  top: -2px;
  left: 0;
  position: absolute;
  background-color: #2f2f2f;
}

.data___2ZQyG div .date___QeavH::before {
  -webkit-mask: url(/static/icons/calendar-white.svg) no-repeat 50% 50%;
  mask: url(/static/icons/calendar-white.svg) no-repeat 50% 50%;
}

.data___2ZQyG div .address___1vMwW::before{
  -webkit-mask: url(/static/icons/map-pin-white.svg) no-repeat 50% 50%;
  mask: url(/static/icons/map-pin-white.svg) no-repeat 50% 50%;
}

.contentAction___1NwOv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contentAction___1NwOv a{
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.1px;
  text-align: justify;
  color: #009DC5;
  display: inline-block;
}

.contentAction___1NwOv a::before{
  content: '+';
  margin-right: 8px;
}

.contentFooter___1_coR {
  grid-column: span 12;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.contentFooter___1_coR button{
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 9px 8px;
  display: block;
  text-align: center;
  color: #FFF;
  border-radius: 32px;
  cursor: pointer;
  border: 0;
  min-width: 204px;
  display: inline;
}

.mapViewer___2inPP{
  height: 100% !important;
}

@media (max-width: 1024px) and (min-width: 768px){
  .contentDays___2RFb3{
    padding: 20px;
  }
  .days___28JA0{
    grid-column: 6 span;
  }
}

@media (max-width: 768px){
  .contentRecommendedInfo___1Zwu4{
    flex-direction: column-reverse;
  }
  .contentFooter___1_coR{
    position: fixed;
    height: 48px;
    display: block;
    width: 100%;
    bottom: 64px;
    flex-direction: column;
    justify-content: center;
  }

  .contentFooter___1_coR button{
    min-width: 50%;
    max-width: 50%;
    height: 48px;
    border-radius: 0;
  }

  .contentFooter___1_coR button:first-child {
    filter: brightness(85%);
    position: absolute;
    left: 0;
  }

  .contentFooter___1_coR button:last-child {
    position: absolute;
    right: 0;
  }
  
  .contentRecommended___15ZWB{
    padding: 0 16px;
  }

  .crumbRoad___25KLi{
    grid-column: 12 span;
    padding: 0 16px;
  }

  .contentShowMap___jNHfx{
    grid-column: 12 span;
    padding: 0 16px;
  }

  .mapViewer___2inPP{
    height: 100vh !important;
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  .showMap___domMl{
    grid-column: span 12;
  }
  .contentMap___IndhQ{
    position: relative;
    grid-column: 12 span;
  }
  .contentDays___2RFb3{
    padding: 8px;
  }
  .days___28JA0{
    grid-column: 2 span;
  }
  .contentDetail___rml3Y {
    flex-direction: column-reverse;
  }

  .mapViewer___2inPP{
    height: 100vh !important;
  }
  .recommended___2lFlB{
    flex-direction: column;
  }

  .recommended___2lFlB .image___3YTKz {
    width: 100%;
    max-width: 100%;
  }

  .data___2ZQyG{
    padding: 0;
  }

  .data___2ZQyG .name___1sQMr {
    font-size: 20px;
    min-height: 60px;
  }

  .data___2ZQyG div .date___QeavH,
  .data___2ZQyG div .address___1vMwW {
    font-size: 12px;
  }

  .contentAction___1NwOv{
    flex-direction: column;
  }

  .contentAction___1NwOv a{
    margin-top: 16px;
  }
}
