.contentPlan___1w3EC{
  margin: 100px auto 0;
}

.title___TfqeX{
  height: 71px;
  grid-column: span 12;
  margin-bottom: 135px;
}

.title___TfqeX h1{
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2 !important;
  letter-spacing: -0.17px !important;
  color: #28d094;
  max-width: 409px;
  position: relative;
  padding: 0 20px;
}

.title___TfqeX h1::before{
  content: '';
  color: #28d094;
  position: absolute;
  font-size: 27px;
  left: 0;
}

.contentTree___tZ-vX{
  grid-column: span 12;
}

.contentRow___ranAS{
  display: grid;
  grid: 1fr / repeat(12, 1fr);
  grid-gap: 0 8px;
  margin-bottom: 20px;
}

.calendar___2ZDdX{
  grid-column: span 2;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.calendar___2ZDdX label{
  font-family: "Poppins", sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  height: 40px;
  width: 80px;
  position: relative;
  background-color: #009DC5;
  color: #edeef4;
  text-transform: uppercase;
}

.calendar___2ZDdX span{
  font-family: "Poppins", sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  height: 40px;
  width: 80px;
  position: relative;
  background-color: #009DC5;
  color: #edeef4;
  text-transform: uppercase;
}

.calendar___2ZDdX label{
  font-size: 14px;
  font-weight: normal;
  line-height: 2.29 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.calendar___2ZDdX span{
  font-size: 22px;
  font-weight: 600;
  line-height: 1.45 !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.calendar___2ZDdX label:before,
.calendar___2ZDdX span:before {
  content: attr(data-value);
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.calendar___2ZDdX label:before{
  bottom: 0;
  line-height: 1.4;
}

.calendar___2ZDdX span:before{
  top: 0;
  line-height: 1;
}

.day___PDwLI{
  grid-column: span 1;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.18px;
  color: #2f2f2f;
}

.empty___1cx-D{
  grid-column: span 2;
}

.hour___3lsFe{
  grid-column: span 1;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45 !important;
  letter-spacing: normal;
  color: #333642;
  position: relative;
}

.hour___3lsFe::after{
  content: '';
  background-color: #333642;
  position: absolute;
  width: 3px;
  height: calc(100% - 34px);
  top: 34px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.recommended___faj2n{
  grid-column: span 6;
  display: flex;
  padding-bottom: 24px
}

.image___1b7xI{
  width: 192px;
  height: 120px;
  background-size: cover;
  background-position: center center;
}

.data___17PTs {
  display: flex;
  flex-direction: column;
  padding: 17px;
  justify-content: center;
  width: calc(100% - 192px);
}

.name___2fO3r {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  padding-bottom: 10px;
}

.address___7-Dki {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38 !important;
  letter-spacing: -0.1px;
  text-align: justify;
  color: #2f2f2f;
  position: relative;
  padding-left: 28px;
}

.address___7-Dki::before {
  content: url('/static/icons/map-pin.svg');
  position: absolute;
  left: 0;
  top: -2px;
}

.buy___22J6j{
  grid-column: span 3;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.buy___22J6j div{
  display: flex;
}

.buy___22J6j a{
  background-color: #009DC5;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  text-transform: uppercase;
  align-self: center;
  padding: 6px 12px;
  text-decoration: none;
}

.buy___22J6j div a{
  background-color: #009DC5;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  text-transform: uppercase;
  align-self: center;
  padding: 6px 12px;
  text-decoration: none;
}

.contentQRCode___WgRNj {
  grid-column: span 12;
  text-align: center;
}

.contentQRCode___WgRNj h1 {

}

.button___1xZj3 {
  text-decoration: none;
  text-transform: uppercase;
  padding: 11px 28px;
  display: block;
  text-align: center;
  color: #FFF;
  border-radius: 40px;
  cursor: pointer;
  border: 0;
  align-self: center;
}

.buttonWhite___17xr_{
  background-color: white;
  border: 1px solid #009DC5;
  color: #009DC5;
}

@media (max-width: 1024px) and (min-width: 768px){
  .contentPlan___1w3EC{
    margin: 50px auto;
  }
  .title___TfqeX{
    margin-bottom: 40px;
  }
  .calendar___2ZDdX{
    grid-column: span 2;
  }

  .day___PDwLI{
    grid-column: span 5;
  }

  .empty___1cx-D{
    grid-column: span 1;
  }

  .recommended___faj2n{
    grid-column: span 10;
  }

  .buy___22J6j{
    grid-column: span 12;
  }

}

@media (max-width: 768px){

}

@media (max-width: 480px){
  .contentPlan___1w3EC{
    margin: 20px auto 40px;
  }
  .title___TfqeX{
    margin-bottom: 20px;
  }
  .title___TfqeX h1{
    font-size: 20px;
    padding: 0 16px;
  }
  .calendar___2ZDdX{
    grid-column: span 3;
    align-items: center;
  }
  .calendar___2ZDdX label,
  .calendar___2ZDdX span{
    width: 60px;
    height: 30px;
  }
  .day___PDwLI {
    grid-column: span 6;
    font-size: 20px;
  }
  .empty___1cx-D{
    grid-column: none;
  }
  .hour___3lsFe{
    grid-column: span 3;
    font-size: 12px;
  }
  .recommended___faj2n{
    grid-column: span 9;
    flex-direction: column;
  }
  .image___1b7xI{
    width: 100%;
  }
  .data___17PTs{
    width: 100%;
    padding: 4px 0;
    box-sizing: border-box;
  }
  .buy___22J6j{
    grid-column: span 12;
    justify-content: flex-end;
    padding: 8px 10px;
  }
}
