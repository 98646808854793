.content___1wJHi{
  display: inline-block;
}

.contentRadio___3bORy{
  position: relative;
  margin-right: 28px;
}

.contentRadio___3bORy input{
  opacity: 0;
  width: 0;
  height: 0;
}

.radio___X5Nqu {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  border: 1px solid #cd22a6;
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.contentRadio___3bORy input:checked + .radio___X5Nqu{
  background-color: #cd22a6;
}

.contentRadio___3bORy input:checked + .radio___X5Nqu::after{
  content: '\2713';
  color: white;
  position: relative;
  top: -4px;
}

/*
.radio,
.selected {
	position: relative;
	padding-left: 25px;
  float: left;
  padding-right: 5px;
  cursor: pointer;
}

.radio:before{
  content: '';
  border-radius: 100%;
  border: 1px solid #cd22a6;
  position: absolute;
  left: -1px;
  top: 1px;
  width: 19px;
  height: 19px;
  transition: all 0.3s ease 0s;
  box-shadow: 0;
}

.selected:after{
  content: '';
  border-radius: 100%;
  background: #cd22a6;
  position: absolute;
  left: 1px;
  top: 3px;
  width: 17px;
  height: 17px;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 16px 24px rgba(205, 34, 166, 0.4);
}

*/
