.experienceitem___1DEkD {
  height: 380px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #e7e7e7;
  margin-right: 16px;
  margin-bottom: 32px;
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;
  pointer-events: fill;
}

.experienceitem___1DEkD::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  /* background: red; */
  top: 0;
  left: 0;
}

.experienceitem___1DEkD:hover::after,
.experienceitem___1DEkD:active:after,
.experienceitem___1DEkD:visited:after {
  background-color: #00000055;
}

.image___33NQi {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 235px;
  position: relative;
}

.experienceitem___1DEkD.loading___ISct4 {
  -webkit-filter: blur(2px);
}

div[class^="swiper_"] .experienceitem___1DEkD {
  width: 395px !important;
  overflow: hidden;
  background-size: 100% 235px;
  background-repeat: no-repeat;
  background-position: top;
}

.infos___1N2FW {
  background-color: rgba(51, 54, 66, 1);
  padding: 10px 24px;
  height: 140px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: #edeef4;
}

.infos___1N2FW h4 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600; /*SemiBold = 600*/
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #edeef4;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.infos___1N2FW > div {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
}

.infos___1N2FW .description___2UPwe {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #edeef4;
  max-height: 52px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.commands___3kv82 {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
}

.heart___3ficM {
  width: 34px;
  height: 34px;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  margin-left: 16px;
  background-image: url("/static/icons/love.svg");
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: fill;
  border: 0;
}

.accent___xkh6Y {
  border-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNjNDMyYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjYzQzMmJlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMS41IDIwLjVzLTktNS05LTEyYzAtMiAxLTUgNC01czUgMi4yNSA1IDIuMjUgMi0yLjI1IDUtMi4yNSA0IDMgNCA1YzAgNy05IDEyLTkgMTJ6Ii8+Cjwvc3ZnPgo=");
}

.infos___1N2FW .category___1X2QU {
  background-color: #009DC5;
  padding: 0 3px;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.2;
  letter-spacing: normal;
  display: inline-block;
}

.category___1X2QU {
  margin-right: 8px;
}

.first_item___3Agcx {
  /* margin-left: 80px; */
}

.categories___3eTh0 {
  display: none !important;
}

.fullscreen___3rPJ9 {
  position: fixed;
  top: 0;
}

.fullscreen___3rPJ9::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffffaa;
}

@media only screen and (max-width: 991px) {
  div[class^="swiper_"] .experienceitem___1DEkD {
    width: calc(100vw - 64px) !important;
    margin-left: 16px;
  }
  .experienceitem___1DEkD {
    margin-right: 0px !important;
    background-color: transparent;
    height: 292px !important;
  }
  .experienceitem___1DEkD * {
    font-family: "Poppins", sans-serif !important;
  }
  .experienceitem___1DEkD .infos___1N2FW {
    background-color: transparent;
    padding: 10px 0;
    height: 140px;
    position: inherit !important;
    bottom: inherit !important;
    left: inherit !important;
    right: inherit !important;
    display: flex;
    flex-direction: column;
    color: #000;
  }
  .experienceitem___1DEkD .infos___1N2FW h4 {
    text-shadow: none !important;
    font-size: 25px;
    font-weight: 400 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22 !important;
    letter-spacing: -0.14px;
    color: #000;
  }
  .experienceitem___1DEkD .infos___1N2FW > div {
    display: block !important;
  }
  .experienceitem___1DEkD .image___33NQi {
    height: 180px;
    border-radius: 6px;
  }
  .experienceitem___1DEkD .image___33NQi .categories___3eTh0 {
    display: flex !important;
    position: absolute;
    left: 8px;
    top: 8px;
  }
  .experienceitem___1DEkD .image___33NQi .category___1X2QU {
    color: #fff;
    background-color: #009DC5;
    padding: 4px 8px;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .experienceitem___1DEkD .infos___1N2FW .category___1X2QU {
    display: none;
  }
  .experienceitem___1DEkD .infos___1N2FW .description___2UPwe {
    color: rgba(0, 0, 0, 0.64);
  }
  .first_item___3Agcx {
    margin-left: 0;
  }
}
