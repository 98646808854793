.navigation___Jvmt2 {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;
}

.navigationScroll___1-MP0 {
    width: 100%
}

.filters___1Lb4A {
    padding: 16px 0;
    padding-bottom: 0!important;
    border-bottom: 1px solid #EAEAEA;
}

.action_button___1MUF2 {
    background: transparent;
    border: 0;
}

.content___1ss6g {
    display: flex;
    flex-direction: column;
}

.content___1ss6g .button___TEX0h {
    align-self: flex-end;
    margin-right: 16px;
}

.left___21K_F {
    flex: 1;
}
.right___2X2v0{
    padding: 16px 0;
}

.filters___1Lb4A>div:first-child {
    margin: 0!important;
    display: flex;
    flex: 1;
}

.show_subcategories___UnyMS {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -13px;
    text-align: center;
    z-index: 1;
}

.show_button___shhi6 {
    background-color: #009DC5;
    padding: 8px 16px;
    border-radius: 8px;
    color: #FFF;
    font-size: 12px!important;
    font-weight: bold;
    text-decoration: none;
}

.navigationContainer___2Y8nv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subcategories___1KvTV {
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
}

.navigation___Jvmt2 .left___21K_F {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr)
}

.filter___1m-ei {
    padding-right: 16px;
    margin-bottom: 16px;
}

.navigation___Jvmt2 .left___21K_F .container___31tIe {
    width: 100%;
}

.item___3mF4I {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 2.29;
    color: #333642;
    margin-right: 16px;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
}

.accent___2GmHO {
    background-color: #009DC5;
    color: #FFF;
    border-radius: 6px;
}

.inputContainer___Tut5x {
    display: flex;
    margin-right: 16px;
}

.input___3T6j0 {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 8px 16px;
    margin-right: 16px;
    flex: 1;
}

.button___TEX0h {
    background-color: #009DC5;
    border: 0;
    border-radius: 24px;
    padding: 8px 16px;
    color: white;
}

.item___3mF4I span {
    text-transform: uppercase;
}

.categories___axt5N {
    padding: 12px 0;
    width: 100%;
    position: relative;
}

.filtersOptions___3PfRE {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.divider___2qwSO {
    background-color: #333642;
    width: 1px;
    height: 16px;
    margin: 0 16px;
}

.adjustments___1EyjR {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('/static/images/adjustments.svg');
}

.search___3rpvx {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    background: url('/static/images/search.svg');
}

.icon___1tS-F {
    width: 24px;
    height: 24px;
    display: inline-flex;
    margin-right: 8px;
    background-color: #009DC5;
    mask-position: center;
    -webkit-mask-position: center;
}

.accent___2GmHO .icon___1tS-F {
    background-color: white;
}

.forYou___2ggRx {
    -webkit-mask-image: url('/static/icons/profile.svg');
    mask-image: url('/static/icons/profile.svg');
}

.alojamiento___30eVT {
    -webkit-mask-image: url('/static/icons/office.svg');
    mask-image: url('/static/icons/office.svg')
}

.restaurantes___2g1P0 {
    -webkit-mask-image: url('/static/icons/food.svg');
    mask-image: url('/static/icons/food.svg')
}

.que_ver___oqi3N {
    -webkit-mask-image: url('/static/icons/picture.svg');
    mask-image: url('/static/icons/picture.svg')
}

.fiesta___1SGYK {
    -webkit-mask-image: url('/static/icons/music-note.svg');
    mask-image: url('/static/icons/music-note.svg')
}

.ocio___2CuOZ {
    -webkit-mask-image: url('/static/icons/beer.svg');
    mask-image: url('/static/icons/beer.svg')
}

.compras___RETgA {
    -webkit-mask-image: url('/static/icons/shop.svg');
    mask-image: url('/static/icons/shop.svg')
}

.experiencias_actividades___2YStB{
    -webkit-mask-image: url('/static/icons/tasks.svg');
    mask-image: url('/static/icons/tasks.svg')
}

.formInput___3qUI2 div {
    display: flex;
    width: 100%;
    margin: 0 16px;
}

.formInput___3qUI2 input {
    flex: 1;
    border: 1px solid #EAEAEA;
    height: 40px;
    padding-left: 16px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.formInput___3qUI2 button {
    flex: initial!important;
    text-indent: -6000px;
    width: 60px;
    background-image: url('/static/icons/search-white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #009DC5;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

@media only screen and (max-width: 991px) {
    .filters___1Lb4A>div {
        flex-direction: column-reverse;
    }
    .filter___1m-ei {
        padding-left: 16px;
        padding-top: 16px;
    }
    .filters___1Lb4A .container___31tIe>div>div {
        display: flex;
    }
    .content___1ss6g .button___TEX0h {
        align-self: stretch;
        flex: 1;
        margin: 0 16px;
    }
    .filters___1Lb4A button {
        flex: 1;
        justify-content: center;
    }
    .filters___1Lb4A>div:first-child {
        align-items: initial;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .filters___1Lb4A .left___21K_F>div {
        flex: 1;
    }
    .navigationContainer___2Y8nv {
        overflow-x: scroll!important;
    }
    .navigationContainer___2Y8nv::-webkit-scrollbar {
        width: 0 !important;
        height: 0!important;
    }
    .navigationContainer___2Y8nv nav {
        width: 1200px;
        overflow: hidden;
    }
    .results___3Ash4 {
        display: none!important;
    }
    .left___21K_F {
        grid-template-columns: repeat(2, 1fr)!important;
    }
}
