.checkoutContent___3AfkT{
  background-color: rgba(237, 238, 244, 0.35);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 44px 57px;
  box-sizing: border-box;
}

.checkoutContentProducts___3mAHb{
  width: 65%;
  padding: 0 33px;
  box-sizing: border-box;
}

.checkoutContentForm___VXGSl{
  width: 35%;
  padding: 0 33px;
  box-sizing: border-box;
}

.checkoutContentFormTitle___2CP13{
  display: block;
  height: 28px;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #333642;
  margin-bottom: 25px;
}

.checkoutContentFormSubtitle___1lhFr{
  display: block;
  height: 44px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #6b6f82;
  margin-bottom: 30px;
}

.input___UbqaG{
  height: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff !important;
  padding: 0px 15px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
}

.button___PMk1A {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.register__checkNewsletter___2JLUS{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .checkoutContent___3AfkT{
    padding: 22px 30px;
  }

  .checkoutContentForm___VXGSl,
  .checkoutContentProducts___3mAHb{
    width: 50%;
    padding: 0 20px;
  }

}

@media only screen and (max-width: 767px) {
  .checkoutContent___3AfkT{
    flex-direction: column;
    padding: 12px;
  }
  .checkoutContentForm___VXGSl,
  .checkoutContentProducts___3mAHb{
    width: 100%;
    padding: 0 12px;
  }

  .checkoutContentProducts___3mAHb{
    margin-top: 20px;
  }

}
