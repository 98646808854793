.navigation___1owAz {
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  position: sticky;
  position: -webkit-sticky;
  top: 81px;
  z-index: 300;
}

.navigationOpened___ycIjU {
  z-index: 100;
}

.main___3LLaY {
  height: 100px;
  display: flex;
  justify-content: center;
}

.mapActive___gtpk9 {
  background: red;
}
.main___3LLaY.searchMain___JHfcR,
.main___3LLaY.mobileCommands___16hZ8,
.main___3LLaY.subcategoriesCollapsed___1r95W {
  height: auto !important;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.main___3LLaY.searchMain___JHfcR.totem___eFh7r{
  flex-direction: column;
  height: 300px;
}

.subcategoriesCollapsed___1r95W .subcategoriesContent___1RKkR {
  align-items: center;
  justify-content: center;
}

.subcategories___YI6E0 {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.mobileCommands___16hZ8 {
  display: none;
}

.main___3LLaY.mobileCommands___16hZ8 .container___la2DP > .button___3oQMq {
  margin: 15px 10px;
}

.main___3LLaY.mobileCommands___16hZ8 .container___la2DP > .button___3oQMq:first-child {
  margin-right: 0;
}

.main___3LLaY.mobileCommands___16hZ8 .container___la2DP > .button___3oQMq:last-child {
  margin-left: 0;
}

.container___la2DP {
  display: grid;
  grid: 1fr / repeat(12, 1fr);
  grid-gap: 0 8px;
  max-width: 1280px;
  flex: 1;
}

.categories___25lhu {
  grid-column: span 9;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  white-space: nowrap;
}

.categories___25lhu > div {
  width: 100%;
}

.categories___25lhu .menuItemWrapper___iYwxI:focus {
  outline: 0 !important;
}

.innerWrapper___36BXw > .menuItemWrapper___iYwxI:last-child > div {
  margin-right: 8px;
}

.categories___25lhu::-webkit-scrollbar {
  height: 0 !important;
}

.categories___25lhu.item___7VrS0:first-child {
  margin-left: 8px;
}

.arrowDisabled___3oZFi {
  display: none;
}

.searchInput___2VaiW {
  border-bottom: solid 1px #979797;
  grid-column: span 12;
  margin: 30px 0;
  display: flex;
  position: relative;
}

.searchInput___2VaiW form {
  width: calc(100% - 50px);
}

.searchInput___2VaiW input {
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  border: 0;
  outline: 0;
  border-radius: 0;
  padding-left: 24px;
  background-color: transparent;
  background-image: url("/static/icons/search.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.searchInput___2VaiW input::placeholder {
  text-transform: uppercase;
}

.searchInput___2VaiW button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  text-indent: -6666px;
  background-image: url("/static/icons/close.svg");
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.item___7VrS0 {
  cursor: pointer;
  text-decoration: none;
  color: #333642;
  margin-left: 8px;
  padding: 8px 16px;
  flex: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.item___7VrS0.selected___2Usmi {
  background-color: #009DC5;
  color: #fff;
}

.dayitem___12Bcz {
  text-decoration: none;
  font-family: Montserrat;
  font-size: 14px;
  line-height: normal!important;
  color: #333642;
  margin-right: 16px;
  padding: 8px 16px;
}

.dayitem___12Bcz span {
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: normal!important;
}

.dayitem___12Bcz span:last-child {
  font-size: 24px;
}

.dayitem___12Bcz.selected___2Usmi  {
  background-color: #009DC5;
  color: #fff;
  border-radius: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.filtersCommands___1Lo9E {
  display: flex;
  align-items: center;
  grid-column: span 3;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.map___3jUtV,
.filtersAction___1q_yD {
  display: flex;
  padding: 4px;
  box-sizing: border-box;
}

.map___3jUtV.selected___2Usmi button {
  background: #009DC5;
}

.filtersAction___1q_yD.selected___2Usmi button {
  background: #009DC5;
}

.filtersCommands___1Lo9E .action___3551T {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
}

.filtersCommands___1Lo9E .search___2cIaj {
}

.search___2cIaj.selected___2Usmi button {
  background: #009DC5;
}

.filtersCommands___1Lo9E .action___3551T .icon___1BAKR {
  margin-right: 0;
}

.arrowLeft___1WSrN,
.arrowRight___3o7hC {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -6666px;
}

.arrowLeft___1WSrN {
  background-image: url("/static/images/chevron-left.svg");
}

.arrowRight___3o7hC {
  background-image: url("/static/images/chevron-right.svg");
}

.action___3551T {
  font-size: 16px;
  border: 0;
  outline: 0;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: transparent;
}

.action___3551T.selected___2Usmi {
  color: #009DC5;
}

.search___2cIaj {
  display: flex;
  padding: 4px;
  box-sizing: border-box;
}

.results___3HeUy {
  position: relative;
  margin-left: 0px; /*estaba en 10px*/
}

.results___3HeUy::before {
  /* content: ' '; */
  position: absolute;
  width: 1px;
  height: 40px;
  background: rgba(0, 0, 0, 0.16);
  left: -10px;
  top: -10px;
}

.icon___1BAKR {
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 8px;
  background-color: #333642;
  mask-position: center;
  -webkit-mask-position: center;
}

.selected___2Usmi .icon___1BAKR {
  background-color: white;
}

.icon___1BAKR.selected___2Usmi {
  background-color: #009DC5;
}

.wetravelhum___38Ij4 {
  -webkit-mask-image: url("/static/icons/airplane.svg");
  mask-image: url("/static/icons/airplane.svg");
}

.foryou___QKDah,
.para_ti___2b1K8 {
  -webkit-mask-image: url("/static/icons/profile.svg");
  mask-image: url("/static/icons/profile.svg");
}

.alojamiento___6rOK5,
.accommodations___3pPES {
  -webkit-mask-image: url("/static/icons/office.svg");
  mask-image: url("/static/icons/office.svg");
}

.restaurante___3Rw_t,
.restaurantes___2UfpM,
.restaurants___2-5Gv {
  -webkit-mask-image: url("/static/icons/food.svg");
  mask-image: url("/static/icons/food.svg");
}

.que_ver___3eyi9,
.to_see___1F6L7 {
  -webkit-mask-image: url("/static/icons/picture.svg");
  mask-image: url("/static/icons/picture.svg");
}

.fiesta___Eoevx,
.party___EkAoG {
  -webkit-mask-image: url("/static/icons/music-note.svg");
  mask-image: url("/static/icons/music-note.svg");
}

.ocio___3awfS,
.leisure___3eCYy {
  -webkit-mask-image: url("/static/icons/beer.svg");
  mask-image: url("/static/icons/beer.svg");
}

.compras___2ehfH,
.shop___3eEcW {
  -webkit-mask-image: url("/static/icons/shop.svg");
  mask-image: url("/static/icons/shop.svg");
}

.experiencias_actividades___39rSM {
  -webkit-mask-image: url("/static/icons/tasks.svg");
  mask-image: url("/static/icons/tasks.svg");
}

.magnify___2eGTX {
  -webkit-mask-image: url("/static/icons/search.svg");
  mask-image: url("/static/icons/search.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #009DC5;
}

.iconmap___2Gz6R {
  -webkit-mask-image: url("/static/icons/map.svg");
  mask-image: url("/static/icons/map.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: #009DC5;
}

.iconmap___2Gz6R.active___3ofjA {
  background-color: white;
}

.filtersIcon___2nIil {
  -webkit-mask-image: url("/static/icons/adjustments.svg");
  mask-image: url("/static/icons/adjustments.svg");
  background-color: #009DC5;
}

.containerIconsButton___1ApjK {
  display: flex;
  justify-content: space-around;
  padding: 16px;
  display: none !important;
}

.iconButton___27QDK {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton___27QDK .icon___1BAKR {
  margin-right: 0;
}

.button___3oQMq {
  height: 32px;
  border-radius: 8px;
  border: solid 1px #009DC5;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  color: #009DC5;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

.button___3oQMq.active___3ofjA {
  background: #009DC5;
  color: #fff;
}

.button___3oQMq.action_visibility___3mqAl {
  background-color: white !important;
  font-size: 14px;
}

.filters___3-uxm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  height: auto;
  padding: 20px;
}

.filters___3-uxm > .container___la2DP {
  grid: none;
}

.filters___3-uxm > .container___la2DP > .container___la2DP {
  grid: 36px / repeat(5, 1fr);
  grid-gap: 20px 8px;
  align-items: flex-start;
  white-space: nowrap;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.filters___3-uxm > .container___la2DP > .container___la2DP::-webkit-scrollbar {
  height: 0 !important;
}

.filters___3-uxm > .container___la2DP > .container___la2DP .button___3oQMq {
  display: inline-flex;
  margin-left: 10px;
}

.filters___3-uxm > .container___la2DP > .container___la2DP > .button___3oQMq:last-child {
  margin-right: 10px;
}

.filters___3-uxm .title___3VE9f {
  display: none;
}

.filters___3-uxm .containerButton___1PpDg {
  display: none;
}

.subcategoriesContent___1RKkR {
  grid-column: span 12;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  flex-direction: row;
  justify-content: start;
  white-space: nowrap;
}

.subcategoriesContent___1RKkR > div {
  width: 100%;
}

.subcategoriesContent___1RKkR .item___7VrS0 {
  text-transform: lowercase;
}

.subcategoriesContent___1RKkR .hideContainer___1dYGr {
  position: absolute;
  left: 0;
  right: 0;
  top: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subcategoriesContent___1RKkR .menuItemWrapper___iYwxI:focus {
  outline: 0 !important;
}

.subcategoriesContent___1RKkR::-webkit-scrollbar {
  height: 0 !important;
}

.subcategoriesContent___1RKkR.item___7VrS0:first-child {
  margin-left: 8px;
}

.filtersContainer___1LCUP .multi-select {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container___la2DP {
    grid: 1fr / repeat(6, 1fr);
    grid-gap: 0 13px;
    max-width: 768px;
  }
  .subcategoriesContent___1RKkR {
    grid-column: span 6;
  }
  .categories___25lhu {
    grid-column: span 5;
    overflow-x: scroll;
  }
  .filtersCommands___1Lo9E {
    grid-column: span 1;
    justify-content: space-between;
  }
  .searchInput___2VaiW {
    margin: 30px 16px;
  }
  .results___3HeUy {
    display: none;
  }
  .action___3551T .label___3abV7 {
    display: none;
  }
  .search___2cIaj {
    flex: initial;
  }
  .search___2cIaj.selected___2Usmi button {
    background: #009DC5;
  }
  .filtersAction___1q_yD {
    flex: initial;
  }
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
*/

@media (min-width: 320px) and (max-width: 480px) {
  .navigation___1owAz {
    background-color: #ffffff;
  }
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
  .subcategoriesContent___1RKkR {
    grid-column: span 2;
  }
  .main___3LLaY {
    height: 70px;
  }
  .subcategories___YI6E0 {
    height: auto !important;
  }
  .container___la2DP {
    grid: 1fr / repeat(2, 1fr);
    grid-gap: 0 10px;
    max-width: 480px;
  }
  .categories___25lhu {
    grid-column: span 2;
    overflow-x: scroll;
  }
  .show_search_button_only___1N41j {
    grid-column: span 2;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .filtersCommands___1Lo9E {
    display: none;
    grid-column: span 1;
  }
  .arrowLeft___1WSrN,
  .arrowRight___3o7hC {
    width: 0 !important;
    display: none !important;
  }
  .main___3LLaY.searchMain___JHfcR{
    border-top: 0;
    flex-direction: column;
  }
  .searchInput___2VaiW {
    margin: 0;
    margin-bottom: 16px;
  }
  .mobileCommands___16hZ8 {
    display: block;
  }
  .filters___3-uxm {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 200;
    background-color: rgba(255, 255, 255, 0.95);
    height: auto !important;
    border-top: 0;
    z-index: 4000;
    padding: 8px;
  }
  .filters___3-uxm > .container___la2DP {
    grid: 100px 1fr / repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
  .filters___3-uxm > .container___la2DP > .container___la2DP {
    grid: none;
    display: grid !important;
    overflow-x: auto;
    grid-column: span 2;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 58px;
  }
  .filters___3-uxm > .container___la2DP > .container___la2DP > .button___3oQMq {
    display: flex;
    margin-right: 10px;
    padding: 0;
  }
  .filters___3-uxm > .container___la2DP > .container___la2DP > .button___3oQMq:first-child,
  .filters___3-uxm > .container___la2DP > .container___la2DP > .button___3oQMq:nth-child(2n + 3) {
    margin-left: 10px;
    margin-right: 0;
  }
  .title___3VE9f {
    display: flex !important;
    grid-column: span 2;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .title___3VE9f span {
    font-size: 22px;
    font-weight: bold;
  }
  .title___3VE9f a {
    color: #009DC5;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .containerButton___1PpDg {
    display: flex !important;
    grid-column: span 2;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

  .filtersContainer___1LCUP {
    grid-auto-rows: 25px !important;
  }

  .filtersContainer___1LCUP .multi-select {
    grid-column: span 2;
  }

  .filtersContainer___1LCUP .dropdown-heading {
    border: solid 1px #009DC5 !important;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03) !important;
    background-color: #ffffff !important;
    border-radius: 4px !important;
  }

  .filtersContainer___1LCUP .dropdown-heading-dropdown-arrow span {
    border-color: #009DC5 transparent transparent !important;
  }
}

@media only screen and (max-width: 991px) {
  .item___7VrS0 {
    border: 1px solid #009DC5;
    border-radius: 24px;
    margin-left: 16px;
  }
  .item___7VrS0 span {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
  }
  .icon___1BAKR {
    background-color: #009DC5;
  }
}

@media (min-width: 1060px) and (max-width: 1080px) {
    .container___la2DP {
        max-width: 1060px;
    }
}
