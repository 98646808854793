.contentPlan___2aCcN{
  margin: 20px auto 0;
}

.contentSubtitle___1GTVM{
  grid-column: span 12 / auto;
  margin: 0;
  padding: 0 20px;
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.contentItems___1m--S{
  grid-column: span 12 / auto;
  display: grid;
  grid: 1fr / repeat(5, 1fr);
  grid-gap: 20px;
  padding: 20px 20px 70px 20px;
}

.categoriaItem___5-Upi{
  height: 200px;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.categoriaItem___5-Upi::before{
  content: ' ';
  width: 100%;
  height: 100%;
  background-color: #00000066;
  display: block;
  border-radius: 8px;
}

.categoriaItem___5-Upi.selected___3g8Hp::before{
  opacity: 0.75;
  background-color: #009DC5;
}

.categoriaItem___5-Upi.selected___3g8Hp::after{
  content: '\2713';
  color: white;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 30px;
}

.name___2Jpju{
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89 !important;
  letter-spacing: -0.18px;
  color: #edeef4;
  position: absolute;
  bottom: 0;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
}

.contentFooter___2CdgK {
  grid-column: span 12;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.contentFooter___2CdgK button{
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 9px 8px;
  display: block;
  text-align: center;
  color: #FFF;
  border-radius: 32px;
  cursor: pointer;
  border: 0;
  min-width: 204px;
  display: inline;
}

@media (max-width: 1024px) and (min-width: 768px){
  .contentPlan___2aCcN {
    margin: 20px auto 50px;
  }

  .contentSubtitle___1GTVM{
    padding: 0 20px;
  }

  .contentItems___1m--S{
    grid: 1fr / repeat(3, 1fr);
    grid-gap: 20px;
    padding: 20px 20px 70px 20px;
  }
}

@media (max-width: 768px){
  .contentPlan___2aCcN {
    margin: 20px auto 0;
  }

  .contentSubtitle___1GTVM{
    padding: 0 16px;
  }

  .contentItems___1m--S{
    grid: 1fr / repeat(2, 1fr);
    grid-gap: 16px;
    padding: 16px 16px 70px 16px;
  }

  .categoriaItem___5-Upi{
    height: 200px;
  }

  .name___2Jpju {
    font-size: 20px;
  }

  .contentFooter___2CdgK{
    position: fixed;
    height: 48px;
    display: block;
    width: 100%;
    bottom: 64px;
    flex-direction: column;
    justify-content: center;
  }

  .contentFooter___2CdgK button{
    min-width: 50%;
    max-width: 50%;
    height: 48px;
    border-radius: 0;
    font-size: 14px;
  }

  .contentFooter___2CdgK button:first-child {
    filter: brightness(85%);
    position: absolute;
    left: 0;
  }

  .contentFooter___2CdgK button:last-child {
    position: absolute;
    right: 0;
  }
}
