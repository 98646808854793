.arrow___13KE3 {
    align-self: stretch;
    width: 16px;
    height: 16px;
    margin: 0 16px;
    background-size: cover;
    background-position: center;
}

.left___CqcC8 {
    background-image: url('/static/icons/chevron-back.svg')
}

.right___2Kf9E {
    background-image: url('/static/icons/chevron-forward.svg')
}

.scroll-menu-arrow___EeaRF {
    padding: 20px;
    cursor: pointer;
    display: none;
}
