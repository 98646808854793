.header___2SkEK {
  flex: 1;
  margin: 22px 0;
}

.header___2SkEK h2 {
  position: relative;
  padding: 8px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  display: inline-block;
}

.header___2SkEK.mainTitle___1wPlB h2 {
  font-size: 34px;
}

.header___2SkEK h2::after {
  content: " ";
  width: 60px;
  height: 4px;
  background-color: #009DC5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.header___2SkEK.mainTitle___1wPlB h2::after {
  width: 100px;
}

.header___2SkEK h3 {
  color: #2f2f2f;
  margin-top: 8px;
  padding-right: 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71 !important;
  letter-spacing: -0.08px;
}

.sectionContent___2yWpD {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column: 12 span;
  margin: 0 10px;
}

.sectionContent___2yWpD a {
  color: #009DC5;
  padding-right: 0;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67 !important;
  letter-spacing: normal;
  text-align: center;
  margin-right: 16px;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  border-radius: 100px;
  border: solid 1px #009DC5;
  align-self: center;
  height: 30px;
}

@media only screen and (max-width: 991px) {
  .body-search .header___2SkEK {
    display: none !important;
  }
  .header___2SkEK {
    margin: 0;
  }
  .header___2SkEK h2,
  .header___2SkEK h3 {
    text-align: left;
    display: block;
  }
  .sectionContent___2yWpD {
    flex-direction: row;
    margin-bottom: 20px;
    align-items: baseline;
  }
  .header___2SkEK h2 {
    font-size: 20px;
  }
  .header___2SkEK.mainTitle___1wPlB h2 {
    font-size: 25px;
  }

  .sectionContent___2yWpD a {
    margin-right: 0;
    align-self: unset;
    margin-bottom: 0;
    padding: 0 0 0 20px;
    border: 0;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 480px) {
  .header___2SkEK h3 {
    display: none;
  }
  .sectionContent___2yWpD > a {
    display: none;
  }
}
