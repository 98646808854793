.orderitem___1XLq6 {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
}

@media only screen and (max-width: 991px) {
  .orderitem___1XLq6 {
    margin: 16px 0;
  }
}
