.contentNotProducts___30qxx{
  width: 100%;
  box-sizing: border-box;
  padding: 10% 20%;
}

.contentNotProducts___30qxx label{
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #0f0f0f;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-align: center;
}

@media (max-width: 768px) {
  .contentNotProducts___30qxx label{
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .contentNotProducts___30qxx label{
    font-size: 20px;
  }
}
