.contentInput___43mdv{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.contentInput___43mdv label{
  flex: 1;
  align-self: center;
}

.select___cEHNa,
.input___3n4xU {
  flex: 2;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
}

.input___3n4xU{
  margin-left: 10px;
}

.input___3n4xU:disabled{
  border: none;
  box-shadow: none;
}

.select___cEHNa:focus {
  border-color: #009DC5
}

.input___3n4xU:focus {
  border-color: #009DC5
}

.button___1lSiu {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  max-width: 200px;
  margin: 21px auto 0;
  display: block;
}

.container___qkGKN {
  margin: 8px 69px;
  grid-column: 8 span;
  grid-column-start: 3;
  grid-column-end: 11;
}

.firstContent___IxH1s{
  margin-top: 0 !important;
}

.icons___3ovmx {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.icons___3ovmx::before {
  content: ' ';
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #009DC5;
  background-image: unset;
  mask-position: center;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.icons___3ovmx.logout___21TS2::before {
  -webkit-mask-image: url('/static/icons/logout.svg');
  mask-image: url('/static/icons/logout.svg');
}

.avatar___2SYoJ{
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: auto;
}

.inputChangePhoto___3mtas{
  display: none;
}

.linkChangePhoto___3kLto{
  text-decoration: none;
  text-align: center;
  display: block;
}

@media only screen and (max-width: 1024px) {
  .container___qkGKN{
    margin: 8px 69px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .container___qkGKN{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 768px) {
  .contentInput___43mdv{
      flex-direction: column;
  }

  .contentInput___43mdv label{
    align-self: flex-start;
  }

  .input___3n4xU{
    margin-left: 0;
  }

  .container___qkGKN {
    margin: 8px;
  }

}
