.container___1H5FF {
  margin: 50px 69px;
  grid-column: 8 span;
  grid-column-start: 3;
  grid-column-end: 11;
  padding-top: 80px;
}

.title___3fpmz {
  position: relative;
  padding: 8px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  display: inline-block;
  font-size: 34px;
}

.title___3fpmz::after{
  content: " ";
  width: 100px;
  height: 4px;
  background-color: #009DC5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.contentMessage___2fPfQ{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 2vw 0;
  font-size: 20px;
}

.separator___jESPp {
  height: 1px;
  width: 100%;
  background-color: #c0c4d2;
  margin: 36px 0;
}

.input___1AkDq {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px;
}

.input___1AkDq:focus {
  border-color: #009DC5
}

.input_error___18nLT {
  border-color: red;
}

.button___I_M2v {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

@media only screen and (max-width: 1024px) {
  .container___1H5FF{
    margin: 8px 69px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .container___1H5FF{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 767px) {
  .container___1H5FF{
    margin: 8px 8px;
  }
}
