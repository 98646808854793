.storeContainer___3xgfv {
  display: grid;
  grid: 1fr / repeat(5, 1fr);
  grid-gap: 0 8px;
  max-width: 1280px;
  flex: 1;
  margin: auto;
}

.contentNextPage___3CTNe{
  grid-column: 5 span;
  text-align: center;
}

.contentNextPage___3CTNe button{
  padding: 0 16px;
  border-radius: 100px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
  color: #009DC5;
  text-transform: uppercase;
  min-height: 32px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
}

@media (max-width: 1024px) {
  .storeContainer___3xgfv {
      grid: 1fr / repeat(3, 1fr);
      grid-gap: 0 13px;
      max-width: 768px;
  }
  .contentNextPage___3CTNe{
    grid-column: 3 span;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .storeContainer___3xgfv {
      grid: 1fr / repeat(2, 1fr);
      grid-gap: 0 13px;
      max-width: 768px;
  }
  .contentNextPage___3CTNe{
    grid-column: 2 span;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .storeContainer___3xgfv {
      grid: 1fr / repeat(1, 1fr);
      grid-gap: 0 10px;
      max-width: 480px;
  }
  .contentNextPage___3CTNe{
    grid-column: 1 span;
    text-align: center;
  }
}

@media (min-width: 1060px) and (max-width: 1080px) {
  .storeContainer___3xgfv {
      max-width: 1060px;
  }
}
