.borderButton___8QLAh {
  display: none;
}
@media only screen and (max-width: 991px) {
  .borderButton___8QLAh {
    height: 45px;
    background-color: white;
    border: 1px solid #009DC5;
    display: block;
    margin: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 6px;
  }

  .borderButton___8QLAh span {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }
}
