.container___1pfK1 {
    margin-top: 32px;
}

.bar___2iUq- {
    grid-column: span 4;
    padding-left: 49px;
    border-left: 1px solid #e4e5ec;
}

.content___38yrQ {
    grid-column: span 8;
}

.section___27dIT {
    border-bottom: 1px solid #e4e5ec;
    padding-bottom: 16px;
    padding-top: 16px;
}

.title___3neyu {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-bottom: 0;
}

.title___3neyu.toggle___3UrV8 {
    cursor: pointer;
}

.title___3neyu.toggle___3UrV8::after {
    content: ' ';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background-color: #333642;
    mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-image: url('/static/icons/chevron-down.svg');
    mask-image: url('/static/icons/chevron-down.svg');
}

.title___3neyu.toggle___3UrV8.toggle_opened____qwoX::after {
    -webkit-mask-image: url('/static/icons/chevron-up.svg');
    mask-image: url('/static/icons/chevron-up.svg');
}

.title___3neyu>span {
    display: flex;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #2f2f2f;
}

.bar___2iUq- .title___3neyu {
    padding: 10px 0;
}

.icon___3mDuS {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 3px;
    position: relative;
}

.calendar___2MWRH {
    background-image: url('/static/icons/calendar.svg');
}
.clock___FKI7H{
    background-image: url('/static/icons/reloj.svg');
}
.map___1TUYZ {
    background-image: url('/static/icons/map.svg')
}

.locate___NJYlK {
    background-image: url('/static/icons/locate.svg')
}

.location___1GbLV {
    background-image: url('/static/icons/location.svg')
}

.tasks___1VtqC {
    background-image: url('/static/icons/tasks.svg')
}

.location___1GbLV {
    background-image: url('/static/icons/location.svg')
}

.information___3CEgs {
    background-image: url('/static/icons/mundo.svg')
}

.forward___1aJcI {
    background-image: url('/static/icons/forward.svg')
}

.price___2gTTs {
    background-image: url('/static/icons/price.svg')
}

@media only screen and (max-width: 991px) {
    .container___1pfK1 {
        flex-direction: column;
    }
    .bar___2iUq- {
        padding-left: 0;
        border-left: 0;
        margin-top: 32px;
        width: auto;
        grid-column: span 6;
        grid-row: 2;
    }
    .content___38yrQ {
        grid-column: span 6;
        grid-row: 1;
    }
    .bar___2iUq-, .content___38yrQ {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .bar___2iUq- {
        grid-column: span 2;
        grid-row: 2;
    }
    .content___38yrQ {
        grid-column: span 2;
        grid-row: 1;
    }
}

@media (min-width: 1060px) and (max-width: 1080px) {
    .content___38yrQ {
        grid-column: span 7;
        max-width: 100%;
    }

    .bar___2iUq- {
        grid-column: span 5;
        max-width: 100%;
    }
}
