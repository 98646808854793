.row___40Ihl {
  display: flex;
  flex-direction: row;
  margin: 0 8px;
}

.contentName___JxDZ3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label___1AWJ2 {
  flex: 1;
  display: flex;
}

.date___1GhwD {
  color: #009DC5;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.price___1Axmf {
  font-weight: bold;
}

.products___3cUYk,
.discount___22-eH {
  border-bottom: 4px dotted rgba(0, 0, 0, 0.24);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.discount___22-eH {
  display: flex;
  flex-direction: row;
}

.image___auWT9 {
  width: 150px;
  height: 84.38px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
}

.recommended___2s_qT{
  margin-bottom: 16px;
}
