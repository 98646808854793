.link___d3kgf {
    text-decoration: none;
}

.container___1emz3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 16px;
}

.event___2ZkyA {
    flex: 1;
    display: flex;
}

.picture___r4Wjs {
    width: 100%;
    height: 200px;
    border-radius: 6px;
    background-color: #d8d8d8;
    background-position: center;
    background-size: cover;
}

.informations___jQ2fx {}

.title___3QPMn {
    display: inline-block;
    font-size: 32px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    padding: 24px 0;
    letter-spacing: -0.2px;
    color: #2f2f2f;
    position: relative;
    text-decoration: none;
    line-height: 34px!important;
}

.title___3QPMn::after {
    position: absolute;
    content: ' ';
    width: 80px;
    height: 6px;
    bottom: 0;
    left: 0;
    background-color: #009DC5;
}

.description___1uqOf {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.1px;
    text-align: justify;
    color: #2f2f2f;
    margin-top: 16px;
}

.schedule___1gB7H {
    font-size: 14px;
}

.commands___2BzB7 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
}

.button_container___2XkBQ {
    flex: 1;
}

.goto___3fYnR {
    padding: 0 16px;
    border-radius: 100px;
    border: solid 1px #009DC5;
    background-color: #ffffff;
    color: #009DC5;
    text-transform: uppercase;
    min-height: 32px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.29;
    letter-spacing: normal;
    text-align: center;
}

/*
.goto span {
    font-size: 12px;
    line-height: 2.67;
    text-transform: uppercase;
    text-align: center;
    color: var(--primary-color);
}
*/

.commands___2BzB7 a {
    width: 40%;
    height: 22px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: -0.09px;
    text-align: right;
}

.titleWithHeart___1wEVk {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.titleWithHeart___1wEVk>span {
    flex: 1;
}

.heart___3wCKx {
    width: 34px;
    height: 34px;
    border-radius: 100px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-left: 16px;
    background-image: url('/static/images/love.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
}

.accent___39EZQ {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNjNDMyYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjYzQzMmJlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMS41IDIwLjVzLTktNS05LTEyYzAtMiAxLTUgNC01czUgMi4yNSA1IDIuMjUgMi0yLjI1IDUtMi4yNSA0IDMgNCA1YzAgNy05IDEyLTkgMTJ6Ii8+Cjwvc3ZnPgo=');
    border-color: transparent
}

.event___2ZkyA a {
    color: #009DC5;
}

/*  Tables & Phones  */

@media only screen and (max-width: 991px) {
    .container___1emz3 {
        margin: 16px 8px;
    }
    .commands___2BzB7 {
        /*flex-direction: column;*/
    }
    .goto___3fYnR {
        padding: 0 8px;
        font-size: 12px;
        line-height: 2.67;
        max-width: 188px;
        width: 100%;
    }
    .commands___2BzB7 a {
        text-align: right;
    }
}
