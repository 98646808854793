.input___juL1K {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px;
}

.input___juL1K:focus {
  border-color: #009DC5
}

.input_error___1NW4Y {
  border-color: red;
}

.button___1daGD {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
  max-width: 200px;
}

.container___3fMUQ {
  margin: 50px 69px;
  grid-column: 4 span;
  grid-column-start: 5;
  grid-column-end: 9;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .container___3fMUQ{
    margin: 8px 69px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .container___3fMUQ{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 767px) {
  .container___3fMUQ{
    margin: 8px 8px;
  }
}
