.navbar___2406s {
  z-index: 400 !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(228, 228, 228) !important;
  height: 81px !important;
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px !important;
  display: flex;
  box-sizing: border-box;
}

.brandContainer___SWyZh {
  flex: 1;
  display: flex;
  align-items: center;
}

.brand___i3dE2 {
  display: flex;
  width: 196px;
  height: 35px;
  text-indent: -6000px;
  text-decoration: none;
  margin-left: 10px;
}

.brand___i3dE2.discoolver___qYoFK{
  background: url("/static/images/discoolver/discoover-brand.svg");
}
.brand___i3dE2.ronda___1mopl{
  background: url("/static/images/ronda/discoover-brand.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.menu___3JrCs {
  display: flex;
  align-items: center;
  grid-column: span 11;
  justify-content: flex-end;
}

.menu___3JrCs .item___267eK {
  display: table-cell !important;
  text-transform: capitalize;
  margin: 0 16px;
}

.menu___3JrCs .item___267eK>a {
  display: inline-block !important;
  vertical-align: middle !important;
  line-height: 1 !important;
  padding: 8px !important;
  text-decoration: none;
  color: #333642;
}

.menu___3JrCs .accent___2Mu2i>a {
  display: inline-block !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  line-height: 1 !important;
  padding: 8px 16px !important;
  text-decoration: none;
  color: #009DC5;
  border-radius: 4px;
  border: 1px solid #009DC5;
  cursor: pointer;
}

.dropdown___2PNRa {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 16px;
}

.options___27Ilm {
  display: none;
  position: absolute;
  background: #FFF;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  border-radius: 2px;
  flex-direction: column;
}

.dropdown_open___2LrJW .options___27Ilm {
  display: flex !important;
  z-index: 1;
}

.dropdownitem___H_ltO {
  width: 100px;
  color: #333642;
  text-decoration: none;
  padding: 8px;
}

.chevron___27dIQ {
  width: 20px;
  height: 20px;
  object-fit: contain;
  border: 0;
  margin-left: 8px;
  background: url('/static/images/chevron-bottom.svg');
}

.mobilemenu___3YtQ1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.mobilemenu___3YtQ1>button {
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 16px;
}

.mobilemenu___3YtQ1>button>span {
  height: 3px;
  width: 26px;
  background-color: #333642;
  display: table-cell;
  border-radius: 6px;
  margin: 3px 0;
}

.inputContainer___37i9H {
  margin: 0 48px;
  display: flex;
  flex: 1;
}

.inputContainer___37i9H input {
  width: 100%;
  height: 32px;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

form {
  display: flex;
  position: relative;
}

.action_button___2oFKN {
  background-color: transparent;
  border: 0;
}

.search___3Fu8i {
  background-image: url('/static/icons/search.svg');
  background-position: center;
  background-size: 54%;
  text-indent: -6000px;
  width: 40px;
  background-repeat: no-repeat;
  margin: 0;
  opacity: .4;
  border: 1px solid #CCC;
  border-radius: 20px;
  padding: 8px;
}

.dialogCommand___5QhLw {
  padding: 22px 0;
  margin: 0 10px;
}

.close___3_bIH {
  background-image: url('/static/icons/close.svg')!important;
  background-position: center;
  background-size: contain;
  text-indent: -6000px;
  width: 24px;
  background-repeat: no-repeat;
  margin: 0 16px;
}

.mobileMenu___2OEln {
  display: none;
  text-indent: -6000px;
}

.input___1Q3KY {
  border: 1px solid #000 !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  height: 48px !important;
  padding: 0 16px;
}

.mobileCommands___3Nwe9, .categoriesContainer___1BbLd {
  display: none;
}



@media (min-width: 768px) and (max-width: 1024px) {
  .menu___3JrCs {
    grid-column: span 4;
  }
  .menu___3JrCs .item___267eK {
    margin: 0 8px;
  }
  .brandContainer___SWyZh {
    grid-column: span 2;
    padding-left: 8px;
  }
  .brand___i3dE2 {
    margin-left: 0;
  }
}

@media (min-width: 100px) and (max-width: 480px) {
  .brandContainer___SWyZh {
    grid-column: span 2;
    position: absolute;
    left: 0;
    display: flex;
    justify-items: right;
    margin-left: 0;
    z-index: 100;
    height: 35px;
    justify-content: center;
    width: 100%;
  }
  .brand___i3dE2 {
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    margin-bottom: 0;
    margin-left: 0;
  }
  .whitFilter___3WSE2{
    width: calc(100% - 32px);
  }
  .dropdown___2PNRa {
    display: none;
  }
  .languageMobile___3mGyr{
    display: inline-block;
    float: right;
  }
  .languageMobile___3mGyr .dropdown___2PNRa {
    display: flex !important;
  }
  .mobileMenu___2OEln {
    display: initial;
    grid-column: span 1;
  }
  .toggleButton___ZKZdp {
    display: flex;
    width: 32px;
    height: 32px;
    background-image: url('/static/icons/hamburger-menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    text-indent: -6000px;
    border: 0;
    margin-left: 8px;
  }
  .mobileCommands___3Nwe9 .toggleButton___ZKZdp {
    margin-left: 4px;
  }
  .menu___3JrCs {
    display: none;
  }
  .mobileCommands___3Nwe9 {
    grid-column: span 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .filtersButton___x3RUR {
    background-image: url('/static/icons/close.svg');
  }
  .filtersButton___x3RUR {
    background-image: url('/static/icons/close.svg');
  }
  .filtersIcon___344DB {
    background-image: url('/static/icons/adjustments.svg');
  }
  /*Button Map*/
  .mapIcon___19PVp {
    background-image: url('/static/icons/map.svg');
  }
  .mapButton___1t7_H {
    background-image: url('/static/icons/map.svg');
  }
  .closeButton___2qCGk {
    padding-left: 0!important;
    margin-left: 0!important;
    margin-bottom: 8px;
    display: inline-block;
  }
  .searchButton___mb_Hu {
    margin-right: 8px;
    background-image: url('/static/icons/search.svg');
  }
  .searchButton___mb_Hu.searchOpen___11r8S {
    background-image: url('/static/icons/close.svg');
    background-size: 70%!important;
  }
  .toggleButton___ZKZdp, .filtersButton___x3RUR, .searchButton___mb_Hu, .mapsButton___3nh8K{
    background-size: 90%;
    pointer-events: visible;
  }
  .mobileMenu___2OEln, .mobileCommands___3Nwe9 {
    position: relative;
    z-index: 200;
    pointer-events: none
  }
  .mobileMenu___2OEln span {
    display: none;
  }
  .categoriesContainer___1BbLd {
    display: initial;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent;
    height: auto!important;
    z-index: 5000;
  }
  .categoriesContent___2kPjM {
    background-color: #FFF;
    position: absolute;
    width: 100% !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }
  .categoriesMenu___1_Jod {
    flex: 1;
    overflow-y: scroll;
  }
  .item___267eK {
    border-bottom: 1px solid #d8d8d8;
    margin: 0 10px;
  }
  .item___267eK button {
    width: 100%;
    text-align: left;
    min-height: 40px;
    vertical-align: center;
    background-image: url('/static/icons/chevron-bottom.svg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 18px;
    background-color: transparent;
    border: 0;
  }
  .item___267eK.item_selected___2Ls_2 button {
    background-image: url('/static/icons/chevron-top.svg');
  }
  .item___267eK button, .subitem___1nNBk a {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    color: #333642;
    letter-spacing: normal;
  }
  .item___267eK button>span {
    display: flex;
    align-items: center;
  }
  .subitem___1nNBk a {
    display: block;
    padding-left: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
  }
  .icon___2bx5O {
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-right: 8px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .wetravelhum___1S_3i {
    -webkit-mask-image: url("/static/icons/airplane.svg");
    mask-image: url("/static/icons/airplane.svg");
  }
  .foryou___304LU, .para_ti___cmvlw {
    background-image: url('/static/icons/profile.svg');
  }
  .alojamiento___6bCbL, .accommodations___2P1Ls {
    background-image: url('/static/icons/office.svg');
  }
  .restaurante___KcXmG, .restaurantes___3NpdT, .restaurants___19XF2 {
    background-image: url('/static/icons/food.svg');
  }
  .que_ver___5LKoH, .to_see___18_OV {
    background-image: url('/static/icons/picture.svg');
  }
  .fiesta___1rp4O, .party___382fi {
    background-image: url('/static/icons/music-note.svg');
  }
  .ocio___3en8d, .leisure___371rF {
    background-image: url('/static/icons/beer.svg');
  }
  .compras___H3OKl, .shop___1TD7v {
    background-image: url('/static/icons/shop.svg');
  }
  .experiencias_actividades___1f8R6{
    background-image: url('/static/icons/tasks.svg')
  }
}
