.pin___3XrlU {
  background-image: url("/static/images/discoolver-map-pin-normal.png");
  width: 24px;
  height: 36px;
}

.selected___3AGgA.pin___3XrlU {
  background-image: url("/static/images/discoolver-map-pin.png");
  transform: scale(1.3);
  transition: 0.3s linear;
  z-index: 1;
  position: sticky;
}

.contentMap___3VObj {
  position: relative;
  width: 100%;
  height: 100%;
}

.contentButton___j4zUp {
  display: none;
  position: absolute;
  bottom: 85px !important;
  left: 0;
  z-index: 1;
  right: 0;
  margin: auto;
  max-width: max-content;
}

.contentSeeMap___3QzFT {
  display: none;
  margin: auto;
  max-width: max-content;
  margin-bottom: 24px;
}

.buttonConfig___2VuOm {
  background-color: #009DC5;
  color: white;
  padding: 8px 16px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  justify-content: center;
}

.buttonConfig___2VuOm span {
  background-color: white;
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-left: 8px;
  mask-position: center;
  -webkit-mask-position: center;
}

.buttonConfig___2VuOm.iconFilter___2dT1X span {
  -webkit-mask-image: url(/static/icons/adjustments.svg);
  mask-image: url(/static/icons/adjustments.svg);
}

.buttonConfig___2VuOm.iconMap___3CyNp span {
  -webkit-mask-image: url(/static/icons/map.svg);
  mask-image: url(/static/icons/map.svg);
}

@media only screen and (max-width: 991px) {
  .contentButton___j4zUp,
  .contentSeeMap___3QzFT {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 64px;
  }
}

-webkit-mask-image: url(/static/icons/map.svg);
mask-image: url(/static/icons/map.svg);
-webkit-mask-size: contain;
mask-size: contain;
background-color: #c432be;
