.ajustesCaja___3nnIc {
  padding-right: 0px;
  padding-left: 0px;
}
.searchTextNow___2Kxlf {
  width: 341px;
  height: 19px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
}
.hint___3hyVL {
  width: 43px;
  height: 24px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #009DC5 !important;
}
.buttonToggle___2YDpq {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
}

.espacioEntreButton___3QN47 {
  margin-top: 10px;
}

.separacionLinea___19lM- {
  margin-top: 19.5px;
  border: solid 4px 0 8px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 19.5px;
}

.botonSearchNoAuth___PlHIQ {
  width: 100%;
  height: 45px !important;
  border-radius: 6px;
  border: solid 1px #009DC5;
  background-color: #009DC5;
  margin-top: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.botonSharedApp___lvmp2 {
  width: 100%;
  height: 45px !important;
  border-radius: 6px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
  margin-top: 20px;
  color: #009DC5;
  text-transform: uppercase;
}

.textSearch___2posD {
  width: 49px;
  height: 32px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff !important;

}

.textSharedApp___1Ips_ {
  text-decoration: none;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 2.29;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 2.29;
  padding: 8px 16px;
  display: inline-flex;
  text-align: center !important;
  text-transform: uppercase;
  color: #009DC5;
}

.heroContainer___fIOyq {
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 90px 0;
}

.search___21QtY,
.relationships___1bpKS,
.rangeDates___1QI0l,
.perfectFor___1gZ0P,
.goto___G3hDh {
  grid-column: span 2;
  grid-row: 2;
  position: relative;
}

.search___21QtY,
.relationships___1bpKS,
.rangeDates___1QI0l,
.perfectFor___1gZ0P {
  height: 44px;
  display: flex;
}

.destinations___lPznV {
  grid-column: span 2;
  grid-column-start: 2;
  grid-column-end: 4;
}

.rangeDates___1QI0l {
  grid-column: span 3;
  justify-content: space-evenly;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
}

.DateInputFocused___20vsS {
  border-color: #009DC5 !important;
}

.rangePicker___1E4il {
  display: flex;
  border: none;
  background: none;
}

.rangePicker___1E4il div {
  display: flex;
}

.perfectFor___1gZ0P {
  margin-right: 0;
}

.container___1zcdN {
  margin: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.calendar___2e-nT .container___1zcdN {
  max-width: 517px;
  color: #fff;
}

.container___1zcdN > span {
  font-size: 16px;
  font-weight: 300;
  color: #edeef4;
  display: flex;
  text-align: center;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.badge___3QDfg {
  display: flex;
  font-size: 16px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: solid 1px #009DC5;
  background-color: #009DC5;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
}

.button___3WMG5 {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: relative;
  bottom: -56px;
  cursor: pointer;
}

.calendar___2e-nT {
  max-width: 620px;
  box-shadow: 0 2px 6px 0 rgba(69, 73, 91, 0.08);
  background-color: rgba(51, 54, 66, 0.9);
  border-radius: 6px;
  display: flex;
  grid-column: 12 span;
  margin: 91px 10px;
}

.calendar___2e-nT .destinations___lPznV,
.calendar___2e-nT .rangeDates___1QI0l,
.calendar___2e-nT .perfectFor___1gZ0P {
  margin-bottom: 10px;
}

.dates___1gc2h {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dates___1gc2h > span {
  /* color: #edeef4; */
  display: block;
  text-align: center;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 45px !important;
}

.dates___1gc2h > .day___1oe8A {
  font-size: 64px;
}

.details___2i7H0 {
  grid-column: 12 span;
  text-align: center;
  padding: 32px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
  margin: 41px 10px;
}

.details___2i7H0 .button___3WMG5 {
  display: inline-block;
  align-self: center;
  padding: 16px 32px;
}

.toggleContainer___dA2kI {
  flex: 1;
  position: relative;
  display: flex;
  border: solid 1px #009DC5;
  color: #009DC5 !important;
  border-radius: 4px;
}

.toggleButton___2kCIT {
  flex: 1;
  background-color: #fff;
  border: 0;
  text-align: left;
  margin-right: 0;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border-color: #009DC5 1px !important;
  color: #009DC5 !important;
}

.toggleButton___2kCIT:focus {
  border: 1px solid #009DC5;
}

.toggleButton___2kCIT::after {
  content: "";
  -webkit-mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  -webkit-mask-size: 11px 11px;
  mask-size: cover;
  display: inline-flex;
  background-color: #009DC5 !important;
  width: 11px !important;
  height: 5px !important;
  border: solid 1px #009DC5;
  position: absolute;
  right: 4px;
  top: 17px;
}

.toggleButton___2kCIT span {
  font-size: 13px;
  border-color: #009DC5 !important;
  color: #009DC5 !important;
}

.show_options___OLJMi {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  background-color: #fff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #009DC5 !important;
  z-index: 2000;
}

.destinationItem___3PsQW {
  background: #fff;
  border: 0;
  text-align: left;
  padding: 8px 12px;
  display: block;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.select___3DMPu {
  display: block;
  margin: 8px 16px;
}

.destinationItem___3PsQW:hover {
  background: #eaeaea;
}

.destinationItem___3PsQW > span {
  display: block;
}

.toggleContainer___dA2kI > .show_options___OLJMi.places___CfxQx > div:first-child {
  margin-top: 16px;
}

.destinationTitle___3uzRj {
  margin: 0 8px;
  display: flex;
}

.CalendarDay__selected_span___3kXGT {
  background: #82e0aa;
  color: white;
  border: 1px solid rosybrown;
}

.CalendarDay__selected___8nUnP {
  background: red;
  color: white;
}

.CalendarDay__selected___8nUnP:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span___1fQoL:hover,
.CalendarDay__hovered_span___1fQoL {
  background: brown;
}

.inputsRow___2GK5A {
  display: flex;
  position: relative;
}

.inputsRow___2GK5A .columnOne___29LJH {
  width: 100%;
  display: flex;
}

.inputsRow___2GK5A .columnTwo___wg2Hm {
  width: 100%;
  display: flex;
}

.separator___ADU8E {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.toLeft___pFCYu {
  justify-content: flex-start;
}

.contentUser___1YdzF{
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: row;
  padding-top: 8px;
}

.avatar___u6CHA {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.06);
  align-self: center;
}

.header___3QdA8 {
  width: calc(100% - 88px);
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header___3QdA8 label,
.header___3QdA8 label span,
.header___3QdA8 b,
.icons___1z_Mu.favorites___PteSO span{
  text-align: left;
  display: inline-block;
  font-family: Montserrat;
  font-size: 12px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  color: #2f2f2f;
}

.header___3QdA8 label {
  display: flex;
  justify-content: space-between;
}

.header___3QdA8 label span{
  text-align: right;
  color: #333642;
  font-weight: normal;
}

.icons___1z_Mu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  border: solid 1px var(#333642);
}

.icons___1z_Mu::before {
  content: " ";
  width: 24px;
  height: 24px;
  margin-right: 0;
}


.icons___1z_Mu.favorites___PteSO span {
  color: #009DC5;
}

.icons___1z_Mu.favorites___PteSO::before {
  -webkit-mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNjNDMyYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjYzQzMmJlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMS41IDIwLjVzLTktNS05LTEyYzAtMiAxLTUgNC01czUgMi4yNSA1IDIuMjUgMi0yLjI1IDUtMi4yNSA0IDMgNCA1YzAgNy05IDEyLTkgMTJ6Ii8+Cjwvc3ZnPgo=');
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNjNDMyYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjYzQzMmJlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMS41IDIwLjVzLTktNS05LTEyYzAtMiAxLTUgNC01czUgMi4yNSA1IDIuMjUgMi0yLjI1IDUtMi4yNSA0IDMgNCA1YzAgNy05IDEyLTkgMTJ6Ii8+Cjwvc3ZnPgo=');
  background: #009DC5;
}

.DateInput_input {
  padding: 10px 0 10px 6px;
}
.DateInput {
  border-radius: 4px;
}

.contentFooterLink___1RfXB{
  display: flex;
  flex-direction: column;
  margin: 24px 0;
}

.contentFooterLink___1RfXB .item___s8jJu{
  height: 19px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
  border-top: 1px solid #d8d8d8;
  position: relative;
  padding: 10px 0;
  text-decoration: none;
}

.contentFooterLink___1RfXB .item___s8jJu::after{
  content: '';
  -webkit-mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  -webkit-mask-size: 25px 25px;
  mask-size: cover;
  display: inline-flex;
  background-color: #009DC5;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: rotate(-90deg);
}

@media only screen and (max-width: 1440px) {
  .toggleButton___2kCIT span {
    font-size: 0.9vw;
  }
}

@media (max-width: 1024px) {
  .hero___EHSdY h1,
  .hero___EHSdY p {
    font-size: calc(1em + 2vw);
  }
  .day___1oe8A {
    line-height: 1em !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .toggleButton___2kCIT span {
    font-size: 16px;
  }
  .heroContainer___fIOyq .titles___9ZuDd,
  .inputsRow___2GK5A {
    grid-column: span 5;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .search___21QtY,
  .relationships___1bpKS {
    grid-column: span 2;
    grid-row: 2;
  }
  .rangeDates___1QI0l {
    grid-column: span 4;
    grid-row: 3;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .perfectFor___1gZ0P {
    grid-column: span 4;
    grid-row: 4;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .destinations___lPznV {
    grid-column: span 2;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .goto___G3hDh {
    grid-column: span 4;
    grid-row: 5;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .calendar___2e-nT {
    grid-column: span 4;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .calendar___2e-nT .container___1zcdN {
    max-width: initial;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .calendar___2e-nT {
    grid-column: 2 span;
    margin: 0px 10px;
  }
  .toggleButton___2kCIT span {
    font-size: 13px;
  }

  .search___21QtY {
    grid-column: span 2;
    grid-row: 2;
  }
  .relationships___1bpKS {
    grid-column: span 2;
    grid-row: 4;
  }
  .rangeDates___1QI0l {
    margin-top: 10px;
    grid-column: span 2;
    width: 100%;
    border-color: #009DC5 !important;
    color: #009DC5 !important;
    grid-row: 3;
  }
  .perfectFor___1gZ0P {
    grid-column: span 2;
    grid-row: 5;
    margin-top: 10px;
    border-color: #009DC5 !important;
    color: #009DC5 !important;
  }
  .destinations___lPznV {
    grid-column: span 2;
  }
  .goto___G3hDh {
    grid-column: span 2;
    grid-row: 6;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
}
