.checkoutContent___-D6nm{
  background-color: rgba(237, 238, 244, 0.35);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 44px 57px;
  box-sizing: border-box;
  position: relative;
}

.checkoutContentProducts___bCfkW{
  width: 65%;
  padding: 0 50px 0 0;
  box-sizing: border-box;
}

.checkoutContentProductsDetail___2Vr9w{
  padding: 12px 0;
  position: relative;
  display: flex;
  flex-direction: row;
}

.checkoutContentProductsDetailImage___2Pvt7{
  width: 50%;
  margin-right: 15px;
}

.checkoutContentProductsDetailImage___2Pvt7 img{
  width: 100%;
  height: auto;
}

.checkoutContentProductsDetailData___1t6Jh{
  width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.checkoutContentProductsRow___1rYrD{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailDataTitle___2b4HX{
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: #333642;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailName___2sfyJ{
  height: auto;
  min-height: 30px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  margin-bottom: 24px;
  padding-left: 33px;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailName___2sfyJ:before{
  content: '';
  background-image: url('/static/images/invoice.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailPrice___3uTdS{
  background-color: #009DC5;
  height: 22px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6 !important;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 68px;
  margin-top: 5px;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailKey___3mUYD{
  padding-left: 33px;
}

.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailKey___3mUYD,
.checkoutContentProductsDetail___2Vr9w .checkoutContentProductsDetailValue___2Yz2x {
  height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38 !important;
  letter-spacing: -0.1px;
  color: #6b6f82;
  margin-bottom: 5px;
}

.checkoutContentProductsLine___3EniG{
  width: 2px;
  height: calc(100% - 114px);
  background-color: #e4e5ec;
  position: absolute;
  left: calc(65% - 18px);
  top: 57px;
}

.checkoutContentButtons___2vSn4{
  width: 35%;
  padding: 0 0 0 50px;
  box-sizing: border-box;
}

.button___3oAi5 {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.contentDelete___8myWg{
  position: absolute;
  right: 0;
  top: 0;
}

.contentDelete___8myWg button{
  width: 28px;
  height: 28px;
  border: 0;
  -webkit-mask-image: url(/static/images/trash_icon.svg);
  mask-image: url(/static/images/trash_icon.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #c432be;
}

@media only screen and (max-width: 1024px) {
  .checkoutContent___-D6nm{
    padding: 22px 30px;
  }

  .checkoutContentProducts___bCfkW{
    width: 60%;
    padding: 0 20px;
  }

  .checkoutContentProductsDetail___2Vr9w{
    flex-direction: column;
  }

  .checkoutContentProductsDetailImage___2Pvt7{
    width: 100%;
  }

  .checkoutContentProductsDetailData___1t6Jh{
    width: 100%;
  }

  .checkoutContentProductsLine___3EniG{
    left: calc(60% - 6px);
  }

  .checkoutContentButtons___2vSn4{
    width: 40%;
    padding: 0 20px;
  }

  .checkoutContentFormRow___174fW{
    flex-direction: column;
  }

}

@media only screen and (max-width: 767px) {
  .checkoutContent___-D6nm{
    flex-direction: column;
    padding: 12px;
  }
  .checkoutContentProducts___bCfkW,
  .checkoutContentButtons___2vSn4{
    width: 100%;
    padding: 0 12px;
  }

  .checkoutContentProductsLine___3EniG{
    width: calc(100% - 66px);
    height: 2px;
    top: auto;
    bottom: 75px;
    left: 33px;
  }

  .checkoutContentButtons___2vSn4{
    margin-top: 20px;
  }

}
