.subtitle___3JvwF{
  height: 38px;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19 !important;
  letter-spacing: -0.18px;
  color: #2f2f2f;
  display: inline;
}

@media (max-width: 768px){
  .subtitle___3JvwF {
    font-size: 22px;
  }
}

@media (max-width: 468px){
  .subtitle___3JvwF {
    font-size: 16px;
  }
}
