#modal_container___GQFaX {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: fill;
}

.brand___1Dw-R {
  display: flex;
  width: 100%;
  height: 35px;
  text-indent: -6000px;
  text-decoration: none;
  margin: 50px 0;
}

.brand___1Dw-R.discoolver___1rP1T {
  background: url("/static/images/discoolver/discoover-brand.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.brand___1Dw-R.ronda___TzbWA {
  background: url("/static/images/ronda/discoover-brand.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.content___3OVDf {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 0 16px;
  pointer-events: fill;
}

.input___Xck9b {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px;
}

.input___Xck9b:focus {
  border-color: #009DC5;
}

.button___1Scv2 {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #ffffff;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.container___2rlP8 {
  margin: 50px 69px;
}

.separator___rgflQ {
  height: 1px;
  width: 100%;
  background-color: #c0c4d2;
  margin: 36px 0;
}

.other_options___3zd5T {
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: block;
  color: #333642;
}

.close___3R_PN {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  background: url("/static/images/close.svg");
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -6000px;
}

@media only screen and (max-width: 991px) {
  #modal_container___GQFaX {
    align-items: flex-end !important;
  }
  .content___3OVDf {
    max-width: auto !important;
    margin: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 16px;
    padding-bottom: 32px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .container___2rlP8 {
    margin: 10px 8px;
  }
}
