.text___2mj_s,
.text___2mj_s p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  text-align: justify;
  color: #2f2f2f;
}

.tab___2GLtz,
.tabColumn___1FBYP {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #2f2f2f;
  padding: 0 0 8px;
}

.tabColumn___1FBYP {
  flex-direction: column;
}

.galleryContainer___28s1u {
  margin: 32px 0;
}
.gallery___Jcsc5 {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.seemap___1Ul1b {
  grid-column: span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.seemap___1Ul1b .button___3rLE6 {
  padding: 8px 16px;
  width: 100%;
  background: white;
  border: 1px solid #009DC5;
  color: #009DC5;
}

.gallery___Jcsc5::-webkit-scrollbar {
  height: 6px;
  background-color: #e4e5ec;
}

.gallery___Jcsc5::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #009DC5;
}

.mainItem___3tqfa {
  width: 100%;
  height: 400px;
  padding: 0;
  margin: 0;
  display: block;
  background-position: center;
  background-size: cover;
}

.gallery___Jcsc5 a,
.galleryItem___2G1nx {
  width: 200px;
  height: 200px;
  display: inline-block;
}

.galleryItem___2G1nx {
  background-position: center;
  background-size: cover;
}

.galleryItem___2G1nx:hover {
  opacity: 0.6;
}

.tabName___2tTsR {
  flex: 1;
  margin-right: 16px;
  color: #6b6f82;
}

.tabName___2tTsR a {
  color: #6b6f82;
  text-decoration: none;
}

.tabName___2tTsR.access_title___2gcHq {
  text-transform: capitalize;
  font-weight: 500;
  color: black;
}

.table___uvY10 {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 16px;
}

.row___3rVJw {
  display: flex;
  flex: 1;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  padding: 16px 0;
}

.table___uvY10 .row___3rVJw > div:first-child {
  flex: 1;
  border-right: 1px dotted rgba(0, 0, 0, 0.12);
  margin-right: 16px;
}
.table___uvY10 .row___3rVJw > div:last-child {
  flex: 2;
}

.categories___1SUZF {
  display: flex;
  flex-wrap: wrap;
}
.category___3SVDn {
  background-color: #009DC5;
  padding: 4px 16px;
  color: #fff;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8 px;
  font-size: 14px;
}

.shareIcon___2rmPy {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #009DC5;
  margin-right: 16px;
  text-indent: -6000px;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.twitter___2FIhF {
  background-image: url("/static/icons/twitter.svg");
}

.facebook___2LHOO {
  background-image: url("/static/icons/facebook.svg");
}

.whatsapp____tZAA {
  background-image: url("/static/icons/whatsapp.svg");
}

.instagram___E66j0 {
  background-image: url("/static/icons/instagram.svg");
}

.youtube___2a68g {
  background-image: url("/static/icons/youtube.svg");
}

.socialIcon___35ybT {
  width: 22px;
  height: 22px;
  margin-top: 8px;
  margin-right: 8px;
  text-indent: -6000px;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  position: relative;
}

.socialIcon___35ybT::after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  background: #009DC5;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
}

.socialIcon___35ybT.twitter___2FIhF::after {
  -webkit-mask-image: url("/static/icons/twitter.svg");
  mask-image: url("/static/icons/twitter.svg");
}

.socialIcon___35ybT.facebook___2LHOO::after {
  -webkit-mask-image: url("/static/icons/facebook.svg");
  mask-image: url("/static/icons/facebook.svg");
}

.socialIcon___35ybT.whatsapp____tZAA::after {
  -webkit-mask-image: url("/static/icons/whatsapp.svg");
  mask-image: url("/static/icons/whatsapp.svg");
}

.socialIcon___35ybT.instagram___E66j0::after {
  -webkit-mask-image: url("/static/icons/instagram.svg");
  mask-image: url("/static/icons/instagram.svg");
}

.socialIcon___35ybT.youtube___2a68g::after {
  -webkit-mask-image: url("/static/icons/youtube.svg");
  mask-image: url("/static/icons/youtube.svg");
}

.button___3rLE6 {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  cursor: pointer;
}

.buttonAlt___M49ue {
  background-color: #fff;
  border: 1px solid #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #009DC5;
  border-radius: 32px;
}

.tag___23y2q {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: center;
  color: #009DC5;
  border: 1px solid #009DC5;
  margin-right: 16px;
  padding: 4px 16px;
  margin-bottom: 16px;
  display: inline-block;
}

.adultsOnly___3eixE {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
  z-index: 2;
}

.adultsOnlyBtnMore18___3S5Xc {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: absolute;
  bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 55px;
  min-width: 200px;
}

.adultsOnlyBtnExit___2m13d {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: absolute;
  bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 55px;
  min-width: 200px;
}

.adultsOnlyBtnMore18___3S5Xc {
  bottom: 70px;
}

.adultsOnlyBtnExit___2m13d {
  top: 70px;
}

.gallery-viewer {
  overflow: hidden;
}

.modalGallery___1tLaS {
  background: #fffd;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3000;
  display: flex;
  flex-direction: column;
}

.navbar___1ME8U {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.icon___3rHxc {
  background-color: transparent;
  border: 0;
  font-family: Montserrat;
  background-repeat: no-repeat;
  background-position: center;
}

.close___3L0bm {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-image: url("/static/icons/close.svg");
  margin: 16px;
}

.close___3L0bm:focus {
  outline: none;
  border: 1px solid rgba(196, 50, 190, 0.6);
  -webkit-box-shadow: 0px 0px 5px #009DC5;
  box-shadow: 0px 0px 5px #009DC5;
}

.modalContent___1bGVX {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  position: relative;
}

.imageContainer___flBLu {
  flex: 1;
  grid-column: 12 span;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbContainer___caRpF {
  max-height: 75vh;
  cursor: pointer;
  border-radius: 16px;
  max-width: 85%;
  margin: 0px auto;
  overflow: hidden;
}

.imageContainer___flBLu img {
  max-height: 90vh;
  object-fit: cover;
  max-width: 100%;
  margin: 0px auto;
}

.thumbnailsContainer___3ZdPi {
  position: absolute;
  z-index: 200;
  pointer-events: visiblePainted;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  left: 0;
  right: 0;
}

.thumbnails___dyfia {
  background-color: #fff;
  border-radius: 8px;
}

.thumbnail___o7RQ5 {
  padding: 0;
  margin: 10px;
  border-radius: 6px;
  overflow: hidden;
  border: 3px solid #fff;
  box-sizing: content-box;
}

.selected___27j-9.thumbnail___o7RQ5 {
  border-color: #009DC5;
}

.thumbContainer___caRpF {
  position: relative;
}

.thumbnail___o7RQ5,
.thumbnail___o7RQ5 img {
  width: 40px;
  height: 40px;
}

.badge___1nBZx,
.title___2Tl5n {
  position: absolute;
}

.badge___1nBZx {
  right: 16px;
  top: 16px;
  display: block;
  background: #fff;
  border-radius: 16px;
  padding: 0 16px;
}
.pageTitle___MGkrf {
  font-size: 32px;
  margin-top: 16px;
}
.title___2Tl5n {
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  padding: 16px;
}

.arrow___af_dC {
  position: absolute;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.arrowLeft___3W0bs {
  left: 0;
}

.iconLeft___3Ki9A,
.iconRight___uN2cA {
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.54);
}

.iconLeft___3Ki9A {
  background-image: url("/static/icons/arrow-back.svg");
}

.iconRight___uN2cA {
  background-image: url("/static/icons/arrow-forward.svg");
}

.arrowRight___1L9pz {
  right: 0;
}

.contentNotLooses___2hrtJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 12 span;
  margin: 0 10px;
}

.notLooses___S4Wxr {
  display: flex;
  margin-bottom: 25px;
}

.notLoosesImg___31LA1 {
  width: 20%;
}

.notLoosesImg___31LA1:hover {
  opacity: 0.6;
}

.notLoosesData___RKeY2 {
  width: 80%;
}

.notLoosesDataTitle___1GkYU {
  display: flex;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  padding: 0 20px 5px;
}

.notLoosesDataDescription___3hy2x {
  display: flex;
  padding: 0 20px;
}

.notLoosesDataDescription___3hy2x div {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.9 !important;
  letter-spacing: normal;
  color: #2f2f2f;
}

.notLooseContainer___3Jdgc {
  flex: 1;
  grid-column: 12 span;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notLooseSticker___1j1La {
  max-height: 75vh;
  cursor: pointer;
  border-radius: 16px;
  max-width: 80%;
  margin: 0px auto;
  overflow: hidden;
  position: relative;
}

.image___163ds {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 50vh;
  position: relative;
}

.infos___2e82- {
  background-color: rgba(51, 54, 66, 1);
  padding: 10px 24px;
  height: 25vh;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: #edeef4;
  box-sizing: border-box;
}

.infos___2e82- h4 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #edeef4;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.infos___2e82- > div {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
}

.infos___2e82- .description___dnZ1t {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #edeef4;
  max-height: 20vh;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.notContentShopping___DhJ5t{
  grid-column: span 12 !important;
  max-width: 100%;
}

.contentVerticalTickets___2xZGO {
  flex-direction: row !important;
  align-items: flex-start !important;
}

@media (max-width: 1023px) {
  .modalContent___1bGVX {
    overflow-y: hidden;
  }
  .thumbnailsContainer___3ZdPi {
    left: 0;
    right: 0;
    bottom: 0;
    top: initial;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .shoppingBar___39Zh0 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #shoppingBar___39Zh0 {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .thumbContainer___caRpF {
    max-width: 90%;
  }

  .notLooses___S4Wxr {
    flex-direction: column;
  }

  .notLoosesImg___31LA1 {
    width: 100%;
  }

  .notLoosesData___RKeY2 {
    width: 100%;
  }

  .notLoosesDataTitle___1GkYU {
    font-size: 18px;
    padding: 20px 0px 5px;
  }

  .notLoosesDataDescription___3hy2x {
    display: flex;
    padding: 0;
  }

  .notLoosesDataDescription___3hy2x div {
    font-size: 14px;
  }

  .notLooseSticker___1j1La {
    max-width: 90%;
  }

  .image___163ds {
    height: 25vh;
  }

  .infos___2e82- {
    padding: 16px;
    height: auto;
  }
  .infos___2e82- h4 {
    font-size: 15px;
    width: 100%;
    max-height: 66px;
    padding-bottom: 12px;
  }
  .infos___2e82- .description___dnZ1t {
    font-size: 10px;
    max-height: inherit;
    -webkit-line-clamp: inherit;
  }
  #shoppingBar___39Zh0 {
    display: none;
  }
  .contentVerticalTickets___2xZGO {
    flex-direction: column !important;
  }
}
