.content___1HZGo{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 48px;
  background-color: white;
  color: #898ea3;
  align-items: center;
  box-sizing: border-box;
}

.contentTexts___304S0{
  display: grid;
  grid: 1fr / repeat(2, 1fr);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.label___1exvO{
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.11px;
  height: 24px;
  position: relative;
  align-self: center;
  justify-content: center;
  display: flex;
}

.label___1exvO::before {
  content: attr(data-value);
  position: relative;
  width: 24px;
  height: 24px;
  left: -7px;
  border-radius: 24px;
  border: 1px solid #898ea3;
  text-align: center;
  display: inline-block;
}

.label___1exvO.selected___2RWuw{
  color: #009DC5;
  font-weight: 600;
}

.label___1exvO.selected___2RWuw::before {
  background-color: #009DC5;
  border: 1px solid #009DC5;
  color: white !important;
}

.contentMarks___3SxwA{
  display: grid;
  height: 4px;
  width: 100%;
  grid: 1fr / repeat(2, 1fr);
  grid-gap: 0 6px;
}

.span___18dRT{
  background-color: #898ea3;
  height: 4px;
}

.span___18dRT.selected___2RWuw{
  background-color: #009DC5;
}

@media only screen and (min-width: 1024px) {

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 768px) {
  .content___1HZGo {

  }
  .contentTexts___304S0{
    display: grid;
    grid: 1fr / repeat(1, 1fr);
  }
  .label___1exvO{
    display: none;
    padding-left: 32px;
  }
  .label___1exvO.selected___2RWuw{
    display: block;
  }
}
