.checkoutbar___2aiy1{
  width: 100%;
  height: 65px;
  background-color: white;
  padding: 20px 88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  top: 80px;
  z-index: 100 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.checkoutbarItem___2vKO4{
  display: block;
  position: relative;
}

.checkoutbarItem___2vKO4 .checkoutbarItemNumber___1j_ax,
.checkoutbarItem___2vKO4 .checkoutbarItemText___2XXcW{
  font-family: 'Montserrat', sans-serif;;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.11px;
  color: #898ea3;
  display: inline-block;
}

.checkoutbarItem___2vKO4 .checkoutbarItemNumber___1j_ax{
  width: 24px;
  height: 24px;
  text-align: center;
}

.checkoutbarItem___2vKO4 .checkoutbarItemNumber___1j_ax span{
  z-index: 1;
  position: relative;
}

.checkoutbarItem___2vKO4 .checkoutbarItemNumber___1j_ax:before{
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #898ea3;
  width: 34px;
  height: 34px;
  position: absolute;
  top: -3px;
  left: -6px;
}

.checkoutbarItem___2vKO4 .checkoutbarItemText___2XXcW{
  height: 24px;
  margin-left: 13px;
}

.checkoutbarItem___2vKO4.selected___Tiwlg .checkoutbarItemNumber___1j_ax{
  font-weight: 600;
  color: #edeef4;
}

.checkoutbarItem___2vKO4.selected___Tiwlg .checkoutbarItemNumber___1j_ax:before{
  background: #009DC5;
  border: 1px solid #009DC5;
  z-index: 0;
}

.checkoutbarItem___2vKO4.selected___Tiwlg .checkoutbarItemText___2XXcW{
  color: #009DC5;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .checkoutbar___2aiy1{
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    height: 95px;
  }
}
