.check___3S_gT,
.selected___16d_Z {
	position: relative;
	padding-left: 25px;
  float: left;
  padding-right: 5px;
  cursor: pointer;
}

.check___3S_gT:before {
  content: '';
  border: 1px solid #cd22a6;
  position: absolute;
  left: -1px;
  top: 1px;
  width: 19px;
  height: 19px;
  transition: all 0.3s ease 0s;
  box-shadow: 0;
}

.selected___16d_Z:after {
  content: '';
  background: #cd22a6;
  position: absolute;
  left: 1px;
  top: 3px;
  width: 17px;
  height: 17px;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 16px 24px rgba(205, 34, 166, 0.4);
}
