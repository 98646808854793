.orders___26Vil {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 12 span;
  margin: 20px;
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1600px) {
  .orders___26Vil {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .orders___26Vil {
      grid-template-columns: repeat(2, 1fr);
      margin: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .body-search .trips___2c45L {
      display: none!important;
  }
  .orders___26Vil {
    margin: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 64px;
  }
}
