.row___3nshd {
  display: flex;
  flex-direction: row;
  margin: 0 8px;
  margin-bottom: 16px;
}

.label___1rrDy {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.price___22CRg {
  font-weight: bold;
}

.tag___2VbTA {
  font-size: 10px;
  color: rgb(196, 50, 190);
  align-self: flex-start;
}

.informations___Aukxt {
  color: rgba(0, 0, 0, 0.32);
  font-size: 0.8em;
}
