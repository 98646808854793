.container___249Ti {
    margin: 0 auto !important;
    position: relative !important;
    padding-left: 88px;
    padding-right: 89px;
    box-sizing: border-box;
}

@media only screen and (max-width: 991px) {
    .container___249Ti {
        padding-left: 24px;
        padding-right: 24px;
    }
    .body-search .container___249Ti {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

@media only screen and (max-width: 480px) {
    .container___249Ti {
        padding-left: 9px;
        padding-right: 9px;
    }
    .body-search .container___249Ti {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
