.shopifyPage___2u9SF {
  display: flex;
  justify-content: center;
  margin: 100px 0;
}
.contenedoGaleria___3x0Hu {
  /*grid-area: contenedoGaleria;*/
  grid-column: span 4;
  column-gap: 20px;
  min-height: 100px;
}

.contenedoImagenPrincipal___31LHC {
  display: flex;
  position: relative;
}

.imagePrincipalShopify___-KowD {
  /* border: 1px black solid; */
  padding: 20px;
}

.imagePrincipalShopify___-KowD img {
  width: 100%;
  height: auto;
}

.arrowLeft___Cwu4U {
  display: flex;
  float: left;
  color: #009DC5;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 24px;
  width: 24px;
  margin: auto;
}

.arrowRigth___3rF0j {
  display: flex;
  float: left;
  color: #009DC5;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 24px;
  width: 24px;
  margin: auto;
}

.arrowLeft___Cwu4U {
  left: 0;
}

.arrowRigth___3rF0j {
  right: 0;
}

.contenedoImagenes___mIvbC {
  margin-top: 16px;
}

.galeriaMiniShopify___3jyr1 {
  max-width: 100px;
  max-width: 100px;
  width: 112px;
  height: auto;
  margin-top: 20px;
  border-spacing: 10px 5px;
  justify-content: center;
}

.heartButtonGalery___3R3GD {
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: white;
  color: #009DC5;
  margin-left: 16px;
  background-image: url("/static/icons/love.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 10px;
  right: 29px;
}
.contenerDatosProducto___2asFh {
  /*grid-area: contenerDatosProducto;*/
  grid-column: span 5;
  display: grid;
}
.nombreProductoShopify___3uxJ1 {
  height: 40px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #2f2f2f;
  margin: 17px;
}
.colors___s2LJI {
  display: grid;
  flex-direction: row;
  float: left;
  height: 31px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #2f2f2f;
}
.coloresShopify___1KJbk {
  display: flex;
  flex-direction: row !important;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  font-family: Montserrat;
  text-transform: uppercase !important;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  opacity: 0.8;
  border: solid 1px #c819c2;
}
.talla___2ZeXq {
  flex-direction: row;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #2f2f2f;
  margin: 0 17px;
}
.tallasShopify___31D6K {
  display: inline-block;
  flex-direction: row !important;
}
.arrayTallas___2xfVw {
  border-radius: 4px;
  font-family: Montserrat;
  text-transform: uppercase !important;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  opacity: 0.8;
  border: solid 1px #c819c2;
  margin-right: 12px;
  width: calc(40px * 1rem);
  height: calc(40px);
  padding: 0 15px;
}
.arrayTallas___2xfVw.selected___2qgN0 {
  border-radius: 4px;
  border: solid 1px #c819c2;
  background-color: #c628c0;
}
.guiaDeTallas___2JnSt {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #c628c0;
  text-decoration: underline #c628c0;
  cursor: pointer;
  display: inline-block;
  flex-direction: column;
  margin: 20px 50px;
}
.contenedorCarrito___1YTiq {
  padding-top: 70px;
  /*grid-area: contenedorCarrito;*/
  grid-column: span 3;
  margin-left: 10px;
  justify-content: flex-end;
}
.precioOriginalShopify___8lKNQ {
  display: block;
  margin-bottom: 0px;
  height: 22px;
  opacity: 0.3;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  text-align: right;
  color: #2f2f2f;
  text-decoration: line-through;
}
.precioRebajaShopify___3qb5w {
  float: right;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #333642;
}
.buttonAddCarrito___1CZrA {
  width: 304px !important;
  height: 40px !important;
  border-radius: 100px;
  border: solid 1px #009DC5;
  background-color: #009DC5;
  width: 303px;
  height: 28px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #edeef4;
  text-transform: uppercase;
}
.description___1LXyU {
  display: block;
  height: 30px;
  font-family: Montserrat;
  font-size: 18px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #2f2f2f;
  margin: 17px;
}
.cheveronDownDescription___1MH_C {
  width: 10px;
  height: 8px;
  justify-content: right;
  float: right;
  color: #d123bf;
  cursor: pointer;
}
.descriptionShopify___uxCmR {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.09px;
  color: #2f2f2f;
  display: flex;
  margin: 17px !important;
}
.materiaslesProductoShopify___joLz5 {
}
.descriptionMaterialesShopify___3Xic9 {
}

.masInfoShopify___1oIxh {
}
.masInfoShopifyDescripton___NO7tx {
}

@media (max-width: 768px) {
  .shopifyPage___2u9SF {
    margin: 30px 0;
  }
  .contentProductDetail___2V2ni{
    grid: 1fr / repeat(2, 1fr);
  }
  .contenedoGaleria___3x0Hu{
    grid-column: span 1;
  }
  .contenerDatosProducto___2asFh {
    display: grid;
    grid-column: span 1;
  }
  .tallasShopify___31D6K{
    margin-left: 8px;
  }
  .arrayTallas___2xfVw{
    margin-left: 8px;
    margin-bottom: 8px;
    margin-right: 0;
  }
  .contenedorCarrito___1YTiq {
    display: grid;
    grid-row: span 1;
    grid-column: span 2;
    padding-top: 30px;
    justify-content: center;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .shopifyPage___2u9SF {
    margin: 10px 0 30px;
  }
  .contentProductDetail___2V2ni{
    grid: 1fr / repeat(1, 1fr);
  }
  .contenedoGaleria___3x0Hu{
    grid-row: span 1;
    grid-column: span 1;
  }
  .contenedoImagenes___mIvbC{
    padding: 0 8px;
  }
  .contenerDatosProducto___2asFh {
    grid-row: span 1;
    grid-column: span 1;
  }



  .contenedorCarrito___1YTiq {
    grid-row: span 1;
    grid-column: span 1;
    padding-top: 10px;
  }

  /*
  .contenedoGaleria{
    padding-top: 10px;
    grid: 2fr;
    display: grid;
    grid-row: span 1;
    grid-column: span 1;
  }

  .contenedoImagenPrincipal {
    width: 100%;
    align-items: center;
    grid-row: span 1;
    grid-column: span 1;
    display: grid;
    justify-content: center;
    padding: 0 10px;
}
  .arrowLeft {
    float: left;
    left: 0;
  }
  .arrowRigth {
    float: right;
    right: 0;
  }
  .galeriaMiniShopify {
    max-width: 100px;
    width: 112px;
    height: auto;
    margin: auto;
    top: 10px 5px;
    justify-content: center;
  }

  .contenedorCarrito {
    display: inline-block;
    padding-top: 10px;
    justify-content: center;
    grid-row: span 1;
    grid-column: span 1;
  }
  .contenerDatosProducto {
    display: grid;
    justify-content: center;
    width: 100%;
    grid-row: span 1;
  }
  .nombreProductoShopify{
    top: 0;
    font-size: 21px;
    font-weight: 600;
    text-align: left;
  }
  .tallasShopify{
    font-size: 16px;
  }
  .cheveronDownDescription{
    display: none;
  }
  */
}
