.slider___3S5-W{
  position: relative;
}
.slider___3S5-W .slick-prev___aAt-w, .slick-next___2gfk- {
  position: absolute;
  top: 50%;
}
.slider___3S5-W .slick-prev___aAt-w {
  left: 5%;
}
.slider___3S5-W .slick-next___2gfk- {
  right: 5%;
}

.slick-prev {
  left: -18px;
  width: 32px;
  height: 32px;
  background: #009DC5;
  border-radius: 100%;
  z-index: 1;
}
.slick-prev:hover {
  background: #009DC5;
}
.slick-prev:active {
  background: #009DC5;
}
.slick-prev:visited {
  background: #009DC5;
}
.slick-prev:focus {
  background: #009DC5;
}
.slick-next {
  right: -18px;
  width: 32px;
  height: 32px;
  background: #009DC5;
  border-radius: 100%;
  z-index: 1;
}
.slick-next:hover {
  background: #009DC5;
}
.slick-next:active {
  background: #009DC5;
}
.slick-next:visited {
  background: #009DC5;
}
.slick-next:focus {
  background: #009DC5;
}

.slick-prev:before{
  font-size: 40px;
  opacity: 1;
  position: relative;
  left: -4px;
  top: -1px;
}
.slick-next:before{
  font-size: 40px;
  opacity: 1;
  position: relative;
  left: -4px;
  top: -1px;
}
