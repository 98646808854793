.tripitem___2Yxvg {
  height: 380px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #e7e7e7;
  margin-right: 16px;
  margin-bottom: 32px;
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;
  pointer-events: fill;
}

.image___1vgPK {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 235px;
  position: relative;
}

.tripitem___2Yxvg.loading___1t8t6 {
  -webkit-filter: blur(2px);
}

.infos___3Nf31 {
  background-color: rgba(51, 54, 66, 1);
  padding: 10px 24px;
  height: 140px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: #edeef4;
}

.infos___3Nf31 h4 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600; /*SemiBold = 600*/
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #edeef4;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.infos___3Nf31 > div {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
}

.infos___3Nf31 .description___3BLdG {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #edeef4;
  max-height: 52px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.commands___WyJ0i {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
}

.tripitem___2Yxvg .image___1vgPK .days___zNSPz {
  display: flex !important;
  position: absolute;
  left: 8px;
  top: 8px;
}
.tripitem___2Yxvg .image___1vgPK .day___1KLZ3 {
  color: #fff;
  background-color: #009DC5;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.tripitem___2Yxvg .infos___3Nf31 .day___1KLZ3 {
  display: none;
}


@media only screen and (max-width: 991px) {
  .tripitem___2Yxvg {
    margin-right: 0px !important;
    background-color: transparent;
    height: 292px !important;
  }
  .tripitem___2Yxvg * {
    font-family: "Poppins", sans-serif !important;
  }
  .tripitem___2Yxvg .infos___3Nf31 {
    background-color: transparent;
    padding: 10px 0;
    height: 140px;
    position: inherit !important;
    bottom: inherit !important;
    left: inherit !important;
    right: inherit !important;
    display: flex;
    flex-direction: column;
    color: #000;
  }
  .tripitem___2Yxvg .infos___3Nf31 h4 {
    text-shadow: none !important;
    font-size: 25px;
    font-weight: 400 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22 !important;
    letter-spacing: -0.14px;
    color: #000;
  }
  .tripitem___2Yxvg .infos___3Nf31 > div {
    display: block !important;
  }
  .tripitem___2Yxvg .image___1vgPK {
    height: 180px;
    border-radius: 6px;
  }
  .tripitem___2Yxvg .infos___3Nf31 .description___3BLdG {
    color: rgba(0, 0, 0, 0.64);
  }
}
