.map-viewer-search,
.map-viewer-search #__next,
#mapViewer___1ZgkK {
  height: 100vh;
}

#mapViewer___1ZgkK.fixedView___3IvXe {
  display: flex;
  flex-direction: column;
}

.content___1L5Fz {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.mapContainer___2ObFZ {
  background-color: #eaeaea;
  position: relative;
  display: flex;
  flex: 1;
}

.buttonClose___1F2C2{
  position: absolute;
  z-index: 9999;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  text-indent: -6000px;
  border: solid 1px #009DC5;
  border-radius: 32px;
  top: 10px;
  left: 10px;
}

.buttonClose___1F2C2:after{
  content: '';
  background-image: url(/static/icons/close.svg)!important;
  background-size: cover;
  width: 24px;
  height: 24px;
  display: block;
  margin: 5px 0;
}

.map-viewer .buttonClose___1F2C2{
  display: none;
}

.mapLoader___OaJIm {
  height: inherit;
}

.map___2rQKB {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mapView___VEpwX {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerContainer___3Tbo_ {
  margin-top: 64px;
}

.fixedView___3IvXe .footerContainer___3Tbo_ {
  margin-top: 0;
}

.fixedView___3IvXe .calendarFooter___2nao8 {
  display: none;
}

.mobileCalendarFooter___3R8Ek {
}

.resultsContainer___3XTiP {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 140px;
}

.map-viewer-search .resultsContainer___3XTiP{
  top: inherit;
  bottom: 10px;
}

.mapResult___7WbIK {
  display: inline-flex;
  width: 409px;
  height: 147px;
  background-color: #fff;
  margin-left: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
}

.mapResult___7WbIK a {
  font-size: 12px;
}

.selected___3oVLs.mapResult___7WbIK {
  background-color: #009DC5;
  transition: 0.3s ease-in-out;
  color: #fff;
}

.image___3l5V_ {
  width: 105px;
  height: 105px;
  background-color: #eaeaea;
  margin-left: 15px;
  background-position: center;
  background-size: cover;
}

.informations___W5Cf3 {
  align-self: stretch;
  flex: 1;
  margin: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}

.informations___W5Cf3 .texts___3gHcV {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.texts___3gHcV span {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.selected___3oVLs.mapResult___7WbIK a {
  color: #fff;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .map-viewer-search {
    overflow: hidden;
  }
  .resultsContainer___3XTiP {
    bottom: 60px !important;
  }
  .mapContainer___2ObFZ {
    margin-bottom: 58px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .map-viewer-search {
    overflow: hidden;
    margin-bottom: 0;
  }

  .map-viewer-search .resultsContainer___3XTiP {
    bottom: 60px;
  }

  .mapResult___7WbIK {
    width: 300px;
    height: 120px;
  }
  .image___3l5V_ {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    background-color: #eaeaea;
    margin-left: 15px;
    background-position: center;
    background-size: cover;
  }
  .mapContainer___2ObFZ {
    /*margin-bottom: 58px;*/
  }
  .mapResult___7WbIK a {
    font-size: 12px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    text-align: left !important;
  }
  .texts___3gHcV span {
    font-size: 13px !important;
  }
}
