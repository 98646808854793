.contentForm___1g-1y {
    display: flex;
    flex-direction: column;
    background-color: #ECEEF2;
    border-radius: 5px;
    padding: 12px;
    box-sizing: border-box;
}

.contentForm___1g-1y.header___3a8Su{
    margin-bottom: 15px;
}

.whatsapp___2p-hM,
.callphone___2K_QQ {
    padding-left: 35px;
    position: relative;
}

.whatsapp___2p-hM a,
.callphone___2K_QQ a{
    text-decoration: none;
}

.whatsapp___2p-hM::before,
.callphone___2K_QQ::before {
    content: '';
    height: 30px;
    width: 30px;
    display: inline-block;
    position: absolute;
    left: 0;
}

.whatsapp___2p-hM::before{
    -webkit-mask-image: url(/static/icons/whatsapp.svg);
    mask-image: url(/static/icons/whatsapp.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;;
}

.callphone___2K_QQ::before{
    -webkit-mask-image: url(/static/icons/callphone.svg);
    mask-image: url(/static/icons/callphone.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    background-color: #009DC5;;
}

.row___2Dsap {
    width: 100%;
    margin: 12px 0;
    border-radius: 5px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.contentForm___1g-1y.header___3a8Su .row___2Dsap{
    background-color: #ECEEF2 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.row___2Dsap .num___3XIgG,
.row___2Dsap .phone___3-ih3{
    width: 50%;
    display: inline-flex;
}

.select___3w1HV{
    width: 100%;
    height: 44px;
    border: 0;
    margin: 10px;
    padding: 0px 15px;
}

.input___1ob4Z {
    border: 0;
    height: 44px;
    margin: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid white;
}

.input___1ob4Z {
  width: 100%;
}

.input_error___3ddpA {
  border-color: red;
}

.contentForm___1g-1y.fotter___2OeLe{
    margin-top: 15px;
    background-color: transparent !important;
}

.contentForm___1g-1y.fotter___2OeLe .row___2Dsap{
    background-color: transparent !important;
}

.button___3UvTH {
    background-color: #009DC5;
    text-decoration: none;
    text-transform: uppercase;
    padding: 16px 8px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 32px;
    cursor: pointer;
    width: 100%;
    border: none;
}

.contentTerms___byMcV span,
.contentTerms___byMcV span a{
    font-size: 12px;
}
