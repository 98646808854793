.navigationScroll___2qtZJ {
    width: 100%
}

.filters___18UWX {
    /*padding: 16px 0;
    border-bottom: 1px solid #EAEAEA;
    */
    padding: 16px 0;
    width: 100%;
    position: relative;
}

.action_button___1VaQF {
    background: transparent;
    border: 0;
}

.content___ghJLg {
    display: flex;
    flex-direction: column;
}

.content___ghJLg .button___13tr5 {
    align-self: flex-end;
    margin-right: 16px;
}

.left___zpP7h {
    flex: 1;
}

.filters___18UWX>div:first-child {
    margin: 0 !important;
    /*
    display: flex;
    flex: 1;
    */
    padding-left: 0;
    padding-right: 0;
}

.show_subcategories___16e_r {
    position: absolute;
    right: 26px;
    bottom: 0;
    text-align: center;
    z-index: 1;
    top: -10px;
    height: 20px;
    margin: auto 8px;
    width: 90px;
}

.show_subcategories___16e_r.show_filters_button___YUyh_ {
    width: 300px;
}

.content_filter_button___3ifiQ {
    display: block;
    background-color: white;
}

.show_button___2CFDJ {
    width: 120px;
    height: 20px;
    margin: 0 8px;
    object-fit: contain;
    display: inline-block;
    text-decoration: none;
    position: relative;
    padding-left: 30px;
}

.content_search_button___3Y000 {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.show_search_input___1T-_Y .search_input___258lC {
    width: 240px;
    border-width: 1px;
    padding: 0 16px;
    -webkit-transition: all 1s;
    /* Safari prior 6.1 */
    transition: width 1s;
}

.search_input___258lC {
    position: absolute;
    width: 0;
    height: 44px;
    border-radius: 4px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(0, 0, 0, .2);
    background-color: #ffffff;
    display: flex;
    margin-right: 12px;
    top: -12px;
    right: 5px;
    border-width: 0;
    padding: 0;
    -webkit-transition: all 1s;
    /* Safari prior 6.1 */
    transition: width 1s;
}

.search_button___29Cin {
    width: 20px;
    height: 20px;
    margin: 0 8px;
    object-fit: contain;
    display: inline-block;
    text-decoration: none;
    position: relative;
}

.show_button___2CFDJ:after {
    content: url('/static/images/adjustmentsPurple.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 2px;
}

.show_button___2CFDJ span {
    display: block;
    text-align: left;
}

.search_button___29Cin:after {
    content: url('/static/images/searchPurple.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 2px;
}

.search_button___29Cin span {
    display: none;
}

.clear_button___otOnd {
    display: inline-block;
    height: 20px;
    position: relative;
    top: -7px;
}

.navigationContainer___1fZXG {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subcategories___I3l5P {
    border-top: 1px solid #EAEAEA;
}

.navigation___147z8 .left___zpP7h {
    /*
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr)
    */
}

.filter___-mBGA {
    padding-right: 16px;
    margin-bottom: 16px;
}

.navigation___147z8 .left___zpP7h .container___UMvIl {
    width: 100%;
}

.accent___ZnFmK {
    background-color: #009DC5;
    color: #FFF !important;
    border-radius: 6px;
}

.inputContainer___1MJeg {
    display: flex;
    margin-right: 16px;
}

.input___3UYID {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 8px 16px;
    margin-right: 16px;
    flex: 1;
}

.button___13tr5 {
    background-color: #009DC5;
    border: 0;
    border-radius: 24px;
    padding: 8px 16px;
    color: white;
}

.item___2yzIX span {
    text-transform: uppercase;
    font-size: 14px;
}



.categories___23gAc .slick-slider {
    width: calc(100% - 90px);
}

.categories___23gAc.show_filters_button___YUyh_ .slick-slider {
    width: calc(100% - 300px);
}



.filtersOptions___IpJuW {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    align: left;
}

.divider___ssQej {
    background-color: #333642;
    width: 1px;
    height: 16px;
    margin: 0 16px;
}

.adjustments___2yB5j {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url('/static/images/adjustments.svg');
}

.search___1SrlP {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    background: url('/static/images/search.svg');
}



.accent___ZnFmK .icon___3s6KD {
    background-color: white;
}

.forYou___3v_Tn {
    -webkit-mask-image: url('/static/icons/profile.svg');
    mask-image: url('/static/icons/profile.svg');
}

.alojamiento___231WO,
.accommodations___1CSTk {
    -webkit-mask-image: url('/static/icons/office.svg');
    mask-image: url('/static/icons/office.svg')
}

.restaurantes___H0zyx,
.restaurants___3vQMd {
    -webkit-mask-image: url('/static/icons/food.svg');
    mask-image: url('/static/icons/food.svg')
}

.que_ver___1KbDj,
.to_see___Y_hnU {
    -webkit-mask-image: url('/static/icons/picture.svg');
    mask-image: url('/static/icons/picture.svg')
}

.fiesta___22_gq,
.party___3Vze4 {
    -webkit-mask-image: url('/static/icons/music-note.svg');
    mask-image: url('/static/icons/music-note.svg')
}

.ocio___1MiME,
.leisure___FXVWz {
    -webkit-mask-image: url('/static/icons/beer.svg');
    mask-image: url('/static/icons/beer.svg')
}

.compras___39zrz,
.shop___3wtCA {
    -webkit-mask-image: url('/static/icons/shop.svg');
    mask-image: url('/static/icons/shop.svg')
}

.experiencias_actividades___37tX6{
    -webkit-mask-image: url('/static/icons/tasks.svg');
    mask-image: url('/static/icons/tasks.svg')
}

.menu-wrapper {
    overflow-x: hidden;
    overflow-y: inherit !important;
}

.toggleContainer___1cDGR {
    flex: 1;
    position: relative;
    display: flex;
    border: 1px solid #009DC5;
    color: #FFF;
    border-radius: 6px;
    width: fit-content;
    width: -moz-fit-content;
    height: 44px;
    margin: 0 16px;
}

.toggleContainer___1cDGR {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #009DC5;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #009DC5;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.toggleContainerSelected___1Ltyz {
    background-color: #009DC5 !important;
}

.toggleContainer___1cDGR .toggleButton___3Cg36 {
    flex: 1;
    background-color: transparent;
    border: 0;
    text-align: left;
    margin: 0 16px;
}

.toggleContainer___1cDGR .toggleButton___3Cg36 span {
    color: #333642;
}

.toggleContainerSelected___1Ltyz .toggleButton___3Cg36 span {
    color: #FFF !important;
}

.show_options___jGZNx {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 40px;
    max-width: 300px;
    z-index: 120;
    padding-top: 15px;
}

.show_options_content___3-HSP {
    position: relative;
    box-shadow: 0 2px 1px 0 #009DC533;
    border: solid 1px #009DC5;
    border-radius: 4px;
    max-height: 200px;
    overflow: auto;
    background-color: #FFF;
}

.show_options___jGZNx:before {
    content: '';
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid transparent;
    border-bottom: 15px solid #009DC5;
    top: -10px;
    left: 10px;
}

.show_options___jGZNx:after {
    content: '';
    position: absolute;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -4px;
    left: 15px;
}

.destinationItem___2GwGf {
    background: #FFF;
    border: 0;
    text-align: left;
    padding: 8px 16px;
    display: block;
    width: 100%;
}

.select___3Fgpk {
    display: block;
    margin: 8px 16px;
}

.destinationItem___2GwGf:hover {
    background: #eaeaea;
}

.destinationItem___2GwGf>span {
    display: block;
}

.destinationTitle___qGjxe {
    color: #000;
    margin: 0 16px;
    font-weight: bolder;
}

.mobileFilterBar___9ynTN {
    display: none;
    position: relative;
    margin-bottom: 16px;
}

.mobileFilterBar___9ynTN .close_button___3wG4u {
    width: 20px;
    height: 20px;
    margin: 0;
    object-fit: contain;
    display: inline-block;
    text-decoration: none;
    position: relative;
    padding-left: 0;
}

.mobileFilterBar___9ynTN .close_button___3wG4u:after {
    content: url('/static/images/close.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 2px;
}

.mobileFilterBar___9ynTN .title___OjvcU {
    width: calc(100% - 116px);
    height: 23px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #2f2f2f;
    display: inline-block;
    position: absolute;
    top: -16px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.mobileFilterBar___9ynTN .show_button___2CFDJ {
    border: 0;
    width: 96px;
    height: 22px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.1px;
    color: #a7abbe;
    float: right;
}

.mobileFilterBar___9ynTN .show_button___2CFDJ span {
    text-align: right;
}

.mobileFilterBar___9ynTN .show_button___2CFDJ:after {
    content: '';
}

@media only screen and (max-width: 991px) {
    .body-search .navigation___147z8 {
        /*display: none!important;*/
    }

    .filters___18UWX>div {
        flex-direction: column-reverse;
    }

    .filter___-mBGA {
        padding-left: 16px;
        padding-top: 16px;
    }

    .filters___18UWX .container___UMvIl>div>div {
        display: flex;
    }

    .content___ghJLg .button___13tr5 {
        align-self: stretch;
        flex: 1;
        margin: 0 16px;
    }

    .filters___18UWX button {
        flex: 1;
        justify-content: center;
    }

    .filters___18UWX>div:first-child {
        align-items: initial;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .filters___18UWX .left___zpP7h>div {
        flex: 1;
    }

    .navigationContainer___1fZXG {
        overflow-x: scroll !important;
    }

    .navigationContainer___1fZXG::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    .navigationContainer___1fZXG nav {
        width: 1200px;
        overflow: hidden;
    }

    .results___2w7V6 {
        display: none !important;
    }

    .left___zpP7h {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .categories___23gAc .horizontal-menu {
        width: calc(100% - 80px);
    }

    .show_subcategories___16e_r {
        width: 80px;
    }

    .clear_button___otOnd {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .navigation___147z8 {
        margin-bottom: 50px;
    }

    .categories___23gAc {
        padding: 8px 0px;
    }

    .categories___23gAc .horizontal-menu {
        width: 100%;
    }

    .categories___23gAc .slick-slider,
    .categories___23gAc.show_filters_button___YUyh_ .slick-slider {
        width: 100%;
    }

    .show_subcategories___16e_r {
        position: relative;
        left: 0;
        width: 100vw;
        background: white;
        height: 32px;
        margin: 0;
        top: 0;
        padding: 8px 0;
    }

    .show_subcategories___16e_r.show_filters_button___YUyh_ {
        width: 100%;
    }

    .navigation_with_filter___3bcwD .show_subcategories___16e_r {
        top: 0;
    }

    .filters___18UWX {
        padding: 8px;
    }

    .show_options___jGZNx {
        left: 0 !important;
        margin: auto !important;
        width: 80% !important;
    }

    .show_options___jGZNx:before {
        left: calc(50% - 25px);
    }

    .show_options___jGZNx:after {
        left: calc(50% - 20px);
    }

    .content_filter_button___3ifiQ {
        display: block;
        width: calc(50% - 20px);
        box-sizing: border-box;
        margin: 0 8px;
    }

    .show_button___2CFDJ {
        width: 100%;
        padding-left: 0;
        height: 32px;
        border-radius: 8px;
        border: solid 1px #009DC5;
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        margin: 0;
    }

    .content_search_button___3Y000 {
        width: calc(50% - 20px);
        height: 32px;
        display: inline-block;
        position: relative;
        margin: 0 8px;
    }

    .show_search_input___1T-_Y {
        padding: 8px;
        position: fixed;
        top: 82px;
        left: 0;
        height: calc(100% - 140px);
        opacity: 0.95;
        background-color: #ffffff;
        z-index: 999;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
    }

    .show_search_input___1T-_Y .search_input___258lC {
        position: relative;
        margin: 16px 0;
        width: 100%;
        background: border-box;
        box-sizing: border-box;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0s;
    }

    .search_input___258lC {
        transition: all 0s;
    }

    .search_button___29Cin {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        border: solid 1px #009DC5;
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        margin: 0;
    }

    .show_search_input___1T-_Y .search_button___29Cin {
        outline: 0;
        margin: 0;
        width: 50%;
    }

    .show_button___2CFDJ:after,
    .search_button___29Cin:after {
        position: absolute;
        left: calc(50% - 60px);
        top: 3px;
    }

    .show_button___2CFDJ span,
    .search_button___29Cin span {
        display: block;
        text-transform: uppercase;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.29;
        letter-spacing: normal;
        text-align: center;
        margin-top: 3px;
    }

}

@media only screen and (max-width: 480px) {
    .filters___18UWX {
        padding: 8px;
        position: fixed;
        top: 82px;
        left: 0;
        height: calc(100% - 140px);
        opacity: 0.95;
        background-color: #ffffff;
        z-index: 999;
        width: 100%;
        box-sizing: border-box;
    }

    .filters___18UWX .slick-track {
        width: 100% !important;
    }

    .filters___18UWX .slick-list {
        padding: 0px !important;
        height: 100% !important;
    }

    .filters___18UWX .slick-slide {
        width: 100% !important;
    }

    .filters___18UWX .toggleContainer___1cDGR {
        width: 100%;
        box-sizing: border-box;
        margin: 8px 0;
    }

    .mobileFilterBar___9ynTN {
        display: block;
    }
}

/* Styling Celina */

.categories___23gAc {
    padding: 30px 0;
    width: 93vw;
    position: relative;
    margin-left: 70px;
}

.icon___3s6KD {
    width: 24px;
    height: 24px;
    display: inline-flex;
    margin-right: 8px;
    background-color: #333642;
    mask-position: center;
    -webkit-mask-position: center;
}

.navigation___147z8 {
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    background-color: #ffffff;
    position: sticky;
    position: -webkit-sticky;
    height: 100px;
    top: 99px;
    z-index: 999;
}

.item___2yzIX {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 10px !important;
    line-height: 2.29;
    color: #333642;
    margin-right: 16px;
    padding: 7px 7px;
    display: inline-flex;
    align-items: center;
}

.categories___23gAc .scroll-menu-arrow {
    display: none;
}
