.heroContainer___2uceV {
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 90px 0;
}

.heroContainer___2uceV .titles___2GsU2,
.inputsRow___220D8 {
  grid-column: span 11;
  grid-column-start: 2;
  grid-column-end: 12;
}

.hero___30wZH {
  background: #6b6f82;
  display: flex;
  align-items: center;
  position: relative;
}
.image___aVmmA {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  z-index: 100;
}
.hero___30wZH .heroContainer___2uceV,
.hero___30wZH .container___k19yt {
  position: relative;
  z-index: 200;
}

.hero___30wZH h1,
.hero___30wZH p {
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1em !important;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 0;
  overflow-wrap: break-word;
}

.hero___30wZH h1 > h1:last-child,
.hero___30wZH h1 > p:last-child {
  margin-bottom: 20px !important;
}

.hero___30wZH h2 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
}

.search___3UvFA,
.relationships___2fSVJ,
.rangeDates___2eR9v,
.perfectFor___2jVp9,
.goto___2yH06 {
  grid-column: span 2;
  grid-row: 2;
  position: relative;
}

.search___3UvFA,
.relationships___2fSVJ,
.rangeDates___2eR9v,
.perfectFor___2jVp9 {
  height: 44px;
  display: flex;
}

.destinations___1FQVF {
  grid-column: span 2;
  grid-column-start: 2;
  grid-column-end: 4;
}

.rangeDates___2eR9v {
  grid-column: span 3;
  justify-content: space-evenly;
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
}

.DateInputFocused___3_Ft7 {
  border-color: #009DC5 !important;
}

.rangePicker___wOc8p {
  display: flex;
  border: none;
  background: none;
}

.rangePicker___wOc8p div {
  display: flex;
}

.perfectFor___2jVp9 {
  margin-right: 0;
}

.goto___2yH06 {
  grid-column: span 1;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 2.29;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  background-color: #009DC5;
  text-decoration: none;
  text-align: center;
}

.goto___2yH06 span {
  text-transform: capitalize;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.container___k19yt {
  margin: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.calendar___ucfzo .container___k19yt {
  max-width: 517px;
  color: #fff;
}

.container___k19yt > span {
  font-size: 16px;
  font-weight: 300;
  color: #edeef4;
  display: flex;
  text-align: center;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.badge___2MyaW {
  display: flex;
  font-size: 16px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: solid 1px #009DC5;
  background-color: #009DC5;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
}

.button___REYvF {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: relative;
  bottom: -56px;
  cursor: pointer;
}

.calendar___ucfzo {
  max-width: 620px;
  box-shadow: 0 2px 6px 0 rgba(69, 73, 91, 0.08);
  background-color: rgba(51, 54, 66, 0.9);
  border-radius: 6px;
  display: flex;
  grid-column: 12 span;
  margin: 91px 10px;
}

.calendar___ucfzo .destinations___1FQVF,
.calendar___ucfzo .rangeDates___2eR9v,
.calendar___ucfzo .perfectFor___2jVp9 {
  margin-bottom: 10px;
}

.dates___c7BRK {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dates___c7BRK > span {
  /* color: #edeef4; */
  display: block;
  text-align: center;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 45px !important;
}

.dates___c7BRK > .day___37yNJ {
  font-size: 64px;
}

.details___3QJtf {
  grid-column: 12 span;
  text-align: center;
  padding: 32px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
  margin: 41px 10px;
}

.details___3QJtf .button___REYvF {
  display: inline-block;
  align-self: center;
  padding: 16px 32px;
}

.toggleContainer___1cofi {
  flex: 1;
  position: relative;
  display: flex;
}

.toggleButton___2WSEN {
  flex: 1;
  background-color: #fff;
  border: 0;
  text-align: left;
  margin-right: 0;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.toggleButton___2WSEN:focus {
  border: 1px solid #009DC5;
}

.toggleButton___2WSEN::after {
  content: "";
  -webkit-mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
  -webkit-mask-size: 11px 11px;
  mask-size: cover;
  display: inline-flex;
  background-color: #009DC5;
  width: 11px;
  height: 11px;
  position: absolute;
  right: 4px;
  top: 17px;
}

.toggleButton___2WSEN span {
  color: rgba(42, 43, 51, 0.801);
  font-size: 13px;
}

.show_options___E01Ml {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  background-color: #fff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 2000;
}

.destinationItem___2830g {
  background: #fff;
  border: 0;
  text-align: left;
  padding: 8px 12px;
  display: block;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.select___33Pqp {
  display: block;
  margin: 8px 16px;
}

.destinationItem___2830g:hover {
  background: #eaeaea;
}

.destinationItem___2830g > span {
  display: block;
}

.toggleContainer___1cofi > .show_options___E01Ml.places___1z7Y7 > div:first-child {
  margin-top: 16px;
}

.destinationTitle___swVhl {
  margin: 0 8px;
  display: flex;
}

.CalendarDay__selected_span___1cknv {
  background: #82e0aa;
  color: white;
  border: 1px solid rosybrown;
}

.CalendarDay__selected___wYF0S {
  background: red;
  color: white;
}

.CalendarDay__selected___wYF0S:hover {
  background: orange;
  color: white;
}

.CalendarDay__hovered_span___JM5_k:hover,
.CalendarDay__hovered_span___JM5_k {
  background: brown;
}

.inputsRow___220D8 {
  display: flex;
  position: relative;
}

.inputsRow___220D8 .columnOne___3Qji2 {
  width: 100%;
  display: flex;
}

.inputsRow___220D8 .columnTwo___19Dje {
  width: 100%;
  display: flex;
}

.separator___cmUzv {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.toLeft___1cjPc {
  justify-content: flex-start;
}

.DateInput_input {
  padding: 10px 0 10px 6px;
}

.categoryHero___3NrLG:before {
  content: "";
  background: #00000022;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.categoryContainer___tuTH1 {
  z-index: 1;
}

@media only screen and (max-width: 1440px) {
  .toggleButton___2WSEN span {
    font-size: 0.9vw;
  }
}

@media (max-width: 1024px) {
  .hero___30wZH h1,
  .hero___30wZH p {
    font-size: calc(1em + 2vw);
  }
  .day___37yNJ {
    line-height: 1em !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .toggleButton___2WSEN span {
    font-size: 16px;
  }
  .heroContainer___2uceV .titles___2GsU2,
  .inputsRow___220D8 {
    grid-column: span 5;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .search___3UvFA,
  .relationships___2fSVJ {
    grid-column: span 2;
    grid-row: 2;
  }
  .rangeDates___2eR9v {
    grid-column: span 4;
    grid-row: 3;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .perfectFor___2jVp9 {
    grid-column: span 4;
    grid-row: 4;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .destinations___1FQVF {
    grid-column: span 2;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .goto___2yH06 {
    grid-column: span 4;
    grid-row: 5;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .calendar___ucfzo {
    grid-column: span 4;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  .calendar___ucfzo .container___k19yt {
    max-width: initial;
  }
}

@media only screen and (max-width: 991px) {
  .hero___30wZH {
    background: #009DC5;
  }
  .image___aVmmA {
    opacity: 0.6;
  }
  .image___aVmmA::before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      #009DC5 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .goto___2yH06 {
    margin-top: 16px;
    background-color: white !important;
  }
  .goto___2yH06 span {
    color: #009DC5;
    text-transform: uppercase;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .calendar___ucfzo {
    grid-column: 2 span;
    margin: 0px 10px;
  }
  .toggleButton___2WSEN span {
    font-size: 13px;
  }
  .hero___30wZH {
    padding: 20px 0;
  }
  .categoryHero___3NrLG {
    display: none !important;
  }
  .heroContainer___2uceV {
    margin: 0 10px;
    padding: 0;
  }
  .heroContainer___2uceV .titles___2GsU2,
  .inputsRow___220D8 {
    grid-column: span 2;
  }
  .search___3UvFA {
    grid-column: span 2;
    grid-row: 2;
  }
  .relationships___2fSVJ {
    grid-column: span 2;
    grid-row: 4;
  }
  .rangeDates___2eR9v {
    grid-column: span 2;
    grid-row: 3;
  }
  .perfectFor___2jVp9 {
    grid-column: span 2;
    grid-row: 5;
  }
  .destinations___1FQVF {
    grid-column: span 2;
  }
  .goto___2yH06 {
    grid-column: span 2;
    grid-row: 6;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
}
