.experiences___NovTW, .highlights___1xueJ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 12 span;
}

.highlights___1xueJ {
    grid-template-columns: repeat(2, 1fr);
}

.swiper_experiences___3KGlU {
    position: relative;
    grid-column: 12 span;
    max-width: 100%;
}

.swiper___cjLhF {
    padding: 32px 0;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1600px) {
    .experiences___NovTW {
        grid-template-columns: repeat(4, 1fr);
    }
    .highlights___1xueJ {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 991px) {
    .body-search .experiences___NovTW, .body-search .highlights___1xueJ {
        display: none!important;
    }
    .experiences___NovTW {
        grid-template-columns: repeat(1, 1fr);
    }
    .highlights___1xueJ {
        grid-template-columns: repeat(1, 1fr);
    }
    .swiper___cjLhF {
        padding: 24px;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .body-search .experiences___NovTW, .body-search .highlights___1xueJ {
        display: none!important;
    }
    .highlights___1xueJ, .experiences___NovTW {
        margin: 0 10px;
        grid-template-columns: repeat(1, 1fr);
    }
    .swiper___cjLhF {
        padding: 24px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .swiper___cjLhF {
        padding: 12px 0px;
        width: 100%;
    }
    .swiper_experiences___3KGlU .slick-slider .slick-arrow {
        /*display: none !important;*/
    }

    .swiper_experiences___3KGlU .slick-slider .slick-arrow.slick-prev {
        left: 5px
    }

    .swiper_experiences___3KGlU .slick-slider .slick-arrow.slick-next {
        right: 5px;
    }
}
