.column___11Axn {
    flex: 1;
}

.item___3-9Cw {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: -0.1px;
    color: #6b6f82;
    text-decoration: none;
}