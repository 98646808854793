.experiences___34xoG, .highlights___1MN4e {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 12 span;
}

.highlights___1MN4e {
    grid-template-columns: repeat(2, 1fr);
}

.swiper_experiences___26PgB {
    position: relative;
    grid-column: 12 span;
    margin-bottom: 16px;
    max-width: 100%;
}

.swiper___2p3Y7 {
    padding: 0 30px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1600px) {
    .experiences___34xoG {
        grid-template-columns: repeat(4, 1fr);
    }
    .highlights___1MN4e {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 991px) {
    .body-search .experiences___34xoG, .body-search .highlights___1MN4e {
        display: none!important;
    }
    .experiences___34xoG {
        grid-template-columns: repeat(1, 1fr);
    }
    .highlights___1MN4e {
        grid-template-columns: repeat(1, 1fr);
    }
    .swiper___2p3Y7 {
        padding: 0 24px;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .body-search .experiences___34xoG, .body-search .highlights___1MN4e {
        display: none!important;
    }
    .highlights___1MN4e, .experiences___34xoG {
        margin: 0 10px;
        grid-template-columns: repeat(1, 1fr);
    }
    .swiper___2p3Y7 {
        padding: 0 24px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .categoriesContainer___3I2VW {
        padding: 0 5px;
        box-sizing: border-box;
        margin: 0;
    }

    .swiper___2p3Y7 {
        padding: 0px;
        width: 100%;
    }
    .swiper_experiences___26PgB .slick-slider .slick-arrow {
        /*display: none !important;*/
    }

    .swiper_experiences___26PgB .slick-slider .slick-arrow.slick-prev {
        left: 5px
    }

    .swiper_experiences___26PgB .slick-slider .slick-arrow.slick-next {
        right: 5px;
    }
}
