.product___2rbCc{
  height: 395px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 16px;
  margin-bottom: 32px;
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;
  pointer-events: fill;
  display: flex;
  flex-direction: column;
}

.image___29vhu{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
}

.title___1OU0o {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #0f0f0f;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.description___1thIx {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #0f0f0f;
  max-height: 52px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.contentVariant___4y32E {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  flex-wrap: wrap;
}

.variant___1Y2fi{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 4px;
  margin-bottom: 4px;
}

.variant___1Y2fi.selected___2l02v{
  border: 1px solid #009DC5;
}

.contentPrice___V6w9A {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.compare___B5nqV{
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  text-decoration: line-through;
}

.price___leKY2{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.button___3P63y{
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 8px;
  display: block;
  text-align: center;
  color: #009DC5;
  border-radius: 16px;
  cursor: pointer;
  background: white;
  font-size: 12px;
}

@media (max-width: 480px) {
  .product___2rbCc{
    margin: 16px 8px;
  }
}
