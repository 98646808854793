.contentSwitch___8d32z{
  display: inline;
}

/* The switch - the box around the slider */
.switch___24jLu {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch___24jLu input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider___3rHf9 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
}

.slider___3rHf9:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: #009DC5;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider___3rHf9 {
  background-color: #009DC5;
}

input:focus + .slider___3rHf9 {
  box-shadow: 0 0 1px #009DC5;
}

input:checked + .slider___3rHf9:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #fff;
}

/* Rounded sliders */
.slider___3rHf9.round___2_he0 {
  border-radius: 24px;
}

.slider___3rHf9.round___2_he0:before {
  border-radius: 50%;
}
