/* Large Devices, Wide Screens */
.categories___2XR44{
  margin: 8px 69px;
  grid-column: 8 span;
  grid-column-start: 3;
  grid-column-end: 11;
}

.categories___2XR44 label{
  margin: 4px;
  background-color: white;
  color: #009DC5;
  border-color: #009DC5;
  border-width: 1px;
  border-style: solid;
  padding: 8px 16px;
  border-radius: 16px;
  display: inline-block;
  cursor: pointer;
}

.categories___2XR44 label.selected___2nFQ0{
  background-color: #009DC5;
  color: white;
}

@media only screen and (min-width: 1600px) {

}

@media only screen and (max-width: 1024px) {
  .categories___2XR44{
    margin: 8px 48px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .categories___2XR44{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 767px) {
  .categories___2XR44{
    margin: 8px;
  }
}
