.swiper_experiences___24CU2 {
  position: relative;
  grid-column: 12 span;
  margin-bottom: 16px;
  max-width: 100%;
}

.swiper___3Avaa .slick-slide {

}

.swiper___3Avaa {
  padding: 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
  .advertisingContainer___CeNs6{
    padding: 0 5px;
    box-sizing: border-box;
    margin: 0;
  }

  .swiper___3Avaa {
      padding: 0px;
      width: 100%;
  }
  .swiper_experiences___24CU2 .slick-slider .slick-arrow {
      /*display: none !important;*/
  }

  .swiper_experiences___24CU2 .slick-slider .slick-arrow.slick-prev {
    left: 5px
  }

  .swiper_experiences___24CU2 .slick-slider .slick-arrow.slick-next {
    right: 5px;
  }
}
