.cookieContainer___PY0-A {
  bottom: 0 !important;
}

.divCookieContainer___2tRpE{
  display: flex;
  flex-direction: row;
}

.imageCookieContainer___1eN5E {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageCookieContainer___1eN5E img {
  max-width: 100px;
  justify-content: center;
}

.textCookieContainer___h10qc {
  padding: 20px;
  box-sizing: border-box;
}

.textCookieContainer___h10qc h1 {
  color: #009DC5;
}

.textCookieContainer___h10qc p,
.textCookieContainer___h10qc p a {
  font-size: 13px;
  line-height: 1.1 !important;
}

.cookieIcon___bWmfk {
  font-size: 16px;
  margin: 4px 8px 4px 0;
}

.cookieButton___1N80S {
  background: #009DC5 !important;
  color: #fff !important;
  margin-left: 8px !important;
  padding: 12px 16px !important;
  flex: none !important;
  border-radius: 6px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase !important;
}

.contentChecks___v_HNf {
  display: flex;
  margin-top: 24px;
}

.nocheck___3U2oV{
  color: white;
  border: none;
  width: 25%;
  height: 30px;
  border-radius: 16px;
  margin: 0 8px;
  box-sizing: border-box;
  position: relative;
  background: transparent;
  border: 1px solid #009DC5;
  padding-left: 30px;
  text-align: left;
}

.check___2eyAO{
  color: white;
  border: none;
  width: 25%;
  height: 30px;
  border-radius: 16px;
  margin: 0 8px;
  box-sizing: border-box;
  position: relative;
  background: transparent;
  border: 1px solid #009DC5;
  padding-left: 30px;
  text-align: left;
}

.nocheck___3U2oV{
  cursor: default;
}

.active___1J4e9 {
  background: #009DC5;
  color: white !important;
}

.active___1J4e9::after {
  content: '\2713';
  color: white;
  position: absolute;
  top: -3px;
  left: 8px;
  font-size: 30px;
}

@media only screen and (max-width: 991px) {
  .cookieContainer___PY0-A {
    bottom: 72px !important;
    border-radius: 8px;
    margin: 0px -8px 0 8px;
    background-color: #fff !important;
    width: calc(100vw - 50px) !important;
    padding: 16px;
    box-shadow: 0 26px 16px 32px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .divCookieContainer___2tRpE{
    display: flex;
    flex-direction: column;
  }
  .textCookieContainer___h10qc {
    padding: 20px 0 0;
  }
  .cookieContent___10fg9 {
    margin: 0 !important;
  }
  .cookieContainer___PY0-A > div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .cookieContainer___PY0-A > div:first-child {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: black !important;
  }
  .cookieContainer___PY0-A > div:last-child {
    margin-bottom: 0 !important;
  }
  .cookieButton___1N80S {
    display: block !important;
    margin: 0 !important;
    margin-top: 8px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contentChecks___v_HNf {
    display: grid;
    grid: 1fr / repeat(2, 1fr);
    grid-gap: 8px;
  }
  .nocheck___3U2oV,
  .check___2eyAO{
    width: 100%;
    margin: 0;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .contentChecks___v_HNf {
    display: grid;
    grid: 1fr / repeat(2, 1fr);
    grid-gap: 8px;
    margin-top: 16px;
  }
  .nocheck___3U2oV,
  .check___2eyAO{
    width: 100%;
    margin: 0;
    color: black;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .imageCookieContainer___1eN5E img {
    max-width: 60px;
  }
  .contentChecks___v_HNf {
    flex-direction: column;
    margin-top: 8px;
  }
  .nocheck___3U2oV,
  .check___2eyAO{
    width: 100%;
    margin: 4px 0;
    color: black;
  }
}
