#container___1u3FZ {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  margin-bottom: 16px;
}

.ticketsContainer___tkjD8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: -webkit-sticky;
  top: 181px;
  z-index: 200;
  background-color: #edeef4;
  padding: 16px;
  box-sizing: border-box;
}

.ticketsContainer___tkjD8 > button {
  margin: 0 8px;
}

.title___19wup {
  background-color: rgb(186, 187, 187);
  color: #000;
  display: block !important;
  cursor: pointer;
  background-image: url("/static/images/chevron-bottom.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
}
.collapsed___27U9T {
  background-image: url("/static/images/chevron-top.svg");
}
.contentSelectedDayData___xEIpM{
  width: 100%;
}
.dateRow___1sj76 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: 0px 8px;
  margin-bottom: 32px;
  margin-top: 32px;
}
.dateRow___1sj76 > span {
  margin-bottom: 16px;
}

.options___1yDp1 {
  display: flex;
  flex-wrap: wrap;
}

.option___1gD4X {
  display: block;
  border-radius: 12px;
  padding: 8px 24px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  text-decoration: none;
  margin-right: 8px;
  margin-bottom: 8px;
}

.selectedOption___1_SMx {
  color: #fff;
  background-color: #009DC5;
}

.row___EjJOz {
  padding: 8px 16px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.ticketBody___1HLKx {
  background-color: white;
}
.ticketLabel___l8HYK {
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: space-between;
}
.ticketLabel___l8HYK span{
  text-transform: uppercase;
  max-width: 80%;
  display: inline-block;
}
.ticketAmount___1nOHP {
  margin: 0 8px;
}
.ticketButton___23Nap {
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  border: 1px solid #009DC5;
  color: #009DC5;
}
.cta___3eS2q {
  margin: 0 8px;
  display: flex;
  flex: 1;
  text-align: center;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: calc(100% - 16px);
}

.dates___3Z5AO {
  flex: 2;
  display: flex;
  flex-direction: row !important;
}

.option___1gD4X {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column !important;
  min-width: 0px !important;
  padding: 12px 12px !important;
  height: auto !important;
}
.option___1gD4X:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.optionLabel___CPCOQ {
  color: black;
  font-weight: 600;
}
.optionValue___1zC-W {
  color: rgba(0, 0, 0, 0.64);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}

.modalDatePicker___1RF5V {
  min-height: 400px !important;
}

.modalDatePickerContainer___OUjaa {
  max-height: none !important;
  overflow: hidden !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .ticketsContainer___tkjD8 > button {
    display: block;
    align-self: stretch;
  }
}
