.sectionContent___1Ftqa {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column: 12 span;
    margin: 40px 10px 0;
    position: relative;
}

.header___Yf6w0 {
    flex: 1;
    position: relative;
}

.header___Yf6w0 span {
    position: absolute;
    display: flex;
    top: 0;
    left: -90px;
    width: calc(100% + 180px);
    background: #edeef4;
    align-items: center;
    box-sizing: border-box;
}

.header___Yf6w0 span h2{
    line-height: 1.14;
    letter-spacing: -0.2px;
    font-size: 18px;
    color: #009DC5;
    padding: 8px 90px;
    display: block;
}

.header___Yf6w0 h3 {
    font-size: 15px;
    line-height: 1.19;
    letter-spacing: -0.2px;
    color: #333642;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-top: 68px;
    margin-bottom: 20px;
}

@media (max-width: 1024px) and (min-width: 768px){
    .header___Yf6w0 span {
        left: -19%;
        width: 138%;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .header___Yf6w0 span {
        left: -10px;
        width: calc(100% + 20px);
        justify-content: center;
    }

    .header___Yf6w0 span h2{
        font-size: 16px;
        padding: 8px 20px;
    }

    .header___Yf6w0 h3 {
        font-size: 13px;
        margin-top: 58px;
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 480px) {
    .header___Yf6w0 span h2{
        font-size: 15px;
        padding: 8px 20px;
    }

    .header___Yf6w0 h3 {
        font-size: 12px;
        margin-top: 54px;
        margin-bottom: 10px;
    }
}
