.trips___2ZD1V {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 12 span;
  margin: 20px;
}

.button___3gFIU {
  height: 42px;
  width: 100%;
  background-color: #009DC5;;
  border: 0;
  color: white;
  text-transform: uppercase;
  display: block;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  grid-column: 2 span;
  position: relative;
  grid-column-start: 6;
  grid-column-end: 8;
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1600px) {
  .trips___2ZD1V {
      grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .trips___2ZD1V {
      grid-template-columns: repeat(3, 1fr);
      margin: 15px;
  }
  .button___3gFIU {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

@media only screen and (max-width: 991px) {
  .body-search .trips___2ZD1V {
      display: none!important;
  }
  .trips___2ZD1V {
    margin: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 64px;
  }
  .button___3gFIU {
    position: sticky;
    bottom: 64px;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}
