.container___AobNk {
  margin: 50px 69px;
  grid-column: 8 span;
  grid-column-start: 3;
  grid-column-end: 11;
  padding-top: 80px;
}

.title___3_N4M {
  position: relative;
  padding: 8px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  display: inline-block;
  font-size: 34px;
}

.title___3_N4M::after{
  content: " ";
  width: 100px;
  height: 4px;
  background-color: #009DC5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.contentRegister___3mSKN{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 2vw 0;
  font-size: 20px;
}

.register___2vGfa{
  background-color: transparent;
  color: #009DC5;
  font-family: Montserrat;
  font-size: 22px;
  border: none;
}

.rrss___3d6Kx{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 2vw 0;
}

.facebook___RCZup {
  width: 200px;
  height: 44px;
  background-color: #3b5998;
  border-radius: 22px;
  border: 1px solid #3b5998;
  color: white;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  padding-left: 24px;
}

.facebook___RCZup::before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  color: white;
  position: absolute;
  top: 10px;
  left: 8px;
  -webkit-mask-image: url(/static/images/facebook_line.svg);
  mask-image: url(/static/images/facebook_line.svg);
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  background-color: white;
}

.contentAppleLogin___1xAF7 {
  width: 100%;
  height: 44px;
  background-color: #000;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
}

.contentAppleLogin___1xAF7 div{
  width: 100%;
  text-align: center;
}

.rrss___3d6Kx .facebook___RCZup,
.rrss___3d6Kx .contentAppleLogin___1xAF7{
  max-width: 200px;
}

.input___NvdqV {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 10px;
}

.input___NvdqV:focus {
  border-color: #009DC5
}

.input_error___39ENw {
  border-color: red;
}

.button___Awxo8 {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

.separator___3fdb0 {
  height: 1px;
  width: 100%;
  background-color: #c0c4d2;
  margin: 36px 0;
}

.forgot___2LFqw {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333642;
  margin: 16px 0;
  display: block;
}

.cms___rwmYf {
  width: 100%;
  background-color: #FFF;
  border-radius: 22px;
  border: 2px solid #009DC5;
  color: #009DC5;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
  font-size: 12px;
  font-weight: 600;
  display: block;
  text-decoration: none;
  text-align: center;
  padding-top: 8px;
  height: 32px;
}

.other_options___2fr74 {
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: block;
  color: #333642;
}

.facebook___RCZup b {
  font-size: inherit;
}

.close___3Mm2_ {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  background: url('/static/images/close.svg');
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -6000px;
}

@media only screen and (max-width: 1024px) {
  .container___AobNk{
    margin: 8px 69px;
    grid-column: 10 span;
    grid-column-start: 1;
    grid-column-end: 11;
  }
}

@media only screen and (max-width: 991px) {
  .container___AobNk{
    margin: 8px 48px;
    grid-column: 12 span;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}

@media only screen and (max-width: 767px) {
  .container___AobNk{
    margin: 8px 8px;
  }
}

@media only screen and (max-width: 448px) {
  .container___AobNk{
    margin: 8px 8px;
  }
  .rrss___3d6Kx{
    flex-direction: column;
    align-items: center;
    height: 100px;
  }
}
