.heroContainer___1VnmK {
    grid-row-gap: 10px;
    row-gap: 10px;
    padding: 90px 0;
}

.heroContainer___1VnmK .titles___2VbX1, .inputsRow___1RAXs {
    grid-column: span 11;
    grid-column-start: 2;
    grid-column-end: 12;
}

.hero___1-euR {
    background: #6b6f82;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
}

.hero___1-euR h1, .hero___1-euR p {
    font-family: Montserrat;
    font-size: 64px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1em !important;
    letter-spacing: normal;
    color: #FFF;
    margin-bottom: 0;
    overflow-wrap: break-word;
}

.hero___1-euR h1>h1:last-child, .hero___1-euR h1>p:last-child {
    margin-bottom: 20px !important;
}

.hero___1-euR h2 {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #FFF;
    margin-top: 10px;
    margin-bottom: 20px;
}

.search___2eJjW, .typeOfEvent___SG_b3, .rangeDates___Qs0ix, .perfectFor___3Gda_, .goto___3rnti {
    grid-column: span 2;
    grid-row: 2;
    position: relative;
}

.search___2eJjW, .typeOfEvent___SG_b3, .rangeDates___Qs0ix, .perfectFor___3Gda_ {
    height: 44px;
    display: flex;
}

.typeOfEvent___SG_b3 {
    grid-column: 3 span;
}

.destinations___j8z6O {
    grid-column: span 2;
    grid-column-start: 2;
    grid-column-end: 4;
}

.rangeDates___Qs0ix {
    grid-column: span 3;
    justify-content: space-evenly;
    position: relative;
    border: solid 1px rgba(0, 0, 0, .2);
    background-color: #ffffff;
    border-radius: 4px;
}

.DateInputFocused___1t_9X{
    border-color: #009DC5 !important;
}

.rangePicker___2IRGs {
    display: flex;
    border: none;
    background: none;
}

.rangePicker___2IRGs div {
    display: flex;
}

.perfectFor___3Gda_ {
    margin-right: 0;
}

.goto___3rnti {
    grid-column: span 1;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 2.29;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    background-color: #009DC5;
    text-decoration: none;
    text-align: center;
}

.goto___3rnti span {
    text-align: center;
    color: #ffffff;
    width: 100%;
}

.container___2DMMT {
    margin: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.calendar___2_TPK .container___2DMMT {
    max-width: 517px;
    color: #FFF;
}

.container___2DMMT>span {
    font-size: 16px;
    font-weight: 300;
    color: #edeef4;
    display: flex;
    text-align: center;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
}

.badge___33AZf {
    display: flex;
    font-size: 16px;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    border: solid 1px #009DC5;
    background-color: #009DC5;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
}

.button___3a3pW {
    background-color: #009DC5;
    text-decoration: none;
    text-transform: uppercase;
    padding: 16px 8px;
    display: block;
    text-align: center;
    color: #FFF;
    border-radius: 32px;
    position: relative;
    bottom: -56px;
    cursor: pointer;
}

.calendar___2_TPK {
    max-width: 620px;
    box-shadow: 0 2px 6px 0 rgba(69, 73, 91, 0.08);
    background-color: rgba(51, 54, 66, 0.9);
    border-radius: 6px;
    display: flex;
    grid-column: 12 span;
    margin: 91px 10px;
}

.calendar___2_TPK .destinations___j8z6O, .calendar___2_TPK .rangeDates___Qs0ix, .calendar___2_TPK .perfectFor___3Gda_ {
    margin-bottom: 10px;
}

.dates___19X7T {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dates___19X7T>span {
    /* color: #edeef4; */
    display: block;
    text-align: center;
    font-size: 22px;
    text-transform: capitalize;
    line-height: 45px!important
}

.dates___19X7T>.day___KPT8l {
    font-size: 64px;
}

.details___11zZE {
    grid-column: 12 span;
    text-align: center;
    padding: 32px;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.60);
    margin: 41px 10px;
}

.details___11zZE .button___3a3pW {
    display: inline-block;
    align-self: center;
    padding: 16px 32px;
}

.toggleContainer___3ChWy {
    flex: 1;
    position: relative;
    display: flex;
}

.toggleButton___257nR {
    flex: 1;
    background-color: #FFF;
    border: 0;
    text-align: left;
    margin-right: 0;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(0, 0, 0, .2);
    background-color: #ffffff;
}

.toggleButton___257nR:focus{
    border: 1px solid #009DC5;
}

.toggleButton___257nR::after{
    content: '';
    -webkit-mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
    mask: url(/static/icons/chevron-bottom.svg) no-repeat 100% 50%;
    -webkit-mask-size: 11px 11px;
    mask-size: cover;
    display: inline-flex;
    background-color: #009DC5;
    width: 11px;
    height: 11px;
    position: absolute;
    right: 4px;
    top: 17px;
}

.toggleButton___257nR span {
    color: rgba(42, 43, 51, 0.801);
    font-size: 13px;
}

.show_options___1USFh {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background-color: #FFF;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
    border: solid 1px rgba(0, 0, 0, .2);
    border-radius: 4px;
    z-index: 2000;
}

.destinationItem___1GAC5 {
    background: #FFF;
    border: 0;
    text-align: left;
    padding: 8px 12px;
    display: block;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
}

.select___39vYx {
    display: block;
    margin: 8px 16px;
}

.destinationItem___1GAC5:hover {
    background: #eaeaea;
}

.destinationItem___1GAC5>span {
    display: block;
}

.toggleContainer___3ChWy>.show_options___1USFh.places___2x1Rx>div:first-child {
    margin-top: 16px;
}

.destinationTitle___3eqz7 {
    margin: 0 8px;
    display: flex;
}

.CalendarDay__selected_span___sMuzB {
    background: #82e0aa;
    color: white;
    border: 1px solid rosybrown;
}

.CalendarDay__selected___1wzfM {
    background: red;
    color: white;
}

.CalendarDay__selected___1wzfM:hover {
    background: orange;
    color: white;
}

.CalendarDay__hovered_span___ybT2n:hover, .CalendarDay__hovered_span___ybT2n {
    background: brown;
}

.inputsRow___1RAXs {
    display: flex;
    position: relative;
}

.inputsRow___1RAXs .columnOne___3nODj {
    width: 100%;
    display: flex;
}

.inputsRow___1RAXs .columnTwo___iRtgQ {
    width: 100%;
    display: flex;
}

.separator___1FTtw {
    border-top: 1px solid rgba(0, 0, 0, .2);
}

.toLeft___3-nno {
    justify-content: flex-start;
}


.scroll___2WVkD {
    overflow-y: scroll;
    max-height: 300px;
}

.toggleItem___3yFpg {
    background: #FFF;
    border: 0;
    text-align: left;
    padding: 8px 16px;
    display: block;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, .54);
}

.toggleItem___3yFpg:hover {
    background: #eaeaea;
}

.toggleItem___3yFpg>span {
    display: block;
}

.toggleContainer___3ChWy>.options___p2Wxg.places___2x1Rx>div:first-child {
    margin-top: 16px;
}

.toggleContainer___3ChWy {
    flex: 1;
    position: relative;
    display: flex;
}

.toggleTitle___1XKaV {
    margin: 0 8px;
    display: flex;
}

.toggle___3STSQ {
    display: flex;
}

.DateInput_input {
    padding: 10px 0 10px 6px;
}

@media only screen and (max-width: 1440px) {
    .toggleButton___257nR span {
        font-size: 0.9vw;
    }
}

@media (max-width: 1024px) {
    .hero___1-euR h1, .hero___1-euR p {
        font-size: calc(1em + 2vw)
    }
    .day___KPT8l {
        line-height: 1em !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .toggleButton___257nR span {
        font-size: 16px;
    }
    .heroContainer___1VnmK .titles___2VbX1, .inputsRow___1RAXs {
        grid-column: span 5;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .search___2eJjW, .typeOfEvent___SG_b3 {
        grid-column: span 2;
        grid-row: 2;
    }
    .rangeDates___Qs0ix {
        grid-column: span 4;
        grid-row: 3;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .perfectFor___3Gda_ {
        grid-column: span 4;
        grid-row: 4;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .destinations___j8z6O {
        grid-column: span 2;
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .goto___3rnti {
        grid-column: span 4;
        grid-row: 5;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .calendar___2_TPK {
        grid-column: span 4;
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .calendar___2_TPK .container___2DMMT {
        max-width: initial;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .calendar___2_TPK {
        grid-column: 2 span;
        margin: 0px 10px;
    }
    .toggleButton___257nR span {
        font-size: 13px;
    }
    .hero___1-euR {
        padding: 20px 0;
    }
    .categoryHero___1j-T0 {
        display: none!important;
    }
    .heroContainer___1VnmK {
        margin: 0 10px;
        padding: 0;
    }
    .heroContainer___1VnmK .titles___2VbX1, .inputsRow___1RAXs {
        grid-column: span 2;
    }
    .search___2eJjW {
        grid-column: span 2;
        grid-row: 2;
    }
    .typeOfEvent___SG_b3 {
        grid-column: span 2;
        grid-row: 4;
    }
    .rangeDates___Qs0ix {
        grid-column: span 2;
        grid-row: 3;
    }
    .perfectFor___3Gda_ {
        grid-column: span 2;
        grid-row: 5;
    }
    .destinations___j8z6O {
        grid-column: span 2;
    }
    .goto___3rnti {
        grid-column: span 2;
        grid-row: 6;
    }
    .DateRangePickerInput_arrow {
        display: none;
    }
}
