.text___2C-KM,
.text___2C-KM p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  text-align: justify;
  color: #2f2f2f;
}

.tab___1OLRu,
.tabColumn___ngS1A {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #2f2f2f;
  padding: 0 0 8px;
}

.tabColumn___ngS1A {
  flex-direction: column;
}

.galleryContainer___3gi5l {
  margin: 32px 0;
}
.gallery___1YCZr {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.seemap___1qh3R {
  grid-column: span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.seemap___1qh3R .button___1ADq5 {
  padding: 8px 16px;
  width: 100%;
  background: white;
  border: 1px solid #009DC5;
  color: #009DC5;
}

.gallery___1YCZr::-webkit-scrollbar {
  height: 6px;
  background-color: #e4e5ec;
}

.gallery___1YCZr::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #009DC5;
}

.mainItem___3hR2F {
  width: 100%;
  height: 400px;
  padding: 0;
  margin: 0;
  display: block;
  background-position: center;
  background-size: cover;
}

.gallery___1YCZr a,
.galleryItem___2O-38 {
  width: 200px;
  height: 200px;
  display: inline-block;
}

.galleryItem___2O-38 {
  background-position: center;
  background-size: cover;
}

.galleryItem___2O-38:hover {
  opacity: 0.6;
}

.tabName___20p-e {
  flex: 1;
  margin-right: 16px;
  color: #6b6f82;
}

.tabName___20p-e a {
  color: #6b6f82;
  text-decoration: none;
}

.tabName___20p-e.access_title___Qw4Fs {
  text-transform: capitalize;
  font-weight: 500;
  color: black;
}

.table___2qCzZ {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 16px;
}

.row___-tuFN {
  display: flex;
  flex: 1;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  padding: 16px 0;
}

.table___2qCzZ .row___-tuFN > div:first-child {
  flex: 1;
  border-right: 1px dotted rgba(0, 0, 0, 0.12);
  margin-right: 16px;
}
.table___2qCzZ .row___-tuFN > div:last-child {
  flex: 2;
}

.categories___1quJ4 {
  display: flex;
  flex-wrap: wrap;
}
.category___3JCso {
  background-color: #009DC5;
  padding: 4px 16px;
  color: #fff;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8 px;
  font-size: 14px;
}

.shareIcon___1U_5A {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #009DC5;
  margin-right: 16px;
  text-indent: -6000px;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.twitter___26RNK {
  background-image: url("/static/icons/twitter.svg");
}

.facebook___3q8KB {
  background-image: url("/static/icons/facebook.svg");
}

.whatsapp___c9pr9 {
  background-image: url("/static/icons/whatsapp.svg");
}

.instagram___2N8J0 {
  background-image: url("/static/icons/instagram.svg");
}

.youtube___3Utc4 {
  background-image: url("/static/icons/youtube.svg");
}

.socialIcon___NoE0B {
  width: 22px;
  height: 22px;
  margin-top: 8px;
  margin-right: 8px;
  text-indent: -6000px;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  position: relative;
}

.socialIcon___NoE0B::after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  background: #009DC5;
  top: 0;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
}

.socialIcon___NoE0B.twitter___26RNK::after {
  -webkit-mask-image: url("/static/icons/twitter.svg");
  mask-image: url("/static/icons/twitter.svg");
}

.socialIcon___NoE0B.facebook___3q8KB::after {
  -webkit-mask-image: url("/static/icons/facebook.svg");
  mask-image: url("/static/icons/facebook.svg");
}

.socialIcon___NoE0B.whatsapp___c9pr9::after {
  -webkit-mask-image: url("/static/icons/whatsapp.svg");
  mask-image: url("/static/icons/whatsapp.svg");
}

.socialIcon___NoE0B.instagram___2N8J0::after {
  -webkit-mask-image: url("/static/icons/instagram.svg");
  mask-image: url("/static/icons/instagram.svg");
}

.socialIcon___NoE0B.youtube___3Utc4::after {
  -webkit-mask-image: url("/static/icons/youtube.svg");
  mask-image: url("/static/icons/youtube.svg");
}

.button___1ADq5 {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  cursor: pointer;
}

.buttonAlt___vVAWi {
  background-color: #fff;
  border: 1px solid #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #009DC5;
  border-radius: 32px;
}

.tag___11h-G {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: center;
  color: #009DC5;
  border: 1px solid #009DC5;
  margin-right: 16px;
  padding: 4px 16px;
  margin-bottom: 16px;
  display: inline-block;
}

.adultsOnly___POYMC {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
  z-index: 2;
}

.adultsOnlyBtnMore18___1vFl2 {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: absolute;
  bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 55px;
  min-width: 200px;
}

.adultsOnlyBtnExit___1b-Vg {
  background-color: #009DC5;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 8px;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 32px;
  position: absolute;
  bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 55px;
  min-width: 200px;
}

.adultsOnlyBtnMore18___1vFl2 {
  bottom: 70px;
}

.adultsOnlyBtnExit___1b-Vg {
  top: 70px;
}

.gallery-viewer {
  overflow: hidden;
}

.modalGallery___3D4S8 {
  background: #fffd;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3000;
  display: flex;
  flex-direction: column;
}

.navbar___386xG {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.icon___24FhZ {
  background-color: transparent;
  border: 0;
  font-family: Montserrat;
  background-repeat: no-repeat;
  background-position: center;
}

.close___2fZqK {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-image: url("/static/icons/close.svg");
  margin: 16px;
}

.close___2fZqK:focus {
  outline: none;
  border: 1px solid rgba(196, 50, 190, 0.6);
  -webkit-box-shadow: 0px 0px 5px #009DC5;
  box-shadow: 0px 0px 5px #009DC5;
}

.modalContent___18zBR {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  position: relative;
}

.imageContainer___3WVeq {
  flex: 1;
  grid-column: 12 span;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbContainer___1J9G4 {
  max-height: 75vh;
  cursor: pointer;
  border-radius: 16px;
  max-width: 85%;
  margin: 0px auto;
  overflow: hidden;
}

.imageContainer___3WVeq img {
  max-height: 90vh;
  object-fit: cover;
  max-width: 100%;
  margin: 0px auto;
}

.thumbnailsContainer___5-wgf {
  position: absolute;
  z-index: 200;
  pointer-events: visiblePainted;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  left: 0;
  right: 0;
}

.thumbnails___1lMZe {
  background-color: #fff;
  border-radius: 8px;
}

.thumbnail___11JQ- {
  padding: 0;
  margin: 10px;
  border-radius: 6px;
  overflow: hidden;
  border: 3px solid #fff;
  box-sizing: content-box;
}

.selected___hWPdu.thumbnail___11JQ- {
  border-color: #009DC5;
}

.thumbContainer___1J9G4 {
  position: relative;
}

.thumbnail___11JQ-,
.thumbnail___11JQ- img {
  width: 40px;
  height: 40px;
}

.badge___hLImC,
.title___Zfx6P {
  position: absolute;
}

.badge___hLImC {
  right: 16px;
  top: 16px;
  display: block;
  background: #fff;
  border-radius: 16px;
  padding: 0 16px;
}
.pageTitle___3eg5M {
  font-size: 32px;
  margin-top: 16px;
}
.title___Zfx6P {
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  padding: 16px;
}

.arrow___JhgQm {
  position: absolute;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.arrowLeft___3m97p {
  left: 0;
}

.iconLeft___BizzD,
.iconRight___3zUFc {
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.54);
}

.iconLeft___BizzD {
  background-image: url("/static/icons/arrow-back.svg");
}

.iconRight___3zUFc {
  background-image: url("/static/icons/arrow-forward.svg");
}

.arrowRight___2D8XM {
  right: 0;
}

.contentNotLooses___O3EoF {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 12 span;
  margin: 0 10px;
}

.notLooses___2YNiA {
  display: flex;
  margin-bottom: 25px;
}

.notLoosesImg___3pxy9 {
  width: 20%;
}

.notLoosesImg___3pxy9:hover {
  opacity: 0.6;
}

.notLoosesData___QCnTT {
  width: 80%;
}

.notLoosesDataTitle____O8Kn {
  display: flex;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: #2f2f2f;
  padding: 0 20px 5px;
}

.notLoosesDataDescription___3Pkc2 {
  display: flex;
  padding: 0 20px;
}

.notLoosesDataDescription___3Pkc2 div {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.9 !important;
  letter-spacing: normal;
  color: #2f2f2f;
}

.notLooseContainer___1Xtbq {
  flex: 1;
  grid-column: 12 span;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notLooseSticker___2ityK {
  max-height: 75vh;
  cursor: pointer;
  border-radius: 16px;
  max-width: 80%;
  margin: 0px auto;
  overflow: hidden;
  position: relative;
}

.image___5uYpg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 50vh;
  position: relative;
}

.infos___HS4ap {
  background-color: rgba(51, 54, 66, 1);
  padding: 10px 24px;
  height: 25vh;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: #edeef4;
  box-sizing: border-box;
}

.infos___HS4ap h4 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22 !important;
  letter-spacing: -0.14px;
  color: #edeef4;
  position: relative;
  width: 100%;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.infos___HS4ap > div {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
}

.infos___HS4ap .description___2HxgD {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #edeef4;
  max-height: 20vh;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
}

.notContentShopping___10UNZ{
  grid-column: span 12 !important;
  max-width: 100%;
}

.contentVerticalTickets___3LkZQ {
  flex-direction: row !important;
  align-items: flex-start !important;
}

@media (max-width: 1023px) {
  .modalContent___18zBR {
    overflow-y: hidden;
  }
  .thumbnailsContainer___5-wgf {
    left: 0;
    right: 0;
    bottom: 0;
    top: initial;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .shoppingBar___3ArVk {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #shoppingBar___3ArVk {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .thumbContainer___1J9G4 {
    max-width: 90%;
  }

  .notLooses___2YNiA {
    flex-direction: column;
  }

  .notLoosesImg___3pxy9 {
    width: 100%;
  }

  .notLoosesData___QCnTT {
    width: 100%;
  }

  .notLoosesDataTitle____O8Kn {
    font-size: 18px;
    padding: 20px 0px 5px;
  }

  .notLoosesDataDescription___3Pkc2 {
    display: flex;
    padding: 0;
  }

  .notLoosesDataDescription___3Pkc2 div {
    font-size: 14px;
  }

  .notLooseSticker___2ityK {
    max-width: 90%;
  }

  .image___5uYpg {
    height: 25vh;
  }

  .infos___HS4ap {
    padding: 16px;
    height: auto;
  }
  .infos___HS4ap h4 {
    font-size: 15px;
    width: 100%;
    max-height: 66px;
    padding-bottom: 12px;
  }
  .infos___HS4ap .description___2HxgD {
    font-size: 10px;
    max-height: inherit;
    -webkit-line-clamp: inherit;
  }
  #shoppingBar___3ArVk {
    display: none;
  }
  .contentVerticalTickets___3LkZQ {
    flex-direction: column !important;
  }
}
