.checkoutContent___12sXu{
  background-color: rgba(237, 238, 244, 0.35);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 44px 57px;
  box-sizing: border-box;
}

.checkoutContentForm___3YVAm{
  width: 65%;
  padding: 0 33px;
  box-sizing: border-box;
}

.checkoutContentForm___3YVAm label {
  width: 100%;
  height: 28px;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #333642;
}

.checkoutContentFormRow___2ubad{
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.input___1r6XE,
.inputSmall___34YT9{
  height: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e4e5ec;
  background-color: #ffffff !important;
  margin: 10px;
  padding: 0px 15px;
  box-sizing: border-box;
}

.input___1r6XE {
  width: 50%;
}

.inputSmall___34YT9{
  width: 25%;
}

.checkoutContentProducts___2UmdK{
  width: 35%;
  padding: 0 33px;
  box-sizing: border-box;
}

.checkoutContentProductsRow___2QOfm{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkoutContentProducts___2UmdK label {
  height: 28px;
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #333642;
}

.checkoutContentProductsDetail___3qmB2{
  padding: 12px 0;
  position: relative;
}

.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailName___3x8t_{
  height: auto;
  min-height: 30px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #2f2f2f;
  margin-bottom: 27px;
  padding-left: 33px;
}

.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailName___3x8t_:before{
  content: '';
  background-image: url('/static/images/invoice.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 19px;
}

.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailPrice___2kDKG{
  background-color: #009DC5;
  height: 22px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 68px;
  margin-top: 5px;
}

.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailKey___WC7G9{
  padding-left: 33px;
}

.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailKey___WC7G9,
.checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailValue___3YZ4f {
  height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #6b6f82;
  margin-bottom: 5px;
}

.checkoutContentProductsLine___1vpz5{
  width: 100%;
  height: 4px;
  background-color: #009DC5;
  margin: 18px 0;
}

.button___1Hycx {
  width: 100%;
  height: 44px;
  background-color: #009DC5;
  border-radius: 22px;
  border: 0;
  color: #FFFFFF;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-top: 21px;
}

@media only screen and (max-width: 1024px) {
  .checkoutContent___12sXu{
    padding: 22px 30px;
  }

  .checkoutContentForm___3YVAm,
  .checkoutContentProducts___2UmdK{
    width: 50%;
    padding: 0 20px;
  }
  .checkoutContentFormRow___2ubad{
    flex-direction: column;
  }
  .input___1r6XE,
  .inputSmall___34YT9{
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .checkoutContent___12sXu{
    flex-direction: column;
    padding: 12px;
  }
  .checkoutContentForm___3YVAm,
  .checkoutContentProducts___2UmdK{
    width: 100%;
    padding: 0 12px;
  }

  .checkoutContentProducts___2UmdK{
    margin-top: 20px;
  }

  .input___1r6XE,
  .inputSmall___34YT9{
    margin: 8px 0;
  }

  .checkoutContentProductsDetail___3qmB2 .checkoutContentProductsDetailName___3x8t_ {
    margin-bottom: 12px;
  }
}
