.content___1Pp3C {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: calc(100% - 245px);
}

.center___2AfVe {
  max-width: 300px;
  text-align: center;
  align-self: center;
}

.image___36Wtg {
  background-image: url('/static/images/empty-trips.svg');
  display: block;
  width: auto;
  height: 106px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.title___3ng9s {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.description___272lG {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #00000070;
  margin-top: 8px;
  margin-bottom: 24px;
  line-height: 1 !important;
}

.button___2eqrJ {
  border-radius: 6px;
  background-color: #009DC5;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: white;
  padding: 11px 26px;
  border: 0;
  text-decoration: none;
}
