.ads___2M1d_ {
  background-color: #e7e7e7;
  height: 380px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
  grid-column: 12 span;
  border-radius: 6px;
  margin-top: 30px;
  cursor: pointer;
  width: 100%;
}

.infos___1Csng {
  width: 360px;
  right: 0;
  top: 0;
  position: absolute;
  bottom: 0;
  background-color: rgba(51, 54, 66, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px;
}

.infos___1Csng > div {
  margin: 0;
}

.infos___1Csng span {
  font-family: "Montserrat", sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58 !important;
  letter-spacing: -0.13px;
  color: #edeef4;
  display: inline-block;
  margin: 0;
}

.infos___1Csng p {
  color: #edeef4;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57 !important;
  letter-spacing: -0.09px;
  color: #edeef4;
  display: block;
}

.infos___1Csng p.mobileTime___2pMXv{
  display: none;
}


.description___1Lzli {
  margin-top: 2px !important;
  margin-bottom: 16px !important;
}

.heart___3IRKZ {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  margin-left: 16px;
  background-image: url("/static/icons/love_checked.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.address___1q334,
.time___26gnE {
  display: inline-block;
  padding-top: 0;
}

.time___26gnE {
  padding-left: 0;
}

.mobileTime___2pMXv::before,
.address___1q334::before,
.time___26gnE::before {
  content: " ";
  display: inline-block;
  height: 19px;
  width: 19px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 4px;
  top: 3px;
  position: relative;
}

.address___1q334::before {
  background-image: url("/static/icons/map_white.svg");
}

.time___26gnE::before,
.mobileTime___2pMXv::before {
  background-image: url("/static/icons/calendar-white.svg");
}

.accent___2nNPg {
  border-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiNjNDMyYmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjYzQzMmJlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik0xMS41IDIwLjVzLTktNS05LTEyYzAtMiAxLTUgNC01czUgMi4yNSA1IDIuMjUgMi0yLjI1IDUtMi4yNSA0IDMgNCA1YzAgNy05IDEyLTkgMTJ6Ii8+Cjwvc3ZnPgo=");
}

.commands___1vpSX {
  margin-top: 25px !important;
  display: flex;
  justify-content: space-between;
}

.commands___1vpSX a {
  padding: 0px 14px !important;
  border-radius: 100px;
  border: solid 1px #009DC5;
  background-color: #ffffff;
  color: #009DC5 !important;
  display: inline-block;
  text-decoration: none;
  align-items: center;
  line-height: 2.67;
  font-size: 14px !important;
  text-transform: initial !important;
  font-size: 12px !important;
  height: 28px !important;
  margin-bottom: 0 !important;
  margin-top: 20px;
}

.goto___32o6T span {
  font-size: 12px;
  line-height: 2.67;
  text-align: center;
  color: #009DC5;
}

.infos___1Csng a {
  color: #009DC5;
  padding-right: 0;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67 !important;
  letter-spacing: normal;
  text-align: center;
  margin-right: 8px;
  padding: 0 30px;
  text-decoration: none;
  border-radius: 100px;
  border: solid 1px #009DC5;
  background: white;
  display: inline-block;
  margin-bottom: 30px;
  height: 30px;
}

.mobileImage___1x_FT,
.mobileTime___2pMXv {
  display: none;
}

@media only screen and (max-width: 991px) {
  .body-search .ads___2M1d_ {
    display: none !important;
  }
  .ads___2M1d_ {
    height: 420px;
  }
  .commands___1vpSX {
    position: initial !important;
    margin-top: 0 !important;
  }
  .infos___1Csng {
    width: auto !important;
    position: absolute;
    top: auto !important;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media only screen and (max-width: 991px) {
  .ads___2M1d_ {
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .mobileImage___1x_FT {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center;
    background-size: cover;
    opacity: 0.9;
  }
  .ads___2M1d_ {
    height: 320px;
    background-color: #009DC5;
    background-image: none !important;
  }
  .ads___2M1d_ * {
    font-family: “Poppins”, sans-serif !important;
  }
  .heart___3IRKZ {
    right: 16px;
    top: 16px;
  }
  .infos___1Csng {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: transparent;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      #009DC5 40%,
      rgba(0, 0, 0, 0) 100%
    );
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .infos___1Csng span {
    margin: 0;
    text-shadow: none !important;
  }
  .infos___1Csng p {
    position: initial !important;
    top: initial;
    padding-top: 0px;
    margin: 0 !important;
    margin-bottom: 8px !important;
  }
  .time___26gnE {
    display: none !important;
  }
  .mobileTime___2pMXv::before {
    top: 0 !important;
  }
  .mobileTime___2pMXv {
    display: flex !important;
    flex: 1;
    align-self: center;
  }
  .commands___1vpSX {
    margin-top: 8px!important;
    flex-direction: row;
  }
  .commands___1vpSX a {
    margin: 0;
    flex: 1;
    font-weight: 500;
    font-size: 12px !important;
    height: auto !important;
    padding: 12px 16px !important;
    line-height: initial !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
  }
  .goto___32o6T {
    font-size: 11px !important;
  }
}
